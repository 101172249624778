import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { bURL } from "../api/axios.js";
function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const { USER_INFO } = useSelector((state) => state.user);
  const { WALLET_DATA } = useSelector((state) => state.wallet);
  useEffect(() => {
    fetch(`${bURL}config`).then(async (res) => {
      try {
        const { publishableKey } = await res.json();
        console.log("publishableKey", publishableKey);
        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        console.log("Unable to fetch publishable key", error);
      }
    });
  }, []);

  useEffect(() => {
    fetch(`${bURL}stripe-fund`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: WALLET_DATA?.WALLET_DATA?.transaction_amount,
        currency: WALLET_DATA?.WALLET_DATA?.transaction_currency,
        email: WALLET_DATA?.WALLET_DATA?.funded_by,
        recipient: WALLET_DATA?.WALLET_DATA?.recipient,
        accountID: WALLET_DATA?.WALLET_DATA?.accountID,
        description: WALLET_DATA?.WALLET_DATA?.description,
        name:
          USER_INFO?.account?.bio_data?.firstname +
          " " +
          USER_INFO?.account?.bio_data?.lastname,
      }),
    }).then(async (result) => {
      let { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  });
  const loader = "auto";
  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#ed5f74",
      borderRadius: "8px",
      fontfamily: "sans-serif",
      colorBackground: "#fafafa",
    },
  };

  return (
    <div className="m-auto bg-blue-600">
      <main>
        <h3>Securely Fund your Zurepay Wallet</h3>

        {clientSecret && stripePromise && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret, appearance, loader }}
          >
            <CheckoutForm />
          </Elements>
        )}
      </main>
    </div>
  );
}

export default Payment;
