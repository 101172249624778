import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { compareDesc } from "date-fns";
import axios from "../../api/axios";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { FcApproval, FcApprove } from "react-icons/fc";
const InitiatedKYC = () => {
  const columns = [
    {
      name: "CREATED DATE",
      selector: (row) => dateFormat(row.createdAt, " mmmm dS, yyyy"),
    },

    {
      name: "EMAIL ADDRESS",
      selector: (row) => row.email,
    },

    {
      name: "PROFILE",
      selector: (row) => row?.profile_status,
    },

    {
      name: "ACC VERIFIED",
      selector: (row) =>
        row?.verified ? (
          <span className="txt-green">
            <FaCheckCircle />
          </span>
        ) : (
          <span className="txt-red">&#10060;</span>
        ),
    },

    {
      name: "PoR",
      selector: (row) =>
        row?.proof_of_residence_status === "INITIATED" ? (
          <div className="fllex">
            <a
              className="link"
              href={`/uploads/kyc_docs/${row?.email}/${row?.kyc.proof_of_residence}`}
              download={`/uploads/kyc_docs/${row?.email}/${row?.kyc.proof_of_residence}`}
            >
              <MdOutlineFileDownload size={20} />
            </a>
            <Link className="link" to={`/residence/approve/${row._id}`}>
              Approve
            </Link>
          </div>
        ) : (
          row?.proof_of_residence_status
        ),
    },
    {
      name: "PoID",
      selector: (row) =>
        row?.proof_of_identity_status === "INITIATED" ? (
          <div className="fllex">
            <a
              className="link"
              href={`/uploads/kyc_docs/${row?.email}/${row?.kyc.identity}`}
              download={`/uploads/kyc_docs/${row?.email}/${row?.kyc.identity}`}
            >
              <MdOutlineFileDownload size={20} />
            </a>
            <Link className="link" to={`/identity/approve/${row._id}`}>
              Approve
            </Link>
          </div>
        ) : (
          row?.proof_of_identity_status === "APPROVED" && (
            <span className="txt-green">
              <FaCheckCircle />
            </span>
          )
        ),
    },
    {
      name: "Button",
      selector: (row) => (
        <Link className="link" to={`/kyc/initiated/${row._id}`}>
          View
        </Link>
      ),
    },
  ];

  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const { USER_INFO } = useSelector((state) => state.user);

  useEffect(() => {
    axios
      .get("kyc/initiated")
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        throw new Error(error, " Error encountered while fetching data");
      });
  }, [USER_INFO.account._id]);
  if (!data) return null;
  data?.users?.sort(function (a, b) {
    return compareDesc(a.createdAt, b.createdAt);
  });

  return (
    <section className="dashboard light-gray-bg">
      <div className="table-container">
        {loading ? (
          "LOADING..."
        ) : (
          <DataTable columns={columns} data={data.users} />
        )}
      </div>
    </section>
  );
};

export default InitiatedKYC;
