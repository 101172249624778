const Illustrator = () => {
  return (
    <div className="illustrator-container">
      <div className="illustrator">
        <div className="left">
          <img
            src="/images/E-Wallet-01.svg"
            className="illustrator-image"
            alt="EWallet"
          />
        </div>
        <div className="right">
          <h1>Fast, secure payments</h1>
          <p className="parag">
            ZurePay’s gateway solutions make it fast and secure to accept
            payments from customers, no matter the size.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Illustrator;
