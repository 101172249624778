import React, { useEffect, useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { updatePhone } from "../features/user/userActions";

const UpdatePhone = () => {
  const { error, loading, USER_INFO } = useSelector((state) => state.user);
  const [clearFeedback, setClearFeedback] = useState(false);

  const updated = localStorage.getItem("USER_PHONE_UPDATED");
  const [email] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const [phone] = useState(
    USER_INFO?.account?.phone ? USER_INFO?.account?.phone : ""
  );
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email, phone },
  });

  const submitForm = (data) => {
    try {
      dispatch(updatePhone(data));
      console.log(data);
      setClearFeedback(true);
    } catch (error) {
      console.error("Error: ", error);
      setClearFeedback(false);
    }
  };

  useEffect(() => {
    if (updated) {
      const timer = setTimeout(() => {
        localStorage.removeItem("USER_PHONE_UPDATED");
        setClearFeedback(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [updated]);

  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <i className="sixteen">Update Phone Number</i>
          {error && <div className="alert-warning">{error}</div>}
          {updated && clearFeedback && (
            <i className="alert-success">Phone Number Updated successfully</i>
          )}

          <div className="full-flex">
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />

            <input
              type="text"
              placeholder="Phone number"
              defaultValue={phone}
              {...register("phone", { required: true })}
            />
            {errors.phone && <span>Phone Number is required</span>}
          </div>

          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {loading ? <SpinnerCircular size={20} /> : "Update Phone"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePhone;
