import React from "react";

const Reach = () => {
  return (
    <div className="hero-container">
      <div className="left">
        <h1>Global reach</h1>
        <p className="parag">
          Our solutions enable businesses to accept payments from customers
          around the world. With ZurePay, you can easily expand your reach and
          grow.
        </p>
      </div>
      <div className="right">
        <img
          src="/images/Payment-Information-01.svg"
          className="hero-image"
          alt="Payment-Information"
        />
      </div>
    </div>
  );
};

export default Reach;
