import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setWallet } from "../features/wallet/walletActions";
import { SpinnerCircular } from "spinners-react";
const FundWallet = (props) => {
  const [transaction_currency, setCurrency] = useState("");
  const [transaction_amount, setAmount] = useState("");
  const [description, setDescription] = useState("");

  const [funded_by, setFundedBy] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const funded = localStorage.getItem("WALLET_DATA_STATUS");
  const { wLoading, error } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email, funded_by } });
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("IS_LOGIN_ZPAY");
    if (!auth) {
      navigate("/login");
    }
    const uEmail = localStorage.getItem("USER_EMAIL");
    if (uEmail) {
      setEmail(uEmail);
      setFundedBy(uEmail);
    }
  }, [navigate]);

  useEffect(() => {
    if (funded) {
      const timer = setTimeout(() => {
        localStorage.removeItem("WALLET_DATA_STATUS");
        navigate("/funding");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [funded, navigate]);

  const submitForm = (data) => {
    // check input data
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(setWallet(data));
  };
  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <i className="sixteen">Fund Your Wallet Securely</i>

          {error && <div className="alert-warning">{error}</div>}
          {funded && (
            <i className="sixteen alert-success">
              All is set, taking you to checkout fulfilment page...
            </i>
          )}
          <div className="full-flex">
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />

            <div className="half-flex">
              <select {...register("transaction_currency")}>
                <option value="">Select currency to fund</option>
                <option value="gbp">Pound</option>
                <option value="eur">Euro</option>
                <option value="usd">US dollar</option>
                <option value="ngn">Nigeria Naira</option>
                <option value="zar">South Africa Rand</option>
                {/* <option value="kes">Kenya Shilling</option> */}
              </select>
              <input
                type="text"
                placeholder="Amount"
                {...register("transaction_amount", { required: true })}
                onChange={(e) => setAmount(e.target.value)}
                onFocus={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="half-flex">
            <input
              type="hidden"
              placeholder="funded by"
              defaultValue={funded_by}
              {...register("funded_by", { required: true })}
            />
          </div>

          <input
            type="text"
            placeholder="Description"
            {...register("description", { required: false })}
            onChange={(e) => setDescription(e.target.value)}
            onFocus={(e) => setDescription(e.target.value)}
          />
          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {wLoading ? <SpinnerCircular size={20} /> : " Add Fund"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FundWallet;
