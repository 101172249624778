import React from "react";
import { features } from "./key_features";
const Features = () => {
  return (
    <div id="features" className="feature-container">
      <div className="feature">
        <h1>Key Attributes &amp; Functions</h1>
        <div className="card-container">
          {/* key features */}
          {features.map((f) => (
            <div className="feature-card" key={f.id}>
              <span className="feature-icon">{f.icon}</span>
              <h3 className="feature-card-title">{f.title}</h3>
              <p className="feature-card-body">{f.body}</p>
            </div>
          ))}
          {/* key features end */}
        </div>
      </div>
    </div>
  );
};

export default Features;
