import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { refreshLogin } from "../features/user/userActions";

// form styles
import "../styles/forms.css";
const RefreshLoginScreen = () => {
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const { loading, error, IS_LOGIN_ZPAY_INIT, USER_EMAIL } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email } });

  useEffect(() => {
    if (!IS_LOGIN_ZPAY_INIT && USER_EMAIL == null) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, IS_LOGIN_ZPAY_INIT, USER_EMAIL]);

  const submitForm = (data) => {
    dispatch(refreshLogin(data));
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZPAY-ICON" />
        </div>
        <p className="divider">
          <span>Reset History</span>
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {error && (
              <div className="alert-warning">{error}</div>
          
          )}

          {!IS_LOGIN_ZPAY_INIT && USER_EMAIL == null && (
              <h3 className="alert-success">Zurepay cache cleared...</h3>
       
          )}

          <input
            type="hidden"
            defaultValue={email}
            placeholder="Enter Email"
            {...register("email", { require: true })}
          />
          {errors.email && <span>Email is required</span>}

          <button type="submit" className="r-btn" disabled={loading}>
            {loading ? "Clearing cache..." : "Clear Zurepay Cache"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default RefreshLoginScreen;
