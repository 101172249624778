import { useState } from "react";
import { testimonies } from "./key_testimony";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? testimonies.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === testimonies.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="testimonial-container">
      <div className="testimonial">
        <BsChevronCompactLeft
          onClick={prevSlide}
          className="testimonial-slider-nav"
        />

        <div className="testimonial-card">
          <div className="tc-top">
            <q>{testimonies[currentIndex].body}</q>
          </div>
          <div className="tc-bottom">
            <div className="tc-photo">
              <img
                src={`/images/${testimonies[currentIndex].photo}`}
                className="photo-fit"
                alt={testimonies[currentIndex].name}
              />
            </div>

            <div className="name_title">
              <div className="name"> {`${testimonies[currentIndex].name}`}</div>
              <div className="title">
                {" "}
                {`${testimonies[currentIndex].title}`}
              </div>
            </div>
          </div>
        </div>
        <BsChevronCompactRight
          onClick={nextSlide}
          className="testimonial-slider-nav"
        />
      </div>
    </div>
  );
};
export default Testimonial;
