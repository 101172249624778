import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "../../api/axios";
import dateFormat from "dateformat";
import { moneyFormatter } from "../../utils/moneyFormatter";

const PendingWithdrawalOne = () => {
  const [data, setData] = useState(false);
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [color, setColor] = useState("");

  const params = useParams();
  const id = params.id;
  useEffect(() => {
    axios
      .get(`withdrawal/pending/${id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  if (!data) return null;

  const ApproveWithdrawal = () => {
    axios
      .get(`withdrawal/approve/${id}`)
      .then((response) => {
        setMsg(response.data.message);
        setSuccess(true);
        setColor(response.data.status)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <section className="wallet">
      <div className="container">
        <div className="dash-left dash-left-bg">
          <div className="medium-card">
            <h3>Withdrawal Request</h3>

            {success && msg && (
              <p
                className={`${color ? "alert-success txt-green" : "alert-warning txt-red"}`}
              >
                {msg}
              </p>
            )}
            <p className="fllex">
              <span>Date: </span>
              <span>
                {dateFormat(data.pw[0].transaction_date, " mmmm dS, yyyy")}
              </span>
            </p>
            <p className="fllex">
              <span>Amount: </span>
              <span>
                {moneyFormatter(data.pw[0]?.transaction_amount, data.pw[0].transaction_currency)}
              </span>
            </p>
            <p>
              <Link to="/withdrawal/pending" className="login">
                Approve More
              </Link>
              <button className="signup" onClick={ApproveWithdrawal}>
                Approve payment
              </button>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PendingWithdrawalOne;
