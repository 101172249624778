const About = () => {
  return (
    <div className="pages-container">
      <h1> About Us</h1>

      <p className="parag">


        <b>DE LINCOM HITECH LLC</b>, headquartered at 30N Gould Street, #33941 Sheridan, Wyoming, 82801 stands as the innovative force behind Zurepay, a dynamic payment platform catering to both domestic and commercial needs. Founded with a vision to revolutionize the way payments are made and managed, DE LINCOM HITECH LLC has established itself as a leader in the fintech industry, driven by a commitment to excellence and cutting-edge technology.
        <br /><br />

        Zurepay, the flagship product of DE LINCOM HITECH LLC, is a comprehensive payment solution designed to streamline transactions, enhance security, and optimize financial processes. Whether it's facilitating online purchases, managing payroll for businesses, or enabling seamless peer-to-peer transfers, Zurepay offers a versatile platform that meets the diverse needs of modern consumers and businesses alike.
        <br /><br />

        At the heart of Zurepay's success lies its dedication to innovation and user experience. Through continuous research and development, DE LINCOM HITECH LLC ensures that Zurepay remains at the forefront of technological advancements, integrating the latest features and functionalities to deliver a superior payment experience.
        <br /><br />

        <b>KEY FEATURES INCLUDE</b>
        <br />
        <br />
        <b>Secure Transactions: </b> Zurepay prioritizes the security of every transaction, employing robust encryption and authentication measures to safeguard user data and financial information.
        <br /><br />

        <b>Accessibility: </b> Zurepay offers a user-friendly interface accessible via web and mobile devices, allowing users to manage their finances anytime, anywhere.
        <br /><br />

        <b>Versatility: </b>  Whether it's processing credit card payments, initiating bank transfers, or managing invoices, Zurepay caters to a wide range of payment needs, both personal and professional.
        <br /><br />

        <b>Scalability: </b>  Designed to grow with the user, Zurepay provides scalable solutions for individuals, small businesses, and large enterprises alike, accommodating varying transaction volumes and complexities.
        <br /><br />

        <b>Integration:</b> Zurepay seamlessly integrates with existing accounting and financial systems, enabling efficient reconciliation and reporting for businesses of all sizes.
        <br /><br />

        As the owner of Zurepay, DE LINCOM HITECH LLC remains committed to driving innovation and excellence in the fintech industry, empowering individuals and businesses with the tools they need to thrive in today's digital economy. With a focus on reliability, security, and user-centric design, Zurepay continues to redefine the payment landscape, setting new standards for convenience, efficiency, and peace of mind.
        <br /><br />

      </p>

    </div>
  );
};

export default About;
