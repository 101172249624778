import React, { useEffect, useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { switchAccountToMerchant } from "../features/user/userActions";
const SwitchToMerchant = () => {
  const { error, loading, USER_INFO, SWITCHED_TO_MERCHANT } = useSelector(
    (state) => state.user
  );
  const switched_init = localStorage?.getItem("ACCOUNT_TO_MERCHANT");
  const [email] = useState(USER_INFO.account ? USER_INFO?.account?.email : "");
  const [account_type] = useState(
    USER_INFO.account ? USER_INFO?.account?.account_type : ""
  );
  const [bearerToken] = useState(
    USER_INFO.USER_TOKEN ? `Bearer ${USER_INFO?.USER_TOKEN}` : false
  );
  const [merchant_website_url, setMerchantWebsiteUrl] = useState("");
  const [merchant_name, setMerchantName] = useState("");

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email, account_type, bearerToken },
  });

  const navigate = useNavigate();
  const submitForm = (data) => {
    dispatch(switchAccountToMerchant(data));
    setMerchantWebsiteUrl("");
    setMerchantName("");
  };

  useEffect(() => {
    if (switched_init) {
      const timer = setTimeout(() => {
        localStorage.removeItem("ACCOUNT_TO_MERCHANT");
        navigate("/my/dashboard");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [switched_init, navigate]);

  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="sixteen alert-warning">
            You are upgrading your account to a Business/Merchant or updating
            your merchant website address
            <br />
            <br />
            <b>Note: </b>Additional requirements may be required after a review
            of your website in order to continue a seamless payment experience
            with Zurepay.
          </div>
          {error && <div className="alert-warning">{error}</div>}

          {SWITCHED_TO_MERCHANT && switched_init && (
            <i className="sixteen alert-success">
              {SWITCHED_TO_MERCHANT.message}
            </i>
          )}

          <div className="full-flex">
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />

            <input
              type="hidden"
              placeholder="Bearer Token"
              defaultValue={bearerToken}
              {...register("bearerToken", { required: true })}
            />
            <input
              type="hidden"
              placeholder="Account Type"
              defaultValue={account_type}
              {...register("account_type", { required: true })}
            />
            <i className="sixteen ">
              {USER_INFO?.account
                ? USER_INFO?.account?.merchant_website_url
                : "Enter your Merchant Website URL"}
            </i>
            <input
              type="text"
              placeholder="Merchant Business Name"
              value={USER_INFO?.account?.merchant_name}
              {...register("merchant_name", { required: true })}
              onChange={(e) => setMerchantName(e.target.value)}
              onFocus={(e) => setMerchantName(e.target.value)}
            />
            {errors.merchant_name && (
              <span className="alert-warning">
                Merchant/Business Registered name is required
              </span>
            )}
            <input
              type="text"
              placeholder="Ex: https://www.yourwebsite.com"
              value={merchant_website_url}
              {...register("merchant_website_url", { required: true })}
              onChange={(e) => setMerchantWebsiteUrl(e.target.value)}
              onFocus={(e) => setMerchantWebsiteUrl(e.target.value)}
            />
            {errors.merchant_website_url && (
              <span className="alert-warning">
                Merchant/Business Website address is required
              </span>
            )}
          </div>

          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {loading && SWITCHED_TO_MERCHANT?.status ? (
                <SpinnerCircular size={20} />
              ) : (
                "Update Details"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SwitchToMerchant;
