const RefundPolicy = () => {
  return (
    <div className="pages-container">
      <h1> Refund Policy</h1>
      <h6>Last Updated on 01 April 2023</h6>
      <p className="parag">
        To place a refund, return or exchange request for an order placed with
        ZurePay, You would need to contact ZurePay via email at
        <b> support@zurepay.com, </b> by phone at <b>+1 888 906 0375 </b>or by
        accessing ZurePay myAccount.
        <br />
        <br /> Please have your order number available and provide a reason for
        your request in order to speed up your refund process. Your request will
        be reviewed within 2 days and we will notify you via email of the
        results.
        <br />
        <br /> Refund, returns or exchanges request are accepted up to 30 days
        from the date of placing the Order with ZurePay. ZurePay may grant
        extensions to this period for some special cases and Products at its
        discretion. If you choose to pay by check or money order you may cancel
        your Order before making the payment or in case the payment is not
        received by us in 30 days ZurePay will cancelled you Order. You may
        submit a request to cancel your order on the same day the order was
        placed. Please note that if the Order is already shipped or delivered we
        will not be able to approve the cancelation request. <br />
        <br /> Some products have special conditions for returns as described in
        the Order or Product materials delivered ("Products terms") so please
        read the carefully before making a request with ZurePay. In case of
        misunderstandings between ZurePay Refund Policy and the Product terms,
        the present Refund policy will prevail. Shipping costs are
        non-refundable, except if due to ZurePay error, when you need to contact
        ZurePay and make a formal request. <br />
        <br /> The present Refund policy is part of the ZurePay TERMS AND
        CONDITIONS.
      </p>
      <p className="parag">
        If the refund request is granted by ZurePay, payments are refunded as
        follows:
        <li>
          Credit / Debit Cards payments will be refunded within one (1) business
          days;
        </li>
        <li>
          Wire Transfer and check payments will be refunded within seven (7)
          business days and the cost of transfer will be supported by the end
          user;
        </li>
        <li>PayPal payments will be refunded within one (1) business day;</li>
        <li>
          Other payment methods will be refunded between five (5) to seven (7)
          days.
        </li>
        <br />
        <br />
        If applicable, ZurePay reserve the right to hold the refund payment
        until we receive a completed letter of destruction of the Product signed
        by you.
      </p>
    </div>
  );
};

export default RefundPolicy;
