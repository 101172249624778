const Notifications = () => {
  return (
    <section className="wallet">
      <div className="container">
        <div className="dash-left dash-left-bg">
          <div className="medium-card">
            <h3>Notifications</h3>
            <p className="sixteen">No Notifications found</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Notifications;
