import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios, { fURL } from "../api/axios";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userStateUpdate } from "../features/user/userActions";
const ProfilePhotoUploadScreen = () => {
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState(
    localStorage.getItem("FILE_NAME")
      ? localStorage.getItem("FILE_NAME")
      : "zurepay_zero_avatar.png"
  );
  const [uploadedFile, setUploadedFile] = useState({});
  const [select, setSelect] = useState("Select New Image...");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const { register, handleSubmit } = useForm({ defaultValues: { email } });

  const { USER_INFO } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (filename) {
      const timer = setTimeout(() => {}, 3000);
      setFilename("FILE_NAME");
      return () => clearTimeout(timer);
    }
  }, [filename]);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        localStorage.removeItem("FILE_NAME");
        navigate("/my/settings");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success, navigate]);

  const onFileSelected = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setSelect(e.target.files[0].name);
  };
  const submitForm = async (data, e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("avatar", file);
    formData.append("email", email);
    try {
      setIsLoading(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("upload", formData, config);
      const { fileName, filePath } = data;
      localStorage.setItem("FILE_NAME", fileName);
      localStorage.setItem("FILE_PATH", filePath);
      setUploadedFile({ fileName, filePath });
      dispatch(userStateUpdate(data));
    } catch (error) {
      if (error?.response) {
        setError(true);
        setIsLoading(false);
        setErrMsg(error.response.data.error);
        return;
      }
    }
    setIsLoading(false);
    setSuccess(true);
    setSuccessMsg("File uploaded successfully");
  };

  return (
    <section className="form-container-modal">
      <div className="formly-modal">
        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {error && <span className="alert-warning txt-red">{errMsg}</span>}
          {success && (
            <span className="alert-success txt-green">{successMsg}</span>
          )}
          <input
            type="hidden"
            placeholder="Email"
            defaultValue={email}
            {...register("email", { required: true })}
          />
          <input
            type="file"
            className="upload-file-input"
            placeholder="Click to attach profile photo"
            {...register("file", { required: true })}
            onChange={onFileSelected}
          />
          <label
            className="upload-label"
            htmlFor="attached_file"
            style={{ cursor: "pointer", color: "#09f" }}
          >
            {select}
          </label>
          <div className="btn-container">
            <Link to="/" className="r-btn mt-10">
              Update Later
            </Link>
            &nbsp; &nbsp;
            <button type="submit" className="r-btn-over" disabled={isLoading}>
              {isLoading ? "Updating..." : "Update Photo"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ProfilePhotoUploadScreen;
