import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { compareDesc } from "date-fns";
import axios from "../../api/axios";
import { FaCheckCircle } from "react-icons/fa";

const Account = () => {
  const columns = [
    {
      name: "CREATED DATE",
      selector: (row) => dateFormat(row.createdAt, " mmmm dS, yyyy"),
    },

    {
      name: "EMAIL ADDRESS",
      selector: (row) => row.email,
    },

    {
      name: "PROFILE",
      selector: (row) => row?.profile_status,
    },

    {
      name: "ACC STATUS",
      selector: (row) =>
        row?.verified ? (
          <span className="txt-green">
            <FaCheckCircle />
          </span>
        ) : (
          <span className="txt-red">&#10060;</span>
        ),
    },
    {
      name: "PoR",
      selector: (row) => row?.proof_of_residence_status,
    },
    {
      name: "PoID",
      selector: (row) => row?.proof_of_identity_status,
    },
    {
      name: "Button",
      selector: (row) => (
        <>
          <Link className="link" to={`/account/view/${row._id}`}>
            View
          </Link>
          &nbsp; / &nbsp;
          <Link className="link" to={`/account/delete/${row._id}`}>
            Delete
          </Link>
        </>
      ),
    },
    {
      name: "Suspend",
      selector: (row) =>
        row.account_status === "SUSPENDED" ? (
          <Link className="link" to={`/account/unsuspend/${row._id}`}>
            Lift Sus.
          </Link>
        ) : (
          <Link className="link" to={`/account/suspend/${row._id}`}>
            Suspend
          </Link>
        ),
    },
  ];

  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const { USER_INFO } = useSelector((state) => state.user);

  useEffect(() => {
    axios
      .get("account/all")
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        throw new Error(error, " Error encountered while fetching data");
      });
  }, [USER_INFO.account._id]);
  if (!data) return null;
  data?.users?.sort(function (a, b) {
    return compareDesc(a.createdAt, b.createdAt);
  });

  return (
    <section className="dashboard light-gray-bg">
      <div className="table-container">
        {loading ? (
          "LOADING..."
        ) : (
          <DataTable columns={columns} data={data.users} />
        )}
      </div>
    </section>
  );
};

export default Account;
