import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
const ActivitiesMenu = () => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNavMenu = async () => {
    setNavOpen(!navOpen);
  };

  return (
    <div className="fllex">
      <i>My Recent Activities: </i>
      <button className="act_btn" onClick={toggleNavMenu}>
        {<BsThreeDotsVertical />}
      </button>
      <div className={navOpen ? "act_open" : "act_close"}>
        <div className="act_menu_link">
          <ul className="act_ulli">
            <li>
              <Link to="/my/fundings">My Fiat Fundings</Link>
            </li>

            <li>
              <Link to="/payments/sent">Payment Sent</Link>
            </li>

            <li>
              <Link to="/payments/received">Payment Received</Link>
            </li>
            <li>
              <Link to="/my/withdrawals">Withdrawal History</Link>
            </li>
            <li>
              <Link to="/my/fundrequests">Payment Request History</Link>
            </li>
            <li>
              <Link to="/my/swaps">Currency Swap History</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ActivitiesMenu;
