import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import dateFormat from "dateformat";
import { compareDesc } from "date-fns";
import axios from "../api/axios";
import { moneyFormatter } from "../utils/moneyFormatter";
const columns = [
  {
    name: "DATE",
    selector: (row) => dateFormat(row.transaction_date, " mmmm dS, yyyy"),
  },

  {
    name: "FROM",
    selector: (row) =>
    moneyFormatter(row?.from_amount, row?.from_currency),
  },
  {
    name: "TO",
    selector: (row) =>
    moneyFormatter(row?.to_amount, row?.to_currency),
  },

  {
    name: "Ex. RATE",
    selector: (row) => row.ex_rate,
  },
  {
    name: "STATUS",
    selector: (row) => row.swap_status,
  },
];
const CurrencySwap = () => {
  const [data, setData] = useState(false);
  const { USER_INFO } = useSelector((state) => state.user);
  useEffect(() => {
    axios
      .get(`currency/swap/${USER_INFO.account._id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [USER_INFO.account._id]);
  if (!data) return null;
  data?.CURRENCY_SWAP_HISTORY?.sort(function (a, b) {
    //return a - b;
    return compareDesc(a.transaction_date, b.transaction_date);
  });

  return (
    <section className="dashboard light-gray-bg">
      <div className="table-container">
        <DataTable columns={columns} data={data.CURRENCY_SWAP_HISTORY} />
      </div>
    </section>
  );
};

export default CurrencySwap;
