import React, { useEffect, useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { useParams } from "react-router-dom";
import { suspendAccount } from "../features/user/userActions";
import { RiAlertFill } from "react-icons/ri";
import axios from "../api/axios";

const SuspendAccount = () => {
  const params = useParams();
  const id = params.id;
  const [data, setData] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const { error, loading, USER_INFO, SUSPEND_ACCOUNT } = useSelector(
    (state) => state.user
  );

  const [bearerToken] = useState(
    USER_INFO.USER_TOKEN ? `Bearer ${USER_INFO?.USER_TOKEN}` : false
  );
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm({
    defaultValues: { id },
  });

  const navigate = useNavigate();
  const submitForm = (data) => {
    dispatch(suspendAccount(data));
    setDeleted(true);
  };

  useEffect(() => {
    if (deleted) {
      const timer = setTimeout(() => {
        localStorage.removeItem("ACCOUNT_SUSPENDED");
        setDeleted(false);
        navigate("/accounts");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [deleted, navigate]);

  useEffect(() => {
    axios
      .get(`account/${id}`, {
        headers: {
          Authorization: `Bearer ${USER_INFO.USER_TOKEN}`,
        },
      })
      .then((response) => {
        setData(response.data);
        localStorage.removeItem("ACCOUNT_SUSPENDED");
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, USER_INFO?.USER_TOKEN]);
  if (!data) return null;

  return (
    <section className="wallet_div">
      <div className="container">
        <div id="CardForm">
          <div className="right">
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="sixteen alert-warning">
                <h2 className="flex-between">
                  <RiAlertFill /> Account Deletion Waring!
                </h2>
                <hr />
                You are about to suspend the users zurepay account associated
                with this email address below
                <br />
                <br />
                <b>{data.user_details.email}</b>
              </div>
              {error && <div className="alert-warning">{error}</div>}

              {SUSPEND_ACCOUNT && deleted && (
                <i className="sixteen alert-success">
                  {SUSPEND_ACCOUNT.message}
                </i>
              )}

              <div className="full-flex">
                <input
                  type="hidden"
                  placeholder="Bearer Token"
                  defaultValue={bearerToken}
                  {...register("bearerToken", { required: true })}
                />
                <input
                  type="hidden"
                  placeholder="ID"
                  defaultValue={id}
                  {...register("id", { required: true })}
                />
              </div>

              <div className="btn-container">
                <button></button>
                <button type="submit" className="login">
                  {loading && SUSPEND_ACCOUNT?.status ? (
                    <SpinnerCircular size={20} />
                  ) : (
                    "Suspend User Account"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuspendAccount;
