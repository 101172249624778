const PrivacyPolicy = () => {
  return (
    <div className="pages-container">
      <h1>Privacy Policy</h1>
      <h6>Updated on 08 may 2023</h6>
      <p className="parag">
        Overview ZurePay has developed this Privacy Statement to explain how we
        may collect, retain, process, share and transfer your Personal Data when
        you visit our Sites or use our Services. This Privacy Statement applies
        to your Personal Data when you visit Sites or use Services, and does not
        apply to online websites or services that we do not own or control,
        including websites or services of other ZurePay Users.
      </p>
      <p className="parag">
        This Privacy Statement is designed to help you obtain information about
        our privacy practices and to help you understand your privacy choices
        when you use our Sites and Services. Please note that our Service
        offerings may vary by region. We have defined some terms that we use
        throughout the Privacy Statement. You can find the meaning of a
        capitalized term in the Definitions section. Please contact us if you
        have questions about our privacy practices that are not addressed in
        this Privacy Statement.
      </p>
      <p className="parag">
        2. What Personal Data Do We Collect? We may collect information about
        you when you visit our Sites or use our Services, including the
        following:
        <li>
          Registration and use information – When you register to use our
          Services by establishing an Account, we will collect Personal Data as
          necessary to offer and fulfill the Services you request. Depending on
          the Services you choose, we may require you to provide us with your
          name, postal address, telephone number, email address and
          identification information to establish an Account. We may require you
          to provide us with additional Personal Data as you use our Services.
        </li>
        <li>
          Transaction and experience information – When you use our Services or
          access our Sites, for example, to make purchases from merchants, to
          receive money, to process payments, or to send money to friends and
          family, we collect information about the transaction, as well as other
          information associated with the transaction such as amount sent or
          requested, amount paid for products or services, merchant information,
          including information about any funding instruments used to complete
          the transaction, Device Information, Technical Usage Data, and
          Geolocation Information.
        </li>
        <li>
          Participant information – When you use our Services or access our
          Sites, we collect Personal Data you provide us about the other
          participants associated with the transaction. o Send or request money:
          When you send or request money through the Services, we collect
          Personal Data such as name, postal address, telephone number, and
          financial account information about the participant who is receiving
          money from you or sending money to you. <br />
          <br />
          The extent of Personal Data required about a participant may vary
          depending on the Services you are using to send or request money.{" "}
          <br />
          <br />
          Pay or request someone else to pay a bill: If you use our Services to
          pay a bill for the benefit of someone else, or if you request a User
          to pay a bill for you, we collect Personal Data from you about the
          account holder such as name, postal address, telephone number, email
          address, and account number of the bill that you intend to pay or
          request to be paid. <br />
          <br />
          Add value to your accounts: If you use our Services to add value to
          your Account or any other account you may have, or if you ask a User
          to add value to any of these accounts, we may collect Personal Data
          from you about the other party, or from the other party about you to
          facilitate the request. <br />
          <br />
          For example, if you use our Services to reload a mobile phone, or to
          request value be added to your mobile account, we may collect Personal
          Data and other information including mobile account number from the
          other participant.
        </li>
        <li>
          Information about your public profile and your friends and contacts –
          It may be easier for us to help you transact with your friends and
          contacts if you choose to connect your contact list information with
          your Account or if your Account profile is publicly available. If you
          establish an account connection between your device or a social media
          platform and your Account, we will use your contact list information
          (such as name, address, email address) to improve your experience when
          you use the Services. <br /> <br /> When your Account profile is
          public, other users can find your profile to send you money by
          searching for you by name, username, email, or mobile number on
          ZurePay and confirm it’s you by viewing your photo. You can make your
          Account profile private anytime in your ZurePay.me settings.
        </li>
        <li>
          Information that you choose to provide us to obtain additional
          Services or specific online Services – If you request or participate
          in an optional Site feature, or request enhanced Services or other
          elective functionality, we may collect additional information from
          you. We will provide you with a separate notice at the time of
          collection, if the use of that information differs from the uses
          disclosed in this Privacy Statement.
        </li>
        <li>
          Personal Data about you if you use unbranded Services – certain
          Services are available without being required to log in to or
          establish an Account. We will collect Personal Data when you are
          interacting with and making payments to merchants using our card
          payment services that do not carry the ZurePay brand and when you
          checkout with ZurePay without logging into an account. For our
          unbranded payment services, your interaction is with the merchant, on
          their platform. <br />
          <br />
          If you are an Account holder, or create an Account at a later date, we
          may collect information about unbranded transactions and associate
          them with your Account to improve your customer experience as an
          Account holder and for compliance and analytics purposes. If you are
          not an Account holder, we will collect and store all information you
          provide and use such information in accordance with this Privacy
          Statement. <br />
          <br /> Information about you from third-party sources – We obtain
          information from third-party sources such as merchants, data
          providers, and credit bureaus, where permitted by law.
        </li>
        <li>
          Other information we collect related to your use of our Sites or
          Services – We may collect additional information from or about you
          when you communicate with us, contact our customer support teams or
          respond to a survey. <br />
          <br />
          Non-Account holders If you use our Services without creating or
          logging into an account, we’ll still collect personal data, which may
          include your payment information, device information, and location.
          When you use our Services without creating or logging into an account,
          we will use this information to process transactions, prevent fraud
          and comply with the law. We may connect this information with your
          account, if you have one or if you create an account at a later date.{" "}
        </li>
      </p>
      <p className="parag">
        3. Why Do We Retain Personal Data? We retain Personal Data to fulfill
        our legal or regulatory obligations and for our business purposes. We
        may retain Personal Data for longer periods than required by law if it
        is in our legitimate business interests and not prohibited by law. If
        your Account is closed, we may take steps to mask Personal Data and
        other information, but we reserve our ability to retain and access the
        data for so long as required to comply with applicable laws. We will
        continue to use and disclose such Personal Data in accordance with this
        Privacy Statement.
      </p>
      <p className="parag">
        4. How Do We Process Personal Data? We may Process your information for
        the following reasons:
        <li>
          To operate the Sites and provide the Services, including to: o
          initiate a payment, send or request money, add value to an account, or
          pay a bill; o authenticate your access to an Account; o communicate
          with you about your Account, the Sites, the Services, or ZurePay; o
          create an account connection between your Account and a third-party
          account or platform; and o perform creditworthiness and other
          financial standing checks, evaluate applications, and compare
          information for accuracy and verification purposes. o keep your
          Account and financial information up to date.
        </li>
        <li>
          To manage our business needs, such as monitoring, analyzing, and
          improving the Services and the Sites’ performance and functionality.
          For example, we analyze User behavior and perform research about the
          way you use our Services.
        </li>
        <li>
          To manage risk and protect the Sites, the Services and you from fraud
          by verifying your identity. ZurePay’s risk and fraud tools use
          Personal Data, Device Information, Technical Usage Data and
          Geolocation Information from our Sites and websites that offer ZurePay
          Services to help detect and prevent fraud and abuse of the Services. •
          To market to you about ZurePay products and Services and the products
          and services of unaffiliated businesses. We may also Process your
          Personal Data to uniquely tailor the marketing content and certain
          Services or Site experiences to better match your interests on ZurePay
          and other third-party websites.
        </li>
        <li>
          To provide personalized Services offered by ZurePay on third-party
          websites and online services. We may use your Personal Data and other
          information collected in accordance with this Privacy Statement to
          provide a targeted display, feature, Services or offer to you on
          third-party websites. We may use cookies and other tracking
          technologies to provide these online services and/or work with other
          third-parties such as merchant’s, advertising or analytics companies
          to provide these online services.
        </li>
        <li>
          To provide you with location-specific options, functionality or offers
          if you elect to share your Geolocation Information through the
          Services. We will use this information to enhance the security of the
          Sites and Services and provide you with location-based Services, such
          as advertising, search results, and other personalized content. • To
          comply with our obligations and to enforce the terms of our Sites and
          Services, including to comply with all applicable laws and
          regulations.
        </li>
        <li>
          To make it easier for you to find and connect with others. For
          instance, if you let us access your contacts or when your Account
          profile is public, we can suggest connections with people you may know
          and help others connect with you to send you money by letting them
          find your profile when they search for you by name, username, email,
          or mobile number on ZurePay. We may also associate information that we
          learn about you through your and your contacts’ use of the Services,
          and information you and others provide, to suggest people you may know
          or may want to transact with through our Services. Social
          functionality and features designed to simplify your use of the
          Services with others vary by Service.
        </li>
        <li>
          To respond to your requests, for example to contact you about a
          question you submitted to our customer service team.
        </li>
      </p>
      <p className="parag">
        5. Do We Share Personal Data? We may share your Personal Data or other
        information about you with others in a variety of ways as described in
        this section of the Privacy Statement. We may share your Personal Data
        or other information for the following reasons:
        <li>
          With other members of the ZurePay corporate family: We may share your
          Personal Data with members of the ZurePay family of entities to, among
          other things, provide the Services you have requested or authorized;
          to manage risk; to help detect and prevent potentially illegal and
          fraudulent acts and other violations of our policies and agreements;
          and to help us manage the availability and connectivity of ZurePay
          products, Services, and communications. With other companies that
          provide services to us: We may share Personal Data with third-party
          service providers that perform services and functions at our direction
          and on our behalf.
        </li>
      </p>
      <p className="parag">
        These third-party service providers may, for example, provide you with
        Services, verify your identity, assist in processing transactions, send
        you advertisements for our products and Services, or provide customer
        support. With other financial institutions: We may share Personal Data
        with other financial institutions that we have partnered with to jointly
        create and offer a product, such as with Synchrony Bank in connection
        with the ZurePay Extras credit card.
      </p>
      <p className="parag">
        {" "}
        These financial institutions may only use this information to market and
        offer ZurePay-related products, unless you have given consent for other
        uses. We may also share Personal Data to process transactions, provide
        you with benefits associated with your eligible cards, and keep your
        financial information up to date. With the other parties to transactions
        when you use the Services, such as other Users, merchants, and their
        service providers: We may share information about you and your account
        with the other parties involved in processing your transactions. This
        includes other Users you are sending or receiving funds from merchants,
        and their service providers when you use the Services to pay for goods
        or services.
      </p>
      <p className="parag">
        The information includes:{" "}
        <li>
          Personal Data and Account information necessary to facilitate the
          transaction;
        </li>
        <li>
          Information to help other participant(s) resolve disputes and detect
          and prevent fraud; and
        </li>
        <li>
          Aggregated data and performance analytics to help merchants better
          understand Users and to help merchants enhance Users’ experiences.
          With other third parties for our business purposes or as permitted or
          required by law: We may share information about you with other parties
          for ZurePay’s business purposes or as permitted or required by law,
          including:{" "}
        </li>
        <li>
          If we need to do so to comply with a law, legal process or
          regulations;
        </li>
        <li>
          {" "}
          law enforcement, regulators, government officials, or other third
          parties (in Singapore or elsewhere) in relation to a subpoena, court
          order, or other legal process or requirement under Singapore law or
          regulation, or the laws and regulations of other jurisdictions that
          are applicable to ZurePay or one of its affiliates; when we need to do
          so to comply with such law or credit card rules; or when we believe,
          in our sole discretion, that the disclosure of Personal Data is
          necessary or appropriate to prevent physical harm or financial loss;
          or to report suspected illegal activity or to investigate violations
          of a user agreement;{" "}
        </li>
        <li>to protect the vital interests of a person; </li>
        <li>to protect our property, Services and legal rights; </li>
        <li>
          to facilitate a purchase or sale of all or part of ZurePay’s business;
        </li>
        <li>
          in connection with shipping and related services for purchases made
          using a Service;
        </li>
        <li>
          {" "}
          to help assess and manage risk and prevent fraud against us, our Users
          and fraud involving our Sites or use of our Services, including fraud
          that occurs at or involves our business partners, strategic ventures,
          or other individuals and merchants;{" "}
        </li>
        <li>
          to banking partners as required by card association rules for
          inclusion on their list of terminated merchants;
        </li>
        <li> to credit reporting and collection agencies; </li>
        <li>
          to companies that we plan to merge with or be acquired by; and • to
          support our audit, compliance, and corporate governance functions.
          With your consent: We also will share your Personal Data and other
          information with your consent or direction, including if you authorize
          an account connection with a third-party account or platform. In
          addition, ZurePay may provide aggregated statistical data to
          third-parties, including other businesses and members of the public,
          about how, when, and why Users visit our Sites and use our Services.
          This data will not personally identify you or provide information
          about your use of the Sites or Services.
        </li>
      </p>
      <p className="parag">
        We do not share your Personal Data with third parties for their
        marketing purposes without your consent.
      </p>
      <p className="parag">
        6. How Do We Work with Other Services and Platforms? A significant
        benefit and innovation of ZurePay’s Services is that you can connect
        your Account with a third-party account or platform. For the purposes of
        this Privacy Statement, an “account connection” with such a third-party
        is a connection you authorize or enable between your Account and a
        non-ZurePay account, payment instrument, or platform that you lawfully
        control or own. When you authorize such a connection, ZurePay and the
        third-party will exchange your Personal Data and other information
        directly.
        <br />
        <br /> Examples of account connections include:
        <li>
          linking your Account to a social media account or social messaging
          service; • connecting your Account to a third-party data aggregation
          or financial services company, if you provide such company with your
          Account log-in credentials; or{" "}
        </li>
        <li>
          {" "}
          using your Account to make payments to a merchant or allowing a
          merchant to charge your Account. If you choose to create an account
          connection, we may receive information from the third-party about you
          and your use of the third-party’s service. For example, if you connect
          your Account to a social media account, we will receive Personal Data
          from the social media provider via the account connection.
          <br />
          <br /> If you connect your Account to other financial accounts,
          directly or through a third-party service provider, we may have access
          to your account balance and transactional information, such as
          purchases and funds transfers. We will use all such information that
          we receive from a third-party via an account connection in a manner
          consistent with this Privacy Statement.
          <br />
          <br /> Information that we share with a third-party based on an
          account connection will be used and disclosed in accordance with the
          third-party’s privacy practices. Before authorizing an account
          connection, you should review the privacy notice of any third-party
          that will gain access to your Personal Data as part of the account
          connection.
          <br />
          <br /> For example, Personal Data that ZurePay shares with a
          third-party account or platform such as a social media account may in
          turn be shared with certain other parties, including the general
          public, depending on the account’s or platform’s privacy practices.
        </li>
      </p>
      <p className="parag">
        7. How Do We Use Cookies and Tracking Technologies? When you visit our
        Sites, use our Services, or visit a third-party website for which we
        provide online Services, we and our business partners and vendors may
        use cookies and other tracking technologies (collectively, “Cookies”) to
        recognize you as a User and to customize your online experiences, the
        Services you use, and other online content and advertising; measure the
        effectiveness of promotions and perform analytics; and to mitigate risk,
        prevent potential fraud, and promote trust and safety across our Sites
        and Services. Certain aspects and features of our Services and Sites are
        only available through the use of Cookies, so if you choose to disable
        or decline Cookies, your use of the Sites and Services may be limited or
        not possible.
      </p>
      <p className="parag">
        Do Not Track (DNT) is an optional browser setting that allows you to
        express your preferences regarding tracking by advertisers and other
        third-parties. We do not respond to DNT signals.
      </p>
      <p className="parag">
        8. What Privacy Choices Are Available To You? You have choices when it
        comes to the privacy practices and communications described in this
        Privacy Statement.
      </p>
      <p className="parag">
        Many of your choices may be explained at the time you sign up for or use
        a Service or in the context of your use of a Site. You may be provided
        with instructions and prompts within the experiences as you navigate the
        Services.{" "}
        <li>
          Choices Relating to the Personal Data We Collect • Personal Data. You
          may decline to provide Personal Data when it is requested by ZurePay,
          but certain Services or all of the Services may be unavailable to you.{" "}
        </li>
        <li>
          Location and other device-level information. The device you use to
          access the Sites or Services may collect information about you,
          including Geolocation Information and User usage data that ZurePay may
          then collect and use. For information about your ability to restrict
          the collection and use of such information, please use the settings
          available in the device.
        </li>
        <li>Choices Relating to Our Use of Your Personal Data </li>
        <li>
          Personalized Services offered by ZurePay on third-party websites and
          services. You may manage your preferences for other ZurePay Services
          that are personalized and offered to you on third-party websites from
          your Account. We may also provide you with instructions and prompts on
          how to manage your preferences within the Service experience.
        </li>
        <li>
          {" "}
          Finding and connecting with others. If available, you may manage your
          preferences for finding and connecting with others from your account
          of the Service you use. • Choices Relating to Account Connections{" "}
        </li>
        <li>
          If you authorize an account connection to a third-party account or
          platform, such as a social media account, you may be able to manage
          your connection preferences from your Account or the third-party
          account or platform. Please refer to the privacy notice that governs
          the third-party platform for more information on the choices you may
          have.
        </li>
        <li>
          Choices Relating to Cookies • You may have options available to manage
          your cookies preferences. For example, your browser or internet device
          may allow you delete, disable, or block certain cookies and other
          tracking technologies. You can learn more by visiting
          AboutCookies.org. You may choose to enable these options, but doing so
          may prevent you from using many of the core features and functions
          available on a Service or Site.
        </li>
        <li>
          You may have an option regarding the use of cookies and other tracking
          technologies when you use a Service or visit parts of a Site. For
          example, you may be asked if you want the Service or Site to
          “remember” certain things about you, and we will use cookies and other
          tracking technologies to the extent that you permit them.
        </li>{" "}
        <li>
          You can learn more about our cookies and tracking technologies by
          visiting the Statement on Cookies and Tracking Technologies page.{" "}
        </li>{" "}
        <li>
          Choices Relating to Your Registration and Account Information • If you
          have an Account, you generally may review and edit Personal Data by
          logging in and updating the information directly or by contacting us.
          Contact us if you do not have an Account or if you have questions
          about your Account information or other Personal Data. • Choices
          Relating to Communication
        </li>{" "}
        <li>
          Notices, Alerts and Updates from Us: Marketing: We may send you
          marketing content about our Sites, Services, products, products we
          jointly offer with financial institutions, as well as the products and
          services of unaffiliated third parties and members of the ZurePay
          corporate family through various communication channels, for example,
          email, text, pop-ups, push notifications, and messaging applications.
        </li>
      </p>
      <p className="parag">
        You may opt out of these marketing communications by following the
        instructions in the communications you receive. If you have an Account
        with us, you may also adjust your communication preferences in your
        Account settings. For messages sent via push notifications, you may
        manage your preferences in your device. <br />
        <br /> Informational and Other: We will send communications to you that
        are required or necessary to send to Users of our Services,
        notifications that contain important information and other
        communications that you request from us. You may not opt out of
        receiving these communications. However, you may be able to adjust the
        media and format through which you receive these notices.
      </p>{" "}
      <p className="parag">
        9. What are your Rights? Subject to limitation set out in Data
        Protection Laws, you have certain rights in relation to your personal
        data. You have the right to request access to your data, rectification
        and data portability. Please contact us if you want to exercise these
        rights. You may have the right to review automated decision making. You
        may also revoke consent.{" "}
      </p>{" "}
      <p className="parag">
        Revocation of your consent may affect our ability to provide services to
        you. If you want to exercise any of your rights, contact us. If you wish
        to complete a request for access to all personal data ZurePay holds
        about you, remember that you may be required to prove your identity. If
        you have an Account with any of our Services, you will generally be able
        to review and edit Personal Data in the Account by accessing the account
        and updating the information directly. You can also contact us if you do
        not have an Account or if you have questions about Account information
        or other Personal Data.{" "}
      </p>{" "}
      <p className="parag">
        10. How Do We Protect Your Personal Data? We maintain technical,
        physical, and administrative security measures designed to provide
        reasonable protection for your Personal Data against loss, misuse,
        unauthorized access, disclosure, and alteration. The security measures
        include firewalls, data encryption, physical access controls to our data
        centers, and information access authorization controls.
      </p>{" "}
      <p className="parag">
        {" "}
        While we are dedicated to securing our systems and Services, you are
        responsible for securing and maintaining the privacy of your password(s)
        and Account/profile registration information and verifying that the
        Personal Data we maintain about you is accurate and current. We are not
        responsible for protecting any Personal Data that we share with a
        third-party based on an account connection that you have authorized.
      </p>{" "}
      <p className="parag">
        {" "}
        11. Can Children Use Our Services? The Sites and Services are not
        directed to children under the age of 13. We do not knowingly collect
        information, including Personal Data, from children or other individuals
        who are not legally able to use our Sites and Services.
      </p>
      <p className="parag">
        merchant, on their platform. <br />
        <br /> If we obtain actual knowledge that we have collected Personal
        Data from a child under the age of 13, we will promptly delete it,
        unless we are legally obligated to retain such data. Contact us if you
        believe that we have mistakenly or unintentionally collected information
        from a child under the age of 13.
      </p>{" "}
      <p className="parag">
        {" "}
        12. What Else Should You Know? Changes to This Privacy Statement. We may
        revise this Privacy Statement from time to time to reflect changes to
        our business, the Sites or Services, or applicable laws. The revised
        Privacy Statement will be effective as of the published effective date.
        If the revised version includes a substantial change, we will provide
        you with 30 days prior notice by posting notice of the change on the
        “Policy Update” page of our website. We also may notify Users of the
        change using email or other means.{" "}
      </p>{" "}
      <p className="parag">
        Transfers of Your Personal Data to Other Countries Our operations are
        supported by a network of computers, cloud-based servers, and other
        infrastructure and information technology, including, but not limited
        to, third-party service providers. We and our third-party service
        providers store and Process your Personal Data in the United States of
        America and elsewhere in the world.
      </p>{" "}
      <p className="parag">
        {" "}
        We will protect your information as described in this Privacy Statement
        if your Personal Data is transferred to other countries. By using our
        Sites and Services, you consent to your Personal Data being transferred
        to other countries, including countries that have different data
        protection rules than your country. We do not represent that our Sites
        and Services are appropriate or available in any particular
        jurisdiction.
      </p>{" "}
      <p className="parag">
        {" "}
        13. Contact Us You may contact us if you have general questions about
        our Privacy Statement and practices or questions about your Account
        information or Personal Data. We want to make sure your questions go to
        the right place:{" "}
        <li>
          {" "}
          Click here to contact us about your ZurePay account or transaction, or
          a card payment made to a merchant. Our Data Protection Officer can be
          contacted Online
        </li>
      </p>{" "}
      <p className="parag">
        14. Definitions Account means a ZurePay account. Device Information
        means data that can be automatically collected from any device used to
        access the Sites or Services. Such information may include, but is not
        limited to, your device type; your device’s network connections; your
        device’s name; your device’s IP address; information about your device’s
        web browser and the internet connection being used to access the Site or
        Services; <br />
        <br />
        Geolocation Information; information about apps downloaded to your
        device; and biometric data (e.g., Touch ID/Fingerprint to verify your
        identity). Geolocation Information means information that identifies
        with reasonable specificity your location by using, for instance,
        longitude and latitude coordinates obtained through GPS, Wi-Fi, or cell
        site triangulation.{" "}
      </p>
      <p className="parag">
        Some of our Services may ask you for permission to share your current
        location. Some of the Sites and Services require this information to
        provide a specific product or online Service. If you do not agree to our
        collection of the geolocation information, our Sites or Services may not
        function properly when you try to use them.
      </p>{" "}
      <p className="parag">
        {" "}
        ZurePay means Bytes Consults and subsidiaries or affiliates. In this
        Privacy Statement, ZurePay is sometimes referred to as “we,” “us,” or
        “our,” depending on the context. Personal Data means personal
        information that can be associated with an identified or identifiable
        person. “Personal Data” can include name, postal address (including
        billing and shipping addresses), telephone number, email address,
        payment card number, other financial account information, account
        number, date of birth, and government-issued credentials (e.g., driver’s
        license number, national ID, passport, Social Security number and
        Taxpayer ID).
      </p>{" "}
      <p className="parag">
        {" "}
        Personal Data does not include information that does not identify a
        specific User. Process means any method or way that we handle Personal
        Data or sets of Personal Data, whether or not by automated means, such
        as collection, recording, organization, structuring, storage, adaptation
        or alteration, retrieval, and consultation, disclosure by transmission,
        disseminating or otherwise making available, alignment or combination,
        restriction, erasure or destruction of Personal Data.{" "}
      </p>{" "}
      <p className="parag">
        Services means any products, services, content, features, technologies,
        or functions, and all related websites, applications and services
        offered to you by ZurePay. Sites means the websites, mobile apps,
        official social media platforms, or other online properties through
        which ZurePay offers the Services and which has posted or linked to this
        Privacy Statement. Technical Usage Data means information we collect
        from your phone, computer or other device that you use to access the
        Sites or Services.
      </p>{" "}
      <p className="parag">
        {" "}
        Technical Usage Data tells us how you use the Sites and Services, such
        as what you have searched for and viewed on the Sites and the way you
        use our Services, including your IP address, statistics regarding how
        pages are loaded or viewed, the websites you visited before coming to
        the Sites and other usage and browsing information collected through
        Cookies. User means an individual who uses the Services or accesses the
        Sites.
      </p>{" "}
      <p className="parag">
        {" "}
        15. Additional Information The information provided in this section may
        be specific to customers depending on your region or is provided to
        ZurePay from third parties whom you may interact with when using the
        Services. <br />
        <br />
        You can contact us if you have any questions about the details in this
        section. Google ReCaptcha ZurePay uses ReCaptcha on the Sites and
        Services. Your use of ReCaptcha is subject to the Google Privacy Policy
        and Terms of Use. <br />
        <br />
        ReCaptcha is only used to fight spam and abuse.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
