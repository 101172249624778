const Address = () => {
  return (
    <div className="copyright-container">
      <div className="copyright ">
        Copyright &copy;  DE LINCOM HITECH LLC @ Zurepay
      </div>
    </div>
  );
};

export default Address;
