import { useContext, useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import InputAmount from "./InputAmount";
import SelectCountry from "./SelectCountry";
import SwitchCurrency from "./SwitchCurrency";
import { CurrencyContext } from "./context/CurrencyContext";
import { moneyFormatter } from "../utils/moneyFormatter";
import { swapCurrency } from "../features/wallet/walletActions";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
const SwapCurrency = () => {
  const {
    fromCurrency,
    setFromCurrency,
    toCurrency,
    setToCurrency,
    firstAmount,
  } = useContext(CurrencyContext);
  const [resultCurrency, setResultCurrency] = useState(0);
  const codeFromCurrency = fromCurrency.split(" ")[0];
  const codeToCurrency = toCurrency.split(" ")[0];
  const [converting, SetConverting] = useState(false);
  const dispatch = useDispatch();
  const { CURRENCY_SWAP, swap_success, error } = useSelector(
    (state) => state.wallet
  );
  const swapped = localStorage.getItem("SWAP_STATUS");

  const { USER_INFO } = useSelector((state) => state.user);
  const email = USER_INFO?.account.email;

  useEffect(() => {
    if (swapped) {
      const timer = setTimeout(() => {
        localStorage.removeItem("SWAP_STATUS");
        SetConverting(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [swapped]);

  useEffect(() => {
    if (firstAmount) {
      axios(
        `https://v6.exchangerate-api.com/v6/${process.env.REACT_APP_EXCHANGE_RATE_API}/pair/${codeFromCurrency}/${codeToCurrency}`
      )
        .then((response) => setResultCurrency(response.data.conversion_rate))
        .catch((error) => console.log(error));
    }
  }, [firstAmount, fromCurrency, toCurrency, codeFromCurrency, codeToCurrency]);

  const boxStyles = {
    marginTop: "10%",
    marginLeft: "0%",
    textAlign: "center",
    color: "#222",
    minHeight: "10rem",
    borderRadius: 2,
    padding: "0rem",
    //boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
    position: "relative",
  };

  const newAmount =
    (resultCurrency -
      resultCurrency * process.env.REACT_APP_EXCHANGE_RATE_ADJUSTED_VALUE) *
    firstAmount;
  const submitForm = (data) => {
    SetConverting(true);
    dispatch(
      swapCurrency({
        firstAmount,
        resultCurrency,
        codeFromCurrency,
        codeToCurrency,
        newAmount,
        email,
      })
    );
  };
  const { handleSubmit } = useForm({
    defaultValues: {},
  });
  return (
    <div className="currency-converter-container">
      <Container maxWidth="md" sx={boxStyles}>
        <Grid container spacing={0} marginBottom={5}>
          <InputAmount />
        </Grid>

        <Grid container spacing={2}>
          <SelectCountry
            value={fromCurrency}
            setValue={setFromCurrency}
            label="From Currency"
          />
          <SwitchCurrency />
          <SelectCountry
            value={toCurrency}
            setValue={setToCurrency}
            label="To Currency"
          />
        </Grid>

        {firstAmount && (
          <Box
            sx={{
              textAlign: "left",
              marginTop: "0.5rem",
              paddingBottom: "0px",
            }}
          >
            <Typography
              sx={{
                marginTop: "1px",
                marginBottom: "0px",
                fontWeight: "thin",
                color: "#555",
              }}
            >
              When you send
            </Typography>
            <Typography
              sx={{
                marginTop: "1px",
                marginBottom: "0px",
                fontWeight: "bold",
                color: "#555",
              }}
            >
              {moneyFormatter(firstAmount, codeFromCurrency)}
            </Typography>
            <Typography
              sx={{
                marginTop: "1px",
                marginBottom: "0px",
                fontWeight: "thin",
                color: "#555",
              }}
            >
              Recepient gets
            </Typography>
            <Typography
              sx={{
                marginTop: "1px",
                marginBottom: "0px",
                fontWeight: "bold",
                color: "#555",
              }}
            >
              {moneyFormatter(newAmount, codeToCurrency)}
            </Typography>
          </Box>
        )}
        {error && <div className="alert-warning">{error}</div>}
        {swap_success && swapped && (
          <i className="alert-success">{CURRENCY_SWAP.message}</i>
        )}
        <Button
          sx={{ marginTop: "0.5rem", paddingBottom: "0.5rem" }}
          onClick={handleSubmit(submitForm)}
        >
          {converting && !error ? "Please wait..." : "SWAP CURRENCY"}
        </Button>
      </Container>
    </div>
  );
};
export default SwapCurrency;
