import { Autocomplete, Grid, Skeleton, TextField } from "@mui/material";
import currencies from "./supportedCurrencies";
import { useState } from "react";
const SelectCountry = (props) => {
  const [loaded] = useState(false);
  const [error] = useState(false);
  const { value, setValue, label } = props;
  if (loaded) {
    return (
      <Grid item xs={12} md={3}>
        <Skeleton variant="rounded" height={60} />
      </Grid>
    );
  }
  if (error) {
    return "Unable to fetch supported country";
  }

  const dataCountries = Object.keys(currencies);
  return (
    <Grid item xs={12} md={12} lg={12}>
      <Autocomplete
        value={value}
        disableClearable
        onChange={(event, newValue) => {
          setValue(newValue.toLowerCase());
        }}
        options={dataCountries}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Grid>
  );
};

export default SelectCountry;
