import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userRegister } from "../features/user/userActions";
// form styles
import "../styles/forms.css";
const RegisterScreen = () => {
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const REG_SUCCESS = localStorage.getItem("REG_SUCCESS");
  const { loading, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: email });

  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem("IS_LOGIN_ZPAY");
    if (auth) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (REG_SUCCESS) {
      const timer = setTimeout(() => navigate("/"), 3000);
      localStorage.removeItem("REG_SUCCESS");
      return () => clearTimeout(timer);
    }
  }, [navigate, REG_SUCCESS]);
  const submitForm = (data) => {
    // check if passwords match
    if (data.password !== data.confirmPassword) {
      return new Error("Password mismatch");
    }
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(userRegister(data));
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZurePayICON" />
        </div>
        <p className="divider">
          <span>Sign Up for an Account</span>
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          <div className="flashResponse">
            {error && (
                <h3 className="alert-warning">{error}</h3>
            
            )}

            {REG_SUCCESS && (
                <h3 className="alert-success">
                  Done!, We sent you a welcome email to verify your account
                </h3>
            
            )}
          </div>
          <label htmlFor="email" style={{ display: "none" }}>
            Your email
          </label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Enter Email"
            defaultValue={email}
            {...register("email", { required: true })}
          />
          {errors.email ? <span>Please a valid email to get started</span> : ""}
          <label htmlFor="password" style={{ display: "none" }}>
            Your Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Enter Password"
            {...register("password", { required: true })}
          />
          {errors.password && <span>Please enter password</span>}
          <label htmlFor="confirmPassword" style={{ display: "none" }}>
            Your Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm Password"
            {...register("confirmPassword", { required: true })}
          />
          {errors.confirmPassword && <span>Please confirm password</span>}
          <div className="checkbox-div">
            <input
              type="checkbox"
              {...register("user_agreement", { required: true })}
            />{" "}
           
            <Link to="/pages/legal/agreement" className="mt-10">
            User Policy Agreement
        
          </Link>
          </div>

          {errors.user_agreement && (
            <span>Agreement to our user policy is required </span>
          )}
          <button type="submit" className="r-btn" disabled={loading}>
            {loading ? "Creating..." : "Register"}
          </button>
          <Link to="/login" className="mt-10">
            Login
          </Link>
        </form>
      </div>
    </section>
  );
};

export default RegisterScreen;
