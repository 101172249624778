export const testimonies = [
  {
    id: 1,
    name: "Anita Abubakar",
    photo: "t1.jpeg",
    title: "Business Owner",
    body: "Zurepay has transformed the way I manage payments for my small business. I especially appreciate the real-time transaction monitoring feature, which gives me peace of mind knowing that every transaction is secure and accounted for",
  },

  {
    id: 2,
    name: "Golden P",
    photo: "t2.jpeg",
    title: "Freelancer",
    body: "The platform's invoicing and billing features make it easy for me to create professional invoices and track payment statuses. Plus, the ability to accept payments through multiple channels has made it convenient for my clients to pay me promptly",
  },
  {
    id: 3,
    name: "Rubies Abisola",
    photo: "t3.jpeg",
    title: "Online Course Creator",
    body: "Zurepay has been instrumental in helping me monetize my online courses. Its international payment capabilities have enabled me to reach learners from all over the world, growing my online course business",
  },
];
