import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaRegCopy, FaCheck } from "react-icons/fa";

const Clipboard = () => {
  const { USER_INFO } = useSelector((state) => state.user);
  const [text, setText] = useState(
    process.env.REACT_APP_PAYME_BASE_URL + USER_INFO?.account?.payme_link_code
  );
  const [textShow, setTextShow] = useState("Copy pay me link to share");
  const [check, setCheck] = useState(<FaRegCopy />);
  const [color, setColor] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setTextShow("Link copied to clipboard");
      setCheck(<FaCheck className="txt-green" />);
      setColor(true);
    } catch (error) {
      console.error("Unable to copy to clipboard.", error);
      setTextShow(textShow);
      setCheck(<FaRegCopy />);
      setColor(false);
    }
  };
  //set copy green icon back to copy icon after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setCheck(<FaRegCopy />);
      setColor(false);
      setTextShow("Copy payme link to share");
    }, 3000);
    return () => clearTimeout(timer);
  }, [check]);

  return (
    <div className="fllex">
      <input
        disabled
        className="text-arial-styled"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <input
        disabled
        //className="text-arial-styled-show"
        className={
          color ? `text-arial-styled-show txt-green` : `text-arial-styled-show`
        }
        value={textShow}
        onChange={(e) => setTextShow(e.target.value)}
      />
      <button onClick={handleCopyClick}>{check}</button>
    </div>
  );
};

export default Clipboard;
