import { createSlice } from "@reduxjs/toolkit";
import {
  userRegister,
  userLogin,
  refreshLogin,
  userAuthLogin,
  userProfile,
  passwordResetLink,
  passwordReset,
  addPaymentCard,
  removePaymentCard,
  userStateUpdate,
  userEmailVerify,
  addAddress,
  removeAddress,
  editAddress,
  addBankDetails,
  deleteBankDetails,
  updatePhone,
  addEmail,
  updateEmail,
  refreshPasswordResetLink,
  userMessage,
  deleteBank,
  suspendAccount,
  unSuspendAccount,
  approveIdentity,
  approveResidence,
  deleteAccountByOwner,
  deleteAccountByAdmin,
  switchAccountToMerchant,
} from "./userActions";
//initialize USER_TOKEN from local storage
const USER_TOKEN = localStorage.getItem("USER_TOKEN")
  ? localStorage.getItem("USER_TOKEN")
  : null;

const IS_LOGIN_ZPAY = localStorage.getItem("IS_LOGIN_ZPAY")
  ? localStorage.getItem("IS_LOGIN_ZPAY")
  : false;
const IS_LOGIN_ZPAY_INIT = localStorage.getItem("IS_LOGIN_ZPAY_INIT")
  ? localStorage.getItem("IS_LOGIN_ZPAY_INIT")
  : false;
const USER_EMAIL = localStorage.getItem("USER_EMAIL")
  ? localStorage.getItem("USER_EMAIL")
  : null;

const CARD_ADDED = localStorage.getItem("CARD_ADDED")
  ? localStorage.getItem("CARD_ADDED")
  : false;
const REG_USER_EMAIL = localStorage.getItem("REG_USER_EMAIL")
  ? localStorage.getItem("REG_USER_EMAIL")
  : false;
const USER_ADDRESS_ADDED = localStorage.getItem("USER_ADDRESS_ADDED")
  ? localStorage.getItem("USER_ADDRESS_ADDED")
  : false;
const USER_ADDRESS_REMOVED = localStorage.getItem("USER_ADDRESS_REMOVED")
  ? localStorage.getItem("USER_ADDRESS_REMOVED")
  : false;
const USER_ADDRESS_EDITED = localStorage.getItem("USER_ADDRESS_EDITED")
  ? localStorage.getItem("USER_ADDRESS_EDITED")
  : false;
const BANK_DETAILS_ADDED = localStorage.getItem("BANK_DETAILS_ADDED")
  ? localStorage.getItem("BANK_DETAILS_ADDED")
  : false;

const BANK_DETAILS_DELETED = localStorage.getItem("BANK_DETAILS_DELETED")
  ? localStorage.getItem("BANK_DETAILS_DELETED")
  : false;

const initialState = {
  loading: false,
  isLoading: false,
  error: null,
  USER_INFO: null,
  IS_LOGIN_ZPAY,
  IS_LOGIN_ZPAY_INIT,
  USER_EMAIL,
  USER_TOKEN,
  REG_SUCCESS: false,
  PASS_RESET_LINK: false,
  PASS_RESET: false,
  FIRST_NAME: false,
  PROFILE_DATA: null,
  //card details
  CARD_ADDED,
  //registtration
  REG_USER_EMAIL,
  //addresses,
  USER_ADDRESS_ADDED,
  USER_ADDRESS_REMOVED,
  USER_ADDRESS_EDITED,
  //bank details
  BANK_DETAILS_ADDED,
  BANK_DETAILS_DELETED,
  //email verification
  EMAIL_VERIFIED: null,
  //message
  MSG_SUCCESS: false,
  //switch to merchant
  SWITCHED_TO_MERCHANT: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      //revert state to default
      state.error = null;
      state.loading = false;
      state.isLoading = false;
      state.IS_LOGIN_ZPAY = false;
      state.IS_LOGIN_ZPAY_INIT = false;
      state.USER_EMAIL = null;
      state.USER_INFO = null;
      state.USER_TOKEN = null;
      state.REG_SUCCESS = false;
      state.PASS_RESET_LINK = false;
      //complete profile
      state.PROFILE_DATA = null;
      state.PROFILE_COMPLETED = null;
      //more address added
      state.ADDRESS_ADDED = null;
      state.USER_ADDRESS_REMOVED = null;
      state.USER_ADDRESS_EDITED = null;
      state.BANK_DETAILS_ADDED = null;
      state.BANK_DETAILS_DELETED = null;

      state.REG_USER_EMAIL = null;
      //email verification
      state.EMAIL_VERIFIED = null;
      //password reset
      state.PASS_RESET = null;
      //message
      state.MSG_SUCCESS = null;
      state.DELETE_ACCOUNT_BY_OWNER = null;
      state.DELETE_ACCOUNT_BY_ADMIN = null;
      state.SUSPEND_ACCOUNT = null;
      state.UNSUSPEND_ACCOUNT = null;
      state.APPROVE_KYC_IDENTITY = null;
      state.APPROVE_KYC_RESIDENCE = null;
      state.SWITCHED_TO_MERCHANT = null;
    },
  },
  extraReducers(builder) {
    builder
      // register user
      .addCase(userRegister.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userRegister.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.REG_SUCCESS = true; // registration successful
      })
      .addCase(userRegister.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // user email verify

      .addCase(userEmailVerify.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.EMAIL_VERIFIED = false;
      })
      .addCase(userEmailVerify.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.EMAIL_VERIFIED = true; // email verification successful
        state.REG_USER_EMAIL = null;
      })
      .addCase(userEmailVerify.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.EMAIL_VERIFIED = false;
      })
      // refresh login state
      .addCase(refreshLogin.pending, (state) => {
        state.loading = true;
        state.isLoading = true;
        state.IS_LOGIN_ZPAY_INIT = false;
        state.error = null;
      })
      .addCase(refreshLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isLoading = false;
        state.IS_LOGIN_ZPAY_INIT = false;
      })
      .addCase(refreshLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.IS_LOGIN_ZPAY_INIT = false;
        state.error = payload;
      })
      // login init user
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.isLoading = true;
        state.IS_LOGIN_ZPAY_INIT = false;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isLoading = false;
        state.IS_LOGIN_ZPAY_INIT = true;
        state.PASS_RESET = null;
        state.USER_INFO = payload;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.IS_LOGIN_ZPAY_INIT = false;
        state.error = payload;
      }) // login auth user
      .addCase(userAuthLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userAuthLogin.fulfilled, (state, { payload }) => {
        localStorage.removeItem("IS_LOGIN_ZPAY_INIT");
        state.IS_LOGIN_ZPAY_INIT = false;
        state.loading = false;
        state.USER_INFO = payload;
        state.IS_LOGIN_ZPAY = true;
      })
      .addCase(userAuthLogin.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
        state.ERROR_MSG = payload.error;
      })
      //complete profile
      .addCase(userProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
        state.PROFILE_DATA = payload;
        state.PROFILE_COMPLETED = true;
      })
      .addCase(userProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.PROFILE_COMPLETED = false;
      }) //add more address
      .addCase(addAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.ADDRESS_ADDED = null;
      })
      .addCase(addAddress.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
        state.PROFILE_DATA = payload;
        state.ADDRESS_ADDED = true;
      })
      .addCase(addAddress.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.ADDRESS_ADDED = null;
      }) //remove address
      .addCase(removeAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.USER_ADDRESS_REMOVED = null;
      })
      .addCase(removeAddress.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
        state.PROFILE_DATA = payload;
        state.USER_ADDRESS_REMOVED = true;
      })
      .addCase(removeAddress.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.USER_ADDRESS_REMOVED = null;
      }) //edit address
      .addCase(editAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.USER_ADDRESS_EDITED = null;
      })
      .addCase(editAddress.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
        state.PROFILE_DATA = payload;
        state.USER_ADDRESS_EDITED = true;
      })
      .addCase(editAddress.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.USER_ADDRESS_EDITED = null;
      }) //bank details added
      .addCase(addBankDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.BANK_DETAILS_ADDED = null;
      })
      .addCase(addBankDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
        state.BANK_DATA = payload;
        state.BANK_DETAILS_ADDED = true;
      })
      .addCase(addBankDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.BANK_DETAILS_ADDED = null;
      }) //bank details deleted
      // .addCase(deleteBankDetails.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      //   state.BANK_DETAILS_DELETED = null;
      // })
      // .addCase(deleteBankDetails.fulfilled, (state, { payload }) => {
      //   state.loading = false;
      //   state.USER_INFO = payload;
      //   state.BANK_DATA = payload;
      //   state.BANK_DETAILS_DELETED = true;
      // })
      // .addCase(deleteBankDetails.rejected, (state, { payload }) => {
      //   state.loading = false;
      //   state.error = payload;
      //   state.BANK_DETAILS_DELETED = null;
      // })
      //send password Reset Link
      .addCase(passwordResetLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(passwordResetLink.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.PASS_RESET_LINK = true;
      })
      .addCase(passwordResetLink.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.PASS_RESET_LINK = false;
      }) //password reset
      .addCase(passwordReset.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(passwordReset.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.PASS_RESET = true;
        state.PASS_RESET_LINK = null;
      })
      .addCase(passwordReset.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.PASS_RESET = false;
      }) //add debit / credit card to account
      .addCase(addPaymentCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPaymentCard.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
      })
      .addCase(addPaymentCard.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //remove debit / credit card from account
      .addCase(removePaymentCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removePaymentCard.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
      })
      .addCase(removePaymentCard.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //upload files
      .addCase(userStateUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userStateUpdate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
      })
      .addCase(userStateUpdate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //update phone number
      .addCase(updatePhone.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePhone.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
      })
      .addCase(updatePhone.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //add secondary email
      .addCase(addEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addEmail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
      })
      .addCase(addEmail.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //update secondary email
      .addCase(updateEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEmail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
      })
      .addCase(updateEmail.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //refresh password reset link
      .addCase(refreshPasswordResetLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refreshPasswordResetLink.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
      })
      .addCase(refreshPasswordResetLink.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //send message from form
      .addCase(userMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userMessage.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.MSG_SUCCESS = payload;
      })
      .addCase(userMessage.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //delete bank details (testint)
      .addCase(deleteBank.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBank.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.USER_INFO = payload;
      })
      .addCase(deleteBank.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //delete account by owner
      .addCase(deleteAccountByOwner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAccountByOwner.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.DELETE_ACCOUNT_BY_OWNER = payload;
      })
      .addCase(deleteAccountByOwner.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      //suspend account by admin
      .addCase(suspendAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(suspendAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.SUSPEND_ACCOUNT = payload;
      })
      .addCase(suspendAccount.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      //un suspend account by admin
      .addCase(unSuspendAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unSuspendAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.UNSUSPEND_ACCOUNT = payload;
      })
      .addCase(unSuspendAccount.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      //approve kyc identity by admin
      .addCase(approveIdentity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveIdentity.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.APPROVE_KYC_IDENTITY = payload;
      })
      .addCase(approveIdentity.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //approve kyc residence by admin
      .addCase(approveResidence.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveResidence.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.APPROVE_KYC_RESIDENCE = payload;
      })
      .addCase(approveResidence.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      //delete account by admin
      .addCase(deleteAccountByAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAccountByAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.DELETE_ACCOUNT_BY_ADMIN = payload;
      })
      .addCase(deleteAccountByAdmin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }) //switch to merchant
      .addCase(switchAccountToMerchant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(switchAccountToMerchant.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.SWITCHED_TO_MERCHANT = payload;
      })
      .addCase(switchAccountToMerchant.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
