import { useContext, useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import InputAmount from "./InputAmount";
import SelectCountry from "./SelectCountry";
import SwitchCurrency from "./SwitchCurrency";
import { CurrencyContext } from "./context/CurrencyContext";
import { moneyFormatter } from "../utils/moneyFormatter";

const CurrencyConverter = () => {
  const {
    fromCurrency,
    setFromCurrency,
    toCurrency,
    setToCurrency,
    firstAmount,
  } = useContext(CurrencyContext);
  const [resultCurrency, setResultCurrency] = useState(0);
  const codeFromCurrency = fromCurrency.split(" ")[0];
  const codeToCurrency = toCurrency.split(" ")[0];
  useEffect(() => {
    if (firstAmount) {
      axios(
        `https://v6.exchangerate-api.com/v6/${process.env.REACT_APP_EXCHANGE_RATE_API}/pair/${codeFromCurrency}/${codeToCurrency}`
      )
        .then((response) => setResultCurrency(response.data.conversion_rate))
        .catch((error) => console.log(error));
    }
  }, [firstAmount, fromCurrency, toCurrency, codeFromCurrency, codeToCurrency]);

  const boxStyles = {
    marginTop: "10%",
    marginLeft: "0%",
    textAlign: "center",
    color: "#222",
    minHeight: "20rem",
    borderRadius: 2,
    padding: "0rem",
    boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
    position: "relative",
  };

  const newAmount =
    (resultCurrency -
      resultCurrency * process.env.REACT_APP_EXCHANGE_RATE_ADJUSTED_VALUE) *
    firstAmount;

  return (
    <div className="currency-converter-container border-2">
      <Container maxWidth="md" sx={boxStyles}>
        <Grid container spacing={0} marginBottom={5}>
          <InputAmount />
        </Grid>

        <Grid container spacing={2}>
          <SelectCountry
            value={fromCurrency}
            setValue={setFromCurrency}
            label="From Currency"
          />
          <SwitchCurrency />
          <SelectCountry
            value={toCurrency}
            setValue={setToCurrency}
            label="To Currency"
          />
        </Grid>

        {firstAmount && (
          <Box
            sx={{ textAlign: "left", marginTop: "1rem", paddingBottom: "5px" }}
          >
            <Typography
              sx={{
                marginTop: "1px",
                marginBottom: "2px",
                fontWeight: "thin",
                color: "#555",
              }}
            >
              When you send
            </Typography>
            <Typography
              sx={{
                marginTop: "1px",
                marginBottom: "2px",
                fontWeight: "bold",
                color: "#555",
              }}
            >
              {moneyFormatter(firstAmount, codeFromCurrency)}
            </Typography>
            <Typography
              sx={{
                marginTop: "1px",
                marginBottom: "2px",
                fontWeight: "thin",
                color: "#555",
              }}
            >
              Recepient gets
            </Typography>
            <Typography
              sx={{
                marginTop: "1px",
                marginBottom: "1px",

                fontWeight: "bold",
                color: "#555",
              }}
            >
              {moneyFormatter(newAmount, codeToCurrency)}
            </Typography>
          </Box>
        )}
      </Container>
    </div>
  );
};
export default CurrencyConverter;
