

import { GrCompliance, GrInsecure } from "react-icons/gr";
import { MdDataUsage } from "react-icons/md";
import { RiSecurePaymentLine, RiSecurePaymentFill } from "react-icons/ri";
import { MdCurrencyExchange } from "react-icons/md";
import { MdSupportAgent } from "react-icons/md";
import { TbHeartRateMonitor } from "react-icons/tb";

export const features = [
    {
        id: 1,
        icon: <MdDataUsage />,
        title: "Eaze of Use",
        body: "An intuitive and user-friendly interface, without extensive training or technical expertise."
    },
    {
        id: 2,
        icon: <GrCompliance />,
        title: "Compliance",
        body: "Adheres to relevant regulatory requirements and industry standards to ensure compliance with data protection laws, anti-money laundering regulations, and other legal obligations."
    },
    {
        id: 3,
        icon: <MdCurrencyExchange />,
        title: "Domestic and International Transactions",
        body: "Seamless transactions both domestically and internationally, send and receive payments across borders with ease, regardless of location."
    },
    {
        id: 4,
        icon: <RiSecurePaymentLine />,
        title: "Multi-Channel Payment Processing",
        body: "Supports various payment channels, including credit/debit cards, bank transfers and e-wallets, providing users with flexible options for making and receiving payments."
    },
    {
        id: 5,
        icon: <GrInsecure />,
        title: "Secure Transactions",
        body: "Prioritizes security, implementing advanced encryption protocols, multi-factor authentication, and fraud detection systems."
    },
    {
        id: 6,
        icon: <RiSecurePaymentFill />,
        title: "International Payments",
        body: "Facilitates seamless cross-border transactions, supporting multiple currencies and providing competitive exchange rates for international payments.     "
    },
    {
        id: 7,
        icon: <TbHeartRateMonitor />,
        title: "Real-Time Transaction Monitoring",
        body: "Real-time transaction monitoring tools that provide insights into payment activities, account balances, and transaction statuses."

    },

    {
        id: 8,
        icon: <MdSupportAgent />,
        title: "Dedicated Customer Support",
        body: "Responsive customer support services, including live chat, email support, and a knowledge base, to assist users."
    }



]