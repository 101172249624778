import React, { useEffect, useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { removePaymentCard } from "../features/user/userActions";
import { SpinnerCircular } from "spinners-react";
import { HideString } from "../utils/blindit";

const RemoveCardConfirm = (props) => {
  const [cardID, setCardID] = useState(
    localStorage.getItem("CARD_ID") ? localStorage.getItem("CARD_ID") : ""
  );
  const [cardNum, setCardNum] = useState(
    localStorage.getItem("CARD_NUM") ? localStorage.getItem("CARD_NUM") : ""
  );

  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const removed = localStorage.getItem("CARD_REMOVED");
  const [reveal, setReveal] = useState(false);

  //const [cardID, setCardId] = useState();
  const { loading, error, USER_INFO } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const message = USER_INFO?.message;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email, cardID } });

  useEffect(() => {
    if (removed) {
      const timer = setTimeout(() => {
        localStorage.removeItem("CARD_REMOVED");
        localStorage.removeItem("CARD_ID");
        return () => clearTimeout(timer);
      }, 1000);
    }
  }, [removed]);
  const submitForm = (data) => {
    dispatch(removePaymentCard(data));
    setReveal(false);
    console.log(data);
  };

  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <h3 className="alert-warning">You are about to remove this card?</h3>
          {error && (
              <div className="alert-warning">{error}</div>
          
          )}
          {removed && (
              <div className="alert-success">{message}</div>
         
          )}
          <h3>{HideString(cardNum.slice(52, 68))}</h3>
          <div className="full-flex">
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />

            <input
              type="hidden"
              placeholder="cardID"
              defaultValue={cardID}
              {...register("cardID", { required: true })}
            />
          </div>

          <br />
          <br />
          <div className="btn-container">
            <button type="submit" className="login">
              {loading ? <SpinnerCircular size={20} /> : " Remove Card"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RemoveCardConfirm;
