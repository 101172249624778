import React from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../utils/scroller";
import {
  FaFacebook,
  FaFacebookSquare,
  FaInstagram,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
// import { BsTwitterX } from "react-icons/bs";
import Address from "./Address";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div>
          <h3>Zurepay</h3>
          <p>
            <Link
              onClick={() => scrollToTop()}
              className="link"
              to="/pages/about"
            >
              About Us
            </Link>

            <Link
              onClick={() => scrollToTop()}
              className="link"
              to="/pages/howItWorks"
            >
              How It Works
            </Link>

            <Link
              onClick={() => scrollToTop()}
              className="link"
              to="/pages/fees"
            >
              Fees
            </Link>
          </p>
        </div>
        <div>
          <h3>Help</h3>
          <p>
            <Link
              onClick={() => scrollToTop()}
              className="link"
              to="/pages/contact"
            >
              Click here Contact Us
            </Link>

            <Link className="link">Email: support@zurepay.com</Link>
            <Link
              to="https://www.youtube.com/@ZurePay"
              target="_blank"
              className="link"
            >
              Helpful Short videos
            </Link>
          </p>
        </div>
        <div>
          <h3>Legal</h3>
          <p>
            <Link
              onClick={() => scrollToTop()}
              className="link"
              to="/pages/legal/policy"
            >
              Privacy Policy
            </Link>
            <Link
              onClick={() => scrollToTop()}
              className="link"
              to="/pages/legal/protection"
            >
              Buyer Protection
            </Link>
            <Link
              onClick={() => scrollToTop()}
              className="link"
              to="/pages/legal/refund"
            >
              Refund Policy
            </Link>
            <Link
              onClick={() => scrollToTop()}
              className="link"
              to="/pages/legal/payout"
            >
              Payout Agreement
            </Link>
            <Link
              onClick={() => scrollToTop()}
              className="link"
              to="/pages/legal/agreement"
            >
              User Agreement
            </Link>
          </p>
        </div>
        <div>
          <h3>Social</h3>
          <p>
            <li className="link social">
              <a
                href="https://www.facebook.com/zurePayfb"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare />
              </a>
            </li>
            <li className="link social">
              <a
                href="https://www.instagram.com/zurePay"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
            </li>

            <li className="link social">
              <a
                href="https://www.youtube.com/@ZurePay"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube />
              </a>
            </li>
            <li className="link social">
              <a
                href="https://www.tiktok.com/@zurepay"
                target="_blank"
                rel="noreferrer"
              >
                <FaTiktok />
              </a>
            </li>
          </p>
        </div>
      </div>
      <Address />
    </div>
  );
};

export default Footer;
