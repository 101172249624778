import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { passwordResetLink } from "../features/user/userActions";

// form styles
import "../styles/forms.css";
const PasswordResetLinkScreen = () => {
  const [email, setEmail] = useState("");
  const { loading, error, PASS_RESET_LINK, IS_LOGIN_ZPAY } = useSelector(
    (state) => state.user
  );
  const pass_reset_link_set = localStorage.getItem("PASS_RESET_LINK_SET");
  console.log(pass_reset_link_set);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email } });
  const navigate = useNavigate();

  useEffect(() => {
    if (IS_LOGIN_ZPAY) {
      const timer = setTimeout(() => {
        navigate("/my/dashboard");
      }, 3000);
      return () => clearTimeout(timer);
    } else if (pass_reset_link_set) {
      const timer = setTimeout(() => {
        localStorage.removeItem("PASS_RESET_LINK_SET");
        navigate("/");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, IS_LOGIN_ZPAY, pass_reset_link_set]);

  const submitForm = (data) => {
    dispatch(passwordResetLink(data));
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZurePayICON" />
        </div>
        <p className="divider">
          <span>Request password reset link</span>
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {error && (
              <h6 className="alert-warning">{error}</h6>
       
          )}
          {PASS_RESET_LINK && (
              <h3 className="alert-success">Password reset link sent</h3>
          
          )}
          {IS_LOGIN_ZPAY && (
              <h3 className="alert-warning">
                You don't seem to forgot password
              </h3>
          
          )}

          <input
            type="email"
            {...register("email", { required: true })}
            placeholder="Enter Email"
          />
          {errors.email && <span>Your account Email is required</span>}

          <button
            type="submit"
            className="r-btn"
            disabled={loading && PASS_RESET_LINK}
          >
            {loading ? "Sending..." : "Request Link"}
          </button>
          <Link to="/login" className="mt-10">
            Remember password?
          </Link>
          <Link to="/refresh/link" className="mt-10">
            Didn't receive password reset link?
          </Link>
        </form>
      </div>
    </section>
  );
};

export default PasswordResetLinkScreen;
