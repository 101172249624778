import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Popup from "./Popup";

import AddCard from "../screens/AddCard";
import AddBankDetails from "../screens/AddBankDetails";
import FundWallet from "../screens/FundWallet";
import SendFund from "../screens/SendFund";
import RequestFund from "../screens/RequestFund";
import RequestWithdrawal from "../screens/RequestWithdrawal";
import RefreshWalletBalances from "../screens/RefreshWalletBalances";
import RemoveCard from "../screens/RemoveCard";
import { HideString } from "../utils/blindit";
import DeleteBank from "../screens/DeleteBank";
import Clipboard from "../components/Clipboard";
import ClipboardRevealLink from "../components/ClipboardRevealLink";
import { moneyFormatter } from "../utils/moneyFormatter";
//Admin Dash
import "react-credit-cards-2/es/styles-compiled.css";
import { FaCheckCircle } from "react-icons/fa";
import SwapCurrency from "../converter/SwapCurrency";
import CreateCardHolder from "../screens/CreateCardHolder";

import ActivitiesMenu from "../components/ActivitiesMenu/ActivitiesMenu";
import CreateCard from "../screens/CreateCard";
import PaymentButton from "../components/PaymentButton";

const Dashboard = () => {
  const { USER_INFO, IS_LOGIN_ZPAY } = useSelector((state) => state.user);
  const { BALANCES } = useSelector((state) => state.wallet);
  let bal = BALANCES?.curr;

  const navigate = useNavigate();
  useEffect(() => {
    if (!IS_LOGIN_ZPAY) {
      const timer = setTimeout(() => navigate("/login"), 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, IS_LOGIN_ZPAY]);
  //find wallet balances
  const [addCard, setAddCard] = useState(false);
  const [addBankDetails, setAddBankDetails] = useState(false);
  const [deleteBank, setDeleteBank] = useState(false);
  const [isFunding, setIsFunding] = useState(false);
  const [sendFund, setSendFund] = useState(false);
  const [requestFund, setRequestFund] = useState(false);
  const [requestWithdrawal, setRequestWithdrawal] = useState(false);
  const [swapCurrency, setSwapCurrency] = useState(false);
  const [cardHolder, setCardHolder] = useState(false);
  const [createCard, setCreateCard] = useState(false);
  const toggleAddCard = () => {
    setAddCard(!addCard);
  };
  const toggleIsFunding = () => {
    setIsFunding(!isFunding);
  };

  const toggleSendFund = () => {
    setSendFund(!sendFund);
  };

  const toggleRequestFund = () => {
    setRequestFund(!requestFund);
  };
  const toggleAddBankDetails = () => {
    setAddBankDetails(!addBankDetails);
  };

  const toggleDeleteBank = () => {
    setDeleteBank(!deleteBank);
  };
  const toggleSwapCurrency = () => {
    setSwapCurrency(!swapCurrency);
  };
  const toggleCreateCardHolder = () => {
    setCardHolder(!cardHolder);
  };
  const toggleCreateCard = () => {
    setCreateCard(!createCard);
  };
  const toggleRequestWithdrawal = () => {
    setRequestWithdrawal(!requestWithdrawal);
  };

  return (
    <section className="dashboard">
      <div className="container">
        <div className="dash-left dash-left-bg">
          <div className="small-card flex-out">
            <img src="/images/icon.png" alt="ZPAY-ICON" />
            <p className="sixteen">Android/iOS Apps launching soon...</p>
          </div>

          <div className="medium-card">
            <h3> My Wallet Balance(s)</h3>
            <span className="">
              {bal?.length > 0
                ? bal?.map((b, idx) => {
                    return (
                      <div key={idx}>
                        {moneyFormatter(b?.balance, b?.transaction_currency)}
                      </div>
                    );
                  })
                : 0.0}
            </span>
            <br />
            <RefreshWalletBalances />
            <br />

            {/* <Link to="/fundform" className="signup">
              Fund Your Wallet
            </Link> */}

            <button className="signup" onClick={toggleIsFunding}>
              Fund Your Wallet
            </button>
          </div>

          <div className="large-card">
            <h3>My Fund Activities</h3>
            <p>
              See when money comes in, and when it goes out. You’ll find your
              recent Zurepay transactions activities here...
            </p>
            <ActivitiesMenu />
          </div>
        </div>
        {/* Right conts */}
        <div className="dash-right">
          {USER_INFO?.account?.account_type !== "Admin" &&
            USER_INFO?.account?.profile_status !== "COMPLETED" && (
              <div className="small-card flex-out">
                <Link to="/my/profile" className="signup">
                  You are yet to complete your profile
                  <br />
                  <br />
                  Click here to complete your profile
                </Link>
              </div>
            )}

          {/* Add Bank details */}
          {USER_INFO?.account?.profile_status === "COMPLETED" &&
            USER_INFO?.account?.bank_details !== "ADDED" && (
              <div className="small-card flex-out">
                <li>
                  <Link>
                    <input
                      className="login"
                      type="button"
                      value="Add bank details for cash withdrawal"
                      onClick={toggleAddBankDetails}
                    />
                  </Link>
                </li>
              </div>
            )}
          {USER_INFO?.account?.profile_status === "COMPLETED" &&
            USER_INFO?.account?.bank_details === "ADDED" && (
              <div className="small-card flex-out">
                <hr />
                <h3>Bank Details</h3>
                <p className="links">
                  <span>
                    Account Number: <br /> <br />
                    {USER_INFO?.account
                      ? HideString(
                          `${USER_INFO?.account?.bank_data?.account_number}`
                        )
                      : HideString("00000000000")}
                  </span>
                  <br />
                  <br />
                  <li>
                    <span className="cursor" onClick={toggleDeleteBank}>
                      Delete Bank Details
                    </span>
                  </li>
                  <br />
                  <br />
                  <li>
                    <span className="cursor" onClick={toggleRequestWithdrawal}>
                      Make Cash Withdrawal
                    </span>
                  </li>
                </p>
              </div>
            )}
          {USER_INFO?.account?.profile_status === "COMPLETED" && (
            <div className="small-card grid-out">
              <button className="signup" onClick={toggleSwapCurrency}>
                Swap Currency
              </button>
              <button className="signup" onClick={toggleSendFund}>
                Send Fund
              </button>
              <button className="signup" onClick={toggleRequestFund}>
                Request Fund
              </button>

              {/* Swap Currency  */}

              {/* card Holder and card creation */}
              {/* {cardHolder && (
              <Popup
                content={<CreateCardHolder />}
                handleClose={toggleCreateCardHolder}
                />
                )}
                {!USER_INFO?.account?.isStripeCardHolder &&
                USER_INFO?.account?.profile_status === "COMPLETED" && (
                  <button className="login" onClick={toggleCreateCardHolder}>
                  Create Card Holder
                  </button>
                  )}
                  {createCard && (
                    <Popup content={<CreateCard />} handleClose={toggleCreateCard} />
                    )}
                    {USER_INFO?.account?.isStripeCardHolder && (
                      <button className="login" onClick={toggleCreateCard}>
                      Create Card
                      </button>
                      )} */}

              {/* card Holder and card creation  */}
            </div>
          )}
          {/* Add KYC Residence */}
          {USER_INFO?.account?.profile_status === "COMPLETED" && (
            <div className="small-card grid-out">
              {USER_INFO?.account?.proof_of_residence_status === "PENDING" && (
                <Link to="/kyc/residence" className="login">
                  Upload proof of residence
                </Link>
              )}
              {USER_INFO?.account?.proof_of_residence_status === "INITIATED" &&
                "Proof of residence uploaded, Waiting for approval"}
              <br />
              <br />
              {USER_INFO?.account?.proof_of_residence_status === "APPROVED" && (
                <div className="fllex">
                  KYC Residence:
                  <FaCheckCircle className="txt-green" />
                </div>
              )}

              {/* proof of identity */}
              {USER_INFO?.account?.proof_of_identity_status === "PENDING" && (
                <Link to="/kyc/identity" className="login">
                  Upload proof of identity
                </Link>
              )}
              {USER_INFO?.account?.proof_of_identity_status === "INITIATED"
                ? "Proof of identity uploaded, Waiting for approval"
                : ""}
              <br />
              {USER_INFO?.account?.proof_of_identity_status === "APPROVED" && (
                <div className="fllex">
                  KYC Identity:
                  <FaCheckCircle className="txt-green" />
                </div>
              )}
            </div>
          )}
          {USER_INFO?.account?.profile_status === "COMPLETED" && (
            <>
              {USER_INFO?.account?.has_payme_link_code && (
                <div className="small-card grid-out">
                  <Clipboard />
                </div>
              )}
              {USER_INFO?.account?.account_type === "Merchant" && (
                <div className="small-card grid-out">
                  <ClipboardRevealLink />
                </div>
              )}
            </>
          )}
          <div className="flat-card">
            {/* <button className="signup" onClick={toggleSendFund}>
              Send Fund
            </button>
            <button className="signup" onClick={toggleRequestFund}>
              Request Fund
            </button> */}
            {/* Swap Currency  */}
            {swapCurrency && (
              <Popup
                content={<SwapCurrency />}
                handleClose={toggleSwapCurrency}
              />
            )}
            {sendFund && (
              <Popup content={<SendFund />} handleClose={toggleSendFund} />
            )}

            {requestFund && (
              <Popup
                content={<RequestFund />}
                handleClose={toggleRequestFund}
              />
            )}
            {requestWithdrawal && (
              <Popup
                content={<RequestWithdrawal />}
                handleClose={toggleRequestWithdrawal}
              />
            )}
            {isFunding && (
              <Popup content={<FundWallet />} handleClose={toggleIsFunding} />
            )}
          </div>
          {/* Link Bank/Cards */}

          <div>
            {addBankDetails && (
              <Popup
                content={<AddBankDetails />}
                handleClose={toggleAddBankDetails}
              />
            )}

            {deleteBank && (
              <Popup content={<DeleteBank />} handleClose={toggleDeleteBank} />
            )}
            {addCard && (
              <Popup content={<AddCard />} handleClose={toggleAddCard} />
            )}
          </div>
          {USER_INFO?.account?.cards?.map((item) => {
            return <RemoveCard key={item.id} {...item} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
