import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../features/user/userActions";
import { SpinnerCircular } from "spinners-react";

const AddAddress = (props) => {
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postal_code, setPostalCode] = useState("");

  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const added = localStorage.getItem("USER_ADDRESS_ADDED");
  const { loading, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email } });
  const navigate = useNavigate();

  useEffect(() => {
    const uEmail = localStorage.getItem("USER_EMAIL");
    if (uEmail) {
      setEmail(uEmail);
    }
    if (added) {
      const timer = setTimeout(() => {
        localStorage.removeItem("USER_ADDRESS_ADDED");
        navigate("/my/dashboard");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [navigate, added]);

  const submitForm = (data) => {
    // check input data
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(addAddress(data));
    console.log(data);
    setCity("");
    setStreet("");
    setPostalCode("");
    setCountry("");
  };
  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <i className="sixteen">Add Another Address to your profile</i>
          {error && (
          
              <div className="alert-warning">{error}</div>
            
          )}
          {added && (
           
              <div className="alert-success">Address added successfully</div>
           
          )}
          <div className="full-flex">
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />

            <input
              type="text"
              placeholder="Enter Street name / Number"
              {...register("street", { required: true })}
              onChange={(e) => setStreet(e.target.value)}
              onFocus={(e) => setStreet(e.target.value)}
            />
            {errors.street && <span>Street name and number is required</span>}
            <input
              type="text"
              placeholder="Enter City"
              {...register("city", { required: true })}
              onChange={(e) => setCity(e.target.value)}
              onFocus={(e) => setCity(e.target.value)}
            />
            {errors.city && <span>City is required</span>}
            <input
              type="text"
              placeholder="Enter Postal/Zip Code"
              {...register("postal_code", { required: true })}
              onChange={(e) => setPostalCode(e.target.value)}
              onFocus={(e) => setPostalCode(e.target.value)}
            />
            {errors.postal_code && <span>Postal/Zip Code is required</span>}
            <input
              type="text"
              placeholder="Enter Country"
              {...register("country", { required: false })}
              onChange={(e) => setCountry(e.target.value)}
              onFocus={(e) => setCountry(e.target.value)}
            />
            {errors.country && <span>Country is required</span>}
          </div>
        
          <div className="btn-container">
          <button></button>
            <button type="submit" className="login">
              {loading ? <SpinnerCircular size={20} /> : " Add Address"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAddress;
