export const HideString = (str) => {
  const regex = new RegExp("[0-9a-zA-Z](?=.*.{3})", "gmi");
  const subst = ` * `;
  return str.replace(regex, subst);
};
//hide email tring
export const blindEmail = (email) => {
  if (email) return email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1*****@$2");
};

export const normalizeCardNum = (value) => {
  return (
    value
      .replace(/\s/g, "")
      .match(/.{1,4}/g)
      ?.join(" ")
      .substr(0, 19) || ""
  );
};

//Normalize card Expiry
export const normalizeCardED = (value) => {
  return (
    value
      .split("/")
      ?.join("")
      ?.replace(/\s/g, "")
      .match(/.{1,2}/g)
      ?.join("/")
      .substr(0, 5) || ""
  );
};

export const normalizeCVV = (value) => {
  return (
    value
      .replace(/\s/g, "")
      .match(/.{1,4}/g)
      ?.join("")
      .substr(0, 4) || ""
  );
};

export const walletTotal = (array) => {
  let sum = 0;
  array?.forEach((bal) => {
    sum += bal;
  });
  return sum;
};
