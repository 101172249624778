import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userStateUpdate } from "../features/user/userActions";
const KYCIdentityScreen = () => {
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState(
    localStorage.getItem("FILE_NAME")
      ? localStorage.getItem("FILE_NAME")
      : "zurepay_zero_avatar.png"
  );
  const [uploadedFile, setUploadedFile] = useState({});
  const [select, setSelect] = useState("Select identity doc...");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const { register, handleSubmit } = useForm({ defaultValues: { email } });

  const { USER_INFO } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        navigate("/my/dashboard");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, success]);
  useEffect(() => {
    if (filename) {
      const timer = setTimeout(() => { }, 3000);
      setFilename("FILE_NAME");
      return () => clearTimeout(timer);
    }
  }, [filename]);

  const onFileSelected = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setSelect(e.target.files[0].name);
  };
  const submitForm = async (data, e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("email", email);
    try {
      setIsLoading(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("kyc/identity", formData, config);
      const { fileName, filePath } = data;
      setUploadedFile({ fileName, filePath });
      localStorage.setItem("FILE_NAME", fileName);
      localStorage.setItem("FILE_PATH", filePath);
      dispatch(userStateUpdate(data));
    } catch (error) {
      setError(true);
      if (error?.response) {
        setIsLoading(false);
        setErrMsg(error.response.data.error);
        return;
      }
    }
    setIsLoading(false);
    setSuccess(true);
    setSuccessMsg("File uploaded successfully");
  };

  return (
    <section className="form-container-modal">
      <div className="formly-modal">
        <div className="form-image">
          <img
            className="form_icon"
            src={`/images/icon.png`}
            alt="ZPAY-ICON"
          />
        </div>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {error && (
            <span className="alert-warning txt-red">
              {errMsg}
            </span>
          )}

          {success && (
            <span className="alert-success txt-green">
              {successMsg}
            </span>
          )}

          <input
            type="hidden"
            placeholder="Email"
            defaultValue={email}
            {...register("email", { required: true })}
          />
          <input
            type="file"
            className="upload-file-input"
            placeholder="Click to attach"
            {...register("file", { required: true })}
            onChange={onFileSelected}
          />
          <label
            className="upload-label"
            htmlFor="attached_file"
            style={{ cursor: "pointer", color: "#09f" }}
          >
            {select}
          </label>
          <button type="submit" className="r-btn" disabled={isLoading}>
            {isLoading ? "Uploding..." : "Upload identity"}
          </button>
          <Link to="/" className="signup mt-10">
            Complete it later
          </Link>
        </form>
      </div>
    </section>
  );
};

export default KYCIdentityScreen;
