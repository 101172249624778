import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { compareDesc } from "date-fns";
import axios from "../api/axios";
import { moneyFormatter } from "../utils/moneyFormatter";

const columns = [
  {
    name: "DATE",
    selector: (row) => dateFormat(row.transaction_date, " mmmm dS, yyyy"),
  },

  {
    name: "RECIPIENTS",
    selector: (row) => row.recipient,
  },

  {
    name: "AMOUNT",
    selector: (row) =>
      moneyFormatter(row?.transaction_amount, row?.transaction_currency),
  },

  {
    name: "TYPE",
    selector: (row) => <span className="txt-red">DEBIT</span>,
  },
  {
    name: "STATUS",
    selector: (row) => row.transaction_status,
  },
];

const TransactionsSent = () => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const { USER_INFO } = useSelector((state) => state.user);
  useEffect(() => {
    axios
      .get(`payments/sent/${USER_INFO.account._id}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        throw new Error(error, " Error encountered while fetching data");
      });
  }, [USER_INFO.account._id]);
  if (!data) return null;
  data?.sent_funds?.sort(function (a, b) {
    //return a - b;
    return compareDesc(a.transaction_date, b.transaction_date);
  });

  return (
    <section className="dashboard light-gray-bg">
      <div className="table-container">
        {loading ? (
          "LOADING..."
        ) : (
          <DataTable columns={columns} data={data.sent_funds} />
        )}
      </div>
    </section>
  );
};

export default TransactionsSent;
