import React, { useEffect, useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { deleteBank } from "../features/user/userActions";

const DeleteBank = () => {
  const { error, loading, USER_INFO } = useSelector((state) => state.user);

  const deleted = localStorage.getItem("BANK_DETAILS_DELETED");
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const [bankID, setBankID] = useState(
    USER_INFO?.account?.bank_data?._id ? USER_INFO?.account?.bank_data?._id : ""
  );
  const [banker, setBanker] = useState(
    USER_INFO?.account?.bank_data?.banker
      ? USER_INFO?.account?.bank_data?.banker
      : ""
  );
  const [account_number, setAccountNumber] = useState(
    USER_INFO?.account?.bank_data?.account_number
      ? USER_INFO?.account?.bank_data?.account_number
      : ""
  );
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email, account_number, banker, bankID },
  });

  const submitForm = (data) => {
    dispatch(deleteBank(data));
    console.log(data);
  };

  useEffect(() => {
    if (deleted) {
      const timer = setTimeout(() => {
        localStorage.removeItem("BANK_DETAILS_DELETED");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [deleted]);

  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <i className="sixteen">
            Do you really want to delete withdrawal bank details?
          </i>
          <div className="alert-warning">
            Bank: {banker}
            <br />
            <br />
            Account Number: {account_number}
          </div>
          {error && <div className="alert-warning">{error}</div>}
          {deleted && (
            <i className="sixteen alert-success">
              bank details deleted successfully
            </i>
          )}

          <div className="full-flex">
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />
            <input
              type="hidden"
              name="bankID"
              value={bankID}
              onChange={(e) => setBankID(e.target.value)}
              placeholder="bank details ID is missing"
            />
            <input
              type="hidden"
              placeholder="account number number"
              defaultValue={account_number}
              {...register("account_number", { required: true })}
            />
            {errors.account_number && (
              <span>There is no bank details to be deleted</span>
            )}
          </div>

          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {loading ? (
                <SpinnerCircular size={20} />
              ) : (
                "Yes, Remove Bank Details"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteBank;
