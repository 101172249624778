import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addBankDetails } from "../features/user/userActions";
import { SpinnerCircular } from "spinners-react";

const AddBankDetails = (props) => {
  const [account_name, setAccountName] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [banker, setBanker] = useState("");
  const [routing_number, setRoutingNumber] = useState("");
  const [country, setCountry] = useState("");
  const [swift_code, setSwiftCode] = useState("");

  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const added = localStorage.getItem("BANK_DETAILS_ADDED");

  const { loading, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email } });
  const navigate = useNavigate();

  useEffect(() => {
    const uEmail = localStorage.getItem("USER_EMAIL");
    if (uEmail) {
      setEmail(uEmail);
    }
    if (added) {
      const timer = setTimeout(() => {
        localStorage.removeItem("BANK_DETAILS_ADDED");
        navigate("/my/dashboard");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [navigate, added]);

  const submitForm = (data) => {
    // check input data
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(addBankDetails(data));
    console.log(data);
    setAccountName("");
    setAccountNumber("");
    setBanker("");
    setSwiftCode("");
    setRoutingNumber("");
    setCountry("");
  };
  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <i className="sixteen">Add Bank details for cash withdrawal</i>
          {error && <div className="alert-warning">{error}</div>}
          {added && (
            <i className="sixteen alert-success">
              Withdrawal details added successfully
            </i>
          )}
          <div className="full-flex">
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />

            <input
              type="text"
              placeholder="Enter Account holder's name"
              {...register("account_name", { required: true })}
              onChange={(e) => setAccountName(e.target.value)}
              onFocus={(e) => setAccountName(e.target.value)}
            />
            {errors.account_name && (
              <span>Account holder's name is required</span>
            )}
            <input
              type="text"
              placeholder="Enter Account number"
              {...register("account_number", { required: true })}
              onChange={(e) => setAccountNumber(e.target.value)}
              onFocus={(e) => setAccountNumber(e.target.value)}
            />
            {errors.account_number && <span>Account number is required</span>}
            <input
              type="text"
              placeholder="Enter Bank Name"
              {...register("banker", { required: true })}
              onChange={(e) => setBanker(e.target.value)}
              onFocus={(e) => setBanker(e.target.value)}
            />
            {errors.banker && <span>Bank name is required</span>}
            <input
              type="text"
              placeholder="Enter Routing Number"
              {...register("routing_number", { required: true })}
              onChange={(e) => setRoutingNumber(e.target.value)}
              onFocus={(e) => setRoutingNumber(e.target.value)}
            />
            {errors.rounting_number && <span>Routing Number is required</span>}
            <input
              type="text"
              placeholder="Enter Swift Code"
              {...register("swift_code", { required: true })}
              onChange={(e) => setSwiftCode(e.target.value)}
              onFocus={(e) => setSwiftCode(e.target.value)}
            />
            {errors.swift_code && <span>Swift Code is required</span>}

            <input
              type="text"
              placeholder="Enter Bank Country"
              {...register("country", { required: false })}
              onChange={(e) => setCountry(e.target.value)}
              onFocus={(e) => setCountry(e.target.value)}
            />
            {errors.country && <span>Bank Country is required</span>}
          </div>

          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {loading ? <SpinnerCircular size={20} /> : " Add Bank Details"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddBankDetails;
