import React, { useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { revealIntraTransactionBalances } from "../features/wallet/walletActions";
import { SpinnerCircular } from "spinners-react";

const RefreshIntraBalances = (props) => {
  const [reveal, setReveal] = useState(true);
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const { wLoading } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const submitForm = (data) => {
    dispatch(revealIntraTransactionBalances(data));
    setReveal(false);
  };

  return (
    <div id="CardForm2">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="full-flex">
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />
          </div>
          <div className="btn-container">
            {reveal ? (
              <button type="submit" className="login">
                Reload Balances
              </button>
            ) : (
              <button type="submit" className="login">
                {wLoading ? <SpinnerCircular size={20} /> : "Reload Balances"}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RefreshIntraBalances;
