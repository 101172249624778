import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "../api/axios";
import { logout } from "../features/user/userSlice";

// form styles
import "../styles/forms.css";
const DeleteAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [deleted, setDeleted] = useState(false);
  const [wait, setWait] = useState(false);
  const { error, USER_INFO } = useSelector((state) => state.user);
  const [bearerToken] = useState(
    USER_INFO.USER_TOKEN ? `Bearer ${USER_INFO?.USER_TOKEN}` : false
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      const deleteUserAccount = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: bearerToken,
            },
          };
          await axios.get(`account/delete/owner/${params.id}`, config);
          setWait(true);
          setDeleted(true);
          localStorage.removeItem("ACCOUNT_DELETION_INIT");
          localStorage.removeItem("IS_LOGIN_ZPAY");
          localStorage.clear();
          dispatch(logout());
          //return data;
        } catch (error) {
          console.log(error);
          setDeleted(false);
        }
      };
      deleteUserAccount();
    }, 5000);
    return () => clearTimeout(timer);
  }, [params.id, bearerToken, navigate, dispatch]);

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZPAY-ICON" />
        </div>
        <br />
        <br />
        {error && <h3 className="sixteen alert-warning">{error}</h3>}
        {wait && (
          <div className="sixteen alert-success">
            Almost done, account deletion in progress...
          </div>
        )}
        {deleted && (
          <h3 className="sixteen alert-success">
            Your account has been deleted successfully!
          </h3>
        )}
      </div>
    </section>
  );
};

export default DeleteAccount;
