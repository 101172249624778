import React, { useEffect, useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editAddress } from "../features/user/userActions";
import { SpinnerCircular } from "spinners-react";

const EditAddressConfirm = (props) => {
  const [addID, setAddID] = useState(
    localStorage.getItem("ADDRESS_ID") ? localStorage.getItem("ADDRESS_ID") : ""
  );
  const [street, setStreet] = useState(
    localStorage.getItem("ADDRESS_ID")
      ? localStorage.getItem("ADDRESS_STREET")
      : ""
  );
  const [city, setCity] = useState(
    localStorage.getItem("ADDRESS_CITY")
      ? localStorage.getItem("ADDRESS_CITY")
      : ""
  );
  const [postal_code, setPostalCode] = useState(
    localStorage.getItem("ADDRESS_POSTAL_CODE")
      ? localStorage.getItem("ADDRESS_POSTAL_CODE")
      : ""
  );
  const [country, setCountry] = useState(
    localStorage.getItem("ADDRESS_COUNTRY")
      ? localStorage.getItem("ADDRESS_COUNTRY")
      : ""
  );
  const edited = localStorage.getItem("USER_ADDRESS_EDITED");

  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const [reveal, setReveal] = useState(false);
  const { error, loading, USER_ADDRESS_EDITED } = useSelector(
    (state) => state.user
  );

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email, street, city, postal_code, country, addID },
  });

  const submitForm = (data) => {
    dispatch(editAddress(data));
    //setReveal(false);
    localStorage.removeItem("ADDRESS_ID");
    localStorage.removeItem("ADDRESS_STREET");
    localStorage.removeItem("ADDRESS_CITY");
    localStorage.removeItem("ADDRESS_POSTAL_CODE");
    localStorage.removeItem("ADDRESS_COUNTRY");
    localStorage.removeItem("USER_ADDRESS_EDITED");

    console.log(data);
  };

  useEffect(() => {
    if (edited) {
      const timer = setTimeout(() => {
        localStorage.removeItem("USER_ADDRESS_EDITED");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [edited]);

  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <i className="sixteen">Edit/Update Addess</i>
          {error && <div className="alert-warning">{error}</div>}
          {edited && (
            <i className="alert-success">Address edited successfully</i>
          )}

          <div className="full-flex">
            <input
              type="hidden"
              placeholder="addID"
              defaultValue={addID}
              {...register("addID", { required: true })}
            />
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />

            <input
              type="text"
              placeholder="Street number and address"
              defaultValue={street}
              {...register("street", { required: true })}
            />
            <input
              type="text"
              placeholder="City"
              defaultValue={city}
              {...register("city", { required: true })}
            />
            <input
              type="text"
              placeholder="Postal/Zip Code"
              defaultValue={postal_code}
              {...register("postal_code", { required: true })}
            />
            <input
              type="text"
              placeholder="Country"
              defaultValue={country}
              {...register("country", { required: true })}
            />
          </div>

          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {loading ? <SpinnerCircular size={20} /> : " Edit Address"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAddressConfirm;
