import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios, { fURL } from "../../api/axios";
import { Document, Page } from "react-pdf";

const Pdfviewer = () => {
  const [data, setData] = useState(false);
  const [view, setView] = useState(false);
  const { USER_INFO } = useSelector((state) => state.user);

  const params = useParams();
  console.log(params)

  const pdfURL = "";
  return (
    <section className="wallet">
      <div className="container">
        <Document file={pdfURL}>
          <Page pageNumber={1} />
        </Document>
      </div>
    </section>
  );
};

export default Pdfviewer;
