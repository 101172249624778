import React, { useEffect, useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { deleteAccountByOwner } from "../features/user/userActions";
import { RiAlertFill } from "react-icons/ri";
const DeleteAccountInit = () => {
  const { error, loading, USER_INFO, DELETE_ACCOUNT_BY_OWNER } = useSelector(
    (state) => state.user
  );
  const deletion_init = localStorage.getItem("ACCOUNT_DELETION_INIT");
  const [email] = useState(USER_INFO.account ? USER_INFO?.account?.email : "");
  const [id] = useState(USER_INFO.account ? USER_INFO?.account?._id : "");
  const [bearerToken] = useState(
    USER_INFO.USER_TOKEN ? `Bearer ${USER_INFO?.USER_TOKEN}` : false
  );
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm({
    defaultValues: { id },
  });

  const navigate = useNavigate();
  const submitForm = (data) => {
    dispatch(deleteAccountByOwner(data));
  };

  useEffect(() => {
    if (deletion_init) {
      const timer = setTimeout(() => {
        localStorage.removeItem("ACCOUNT_DELETION_INIT");
        navigate("/my/dashboard");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [deletion_init, navigate]);

  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="sixteen alert-warning">
            <h2 className="flex-between">
              <RiAlertFill /> Account Deletion Waring!
            </h2>
            <hr />
            You are about to delete your zurepay account associated with this
            email address below <br /> <br /> <b>{email}</b>
            <br />
            <br />
            <b>Note: </b>Account Deletion is final, once deleted, you will not
            be able to recover it.
          </div>
          {error && <div className="alert-warning">{error}</div>}

          {DELETE_ACCOUNT_BY_OWNER && deletion_init && (
            <i className="sixteen alert-success">
              {DELETE_ACCOUNT_BY_OWNER.message}
            </i>
          )}

          <div className="full-flex">
            <input
              type="text"
              placeholder="Bearer Token"
              defaultValue={bearerToken}
              {...register("bearerToken", { required: true })}
            />
            <input
              type="hidden"
              placeholder="ID"
              defaultValue={id}
              {...register("id", { required: true })}
            />
          </div>

          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {loading && DELETE_ACCOUNT_BY_OWNER?.status ? (
                <SpinnerCircular size={20} />
              ) : (
                "Delete My Account"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteAccountInit;
