import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendFund } from "../features/wallet/walletActions";
import FundWallet from "../screens/FundWallet";
import axios from "../api/axios";
import { toast } from "react-toastify";

import Popup from "../dashboard/Popup";
// form styles
import "../styles/forms.css";
const PaymeScreen = () => {
  const params = useParams();
  const [paid, setPaid] = useState(false);
  const [transaction_currency, setCurrency] = useState("");
  const [transaction_amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [isFunding, setIsFunding] = useState(false);
  const [email, setEmail] = useState(
    localStorage.getItem("PAY_ME_LINK_EMAIL_INIT")
      ? localStorage.getItem("PAY_ME_LINK_EMAIL")
      : ""
  );
  const [funded_by] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );

  const [payMeLink] = useState(params.id);
  const pay_me_link_email_init = localStorage.getItem("PAY_ME_LINK_EMAIL_INIT");
  const funded = localStorage.getItem("FUND_STATUS");
  const notfunded = localStorage.getItem("FUND_ERROR_STATUS");

  const { loading, IS_LOGIN_ZPAY } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email, funded_by } });
  const navigate = useNavigate();
  const toggleIsFunding = () => {
    setIsFunding(!isFunding);
  };

  useEffect(() => {
    if (!pay_me_link_email_init) {
      axios
        .get(`payme/${payMeLink}`)
        .then((response) => {
          localStorage.setItem("PAY_ME_LINK_EMAIL_INIT", true);
          localStorage.setItem("PAY_ME_LINK", payMeLink);
          localStorage.setItem(
            "PAY_ME_LINK_EMAIL",
            response?.data?.payme_details?.email
          );
          localStorage.setItem(
            "PAY_ME_NAME",
            response.data?.payme_details?.merchant_name
              ? response.data?.payme_details?.merchant_name
              : response.data?.payme_details?.bio_data?.firstname
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [payMeLink, pay_me_link_email_init]);

  useEffect(() => {
    if (!IS_LOGIN_ZPAY && !pay_me_link_email_init) {
      toast.success("You need to log in first");
      const timer = setTimeout(() => {
        navigate("/login");
      }, 3000);
      return () => clearTimeout(timer);
    } else if (!IS_LOGIN_ZPAY && pay_me_link_email_init) {
      toast.success("You need to log in first");
      const timer = setTimeout(() => {
        navigate("/login");
      }, 3000);
      return () => clearTimeout(timer);
    } else if (IS_LOGIN_ZPAY && pay_me_link_email_init) {
      const timer = setTimeout(() => {}, 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, IS_LOGIN_ZPAY, payMeLink, pay_me_link_email_init]);

  useEffect(() => {
    if (funded) {
      const timer = setTimeout(() => {
        setPaid(true);
        localStorage.removeItem("PAY_ME_LINK");
        localStorage.removeItem("PAY_ME_LINK_EMAIL_INIT");
        localStorage.removeItem("PAY_ME_LINK_EMAIL");
        localStorage.removeItem("PAY_ME_NAME");
        localStorage.removeItem("FUND_ERROR_STATUS");
        localStorage.removeItem("FUND_STATUS");
        navigate("/payments/sent");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [funded, navigate, paid]);

  const submitForm = (data) => {
    // check input data
    dispatch(sendFund(data));
    setEmail("");
    setCurrency("");
    setAmount("");
    setDescription("");
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZurePayICON" />
        </div>
        <p className="divider">
          <span>Pay Me Link</span>
        </p>

        <i className="sixteen alert-warning">
          You are making payment to
          <br />
          <br />
          <b> {localStorage.getItem("PAY_ME_NAME")}</b>
        </i>
        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {notfunded && error && (
            <div className="sixteen alert-warning">
              Unable to send fund {error}
            </div>
          )}
          {funded && paid && (
            <div className="alert-success">Fund sent successfully</div>
          )}

          <input
            type="hidden"
            defaultValue={payMeLink}
            {...register("payMeLink")}
            required
          />

          <input
            type="hidden"
            placeholder="Enter Recipient's Email"
            {...register("email", { required: true })}
          />

          <input
            type="hidden"
            placeholder="funder email"
            defaultValue={funded_by}
            {...register("funded_by", { required: true })}
          />
          <div className="half-flex">
            <select {...register("transaction_currency", { required: true })}>
              <option value="">Select currency to send</option>
              <option value="gbp">Pound</option>
              <option value="eur">Euro</option>
              <option value="usd">US dollar</option>
              <option value="ngn">Nigeria Naira</option>
              <option value="zar">South Africa Rand</option>
            </select>
            <input
              type="text"
              placeholder="Amount"
              {...register("transaction_amount", { required: true })}
              onChange={(e) => setAmount(e.target.value)}
              onFocus={(e) => setAmount(e.target.value)}
            />
          </div>
          {errors.transaction_currency && (
            <i className="sixteen alert-warning">Currency is required</i>
          )}
          {errors.transaction_amount && (
            <i className="sixteen alert-warning">Amount is required</i>
          )}
          <input
            type="text"
            placeholder="Description"
            {...register("description", { required: false })}
            onChange={(e) => setDescription(e.target.value)}
            onFocus={(e) => setDescription(e.target.value)}
          />
          {errors.description && (
            <i className="sixteen alert-warning">
              Please enter a short description
            </i>
          )}
          <button type="submit" className="r-btn" disabled={loading}>
            {loading ? "Processing..." : "Pay Now"}
          </button>
          <Link to="/my/dashboard" className="mt-10">
            Don't want to make this payment right now?
          </Link>
          <button className="signup" onClick={toggleIsFunding}>
            Fund Your Wallet
          </button>
        </form>
      </div>
      {isFunding && (
        <Popup content={<FundWallet />} handleClose={toggleIsFunding} />
      )}
    </section>
  );
};

export default PaymeScreen;
