import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Illustrator from "./Illustrator";
import CurrencyConverter from "../converter/CurrencyConverter";

const Hero = () => {
  const [email, setEmail] = useState("");
  const [loading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  //validate email
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const persistEmail = () => {
    if (!email || !isValidEmail(email)) {
      return setError(true);
    } else {
      localStorage.setItem("USER_EMAIL", email);
      navigate("/signup");
    }
  };

  return (
    <>
      {/* Hero section */}
      <div className="hero-container">
        <div className="left">
          <h1>Unlock the power of online payments with ZurePay</h1>
          <p className="parag">
            Get your business started with a secure and efficient way to process
            payments with ZurePay.
          </p>
          <div className="get-started-form">
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email to get started"
            />
            <button type="submit" onClick={persistEmail} disabled={loading}>
              {loading ? "Please Wait..." : "Get Started"}
            </button>
          </div>
          <div>
            {error && !isValidEmail(email) && (
              <span className="alert-warning">
                Please enter a valid email address to get started
              </span>
            )}
          </div>
        </div>
        <div className="right">
          <CurrencyConverter />
        </div>
      </div>

      <Illustrator />
    </>
  );
};

export default Hero;
