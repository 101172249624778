import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userMessage } from "../features/user/userActions";
// form styles
import "../styles/forms.css";
const ContactScreen = () => {
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const MSG_SUCCESS = localStorage.getItem("MSG_SUCCESS");
  const { loading, error, message } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: email });

  const navigate = useNavigate();

  useEffect(() => {
    if (MSG_SUCCESS) {
      const timer = setTimeout(() => navigate("/"), 3000);
      localStorage.removeItem("MSG_SUCCESS");
      return () => clearTimeout(timer);
    }
  }, [navigate, MSG_SUCCESS]);
  const submitForm = (data) => {
    dispatch(userMessage(data));
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZurePayICON" />
        </div>
        <p className="divider">
          <span>Send us a message</span>
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          <div className="flashResponse">
            {error && (
                <h3 className="alert-warning">{error}</h3>
             
            )}
            {MSG_SUCCESS && (
                <h3 className="alert-success"> Message sent!</h3>
             
            )}
          </div>
          <label htmlFor="email" style={{ display: "none" }}>
            Your email
          </label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Enter Email"
            defaultValue={email}
            {...register("email", { required: true })}
          />
          {errors.email ? <span>Please enter feedback email</span> : ""}
          <label htmlFor="phone" style={{ display: "none" }}>
            Your phone
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Enter Phone (Optional)"
            {...register("phone")}
          />
          {errors.phone && <span>Please enter phone</span>}
          <label htmlFor="message" style={{ display: "none" }}>
            Your phone
          </label>
          <textarea
            type="message"
            id="message"
            name="message"
            placeholder="Enter your Message"
            {...register("message", { required: true })}
          ></textarea>
          {errors.message && <span>Please Enter your message</span>}

          <button type="submit" className="r-btn" disabled={loading}>
            {loading ? "Sending..." : "Send"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactScreen;
