import React from "react";
import Marquee from "react-fast-marquee";
const Trustedby = () => {
  return (

    <div className="trust-container">
      <div className="trust">
        <h1>Trusted By</h1>
        <div className="trust-card-container">
          <Marquee>
            <div className="trust-card"> <img src="/images/tr1.png" alt="tr1" className="trust-fit" /></div>
            <div className="trust-card"> <img src="/images/tr2.png" alt="tr2" className="trust-fit" /></div>
            <div className="trust-card"> <img src="/images/tr3.png" alt="tr3" className="trust-fit" /></div>
            <div className="trust-card"> <img src="/images/tr4.png" alt="tr4" className="trust-fit" /></div>
            <div className="trust-card"> <img src="/images/tr5.png" alt="tr5" className="trust-fit" /></div>
            <div className="trust-card"> <img src="/images/tr6.png" alt="tr6" className="trust-fit" /></div>
          </Marquee>
        </div>

      </div>
    </div>

  );
};

export default Trustedby;
