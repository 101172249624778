import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { compareDesc } from "date-fns";
import axios from "../../api/axios";
import { FaCheckCircle } from "react-icons/fa";
import { moneyFormatter } from "../../utils/moneyFormatter";

const PendingWithdrawals = () => {
  const columns = [
    {
      name: "CREATED DATE",
      selector: (row) => dateFormat(row.transaction_date, " mmmm dS, yyyy"),
    },

    {
      name: "EMAIL ADDRESS",
      selector: (row) => row.email,
    },

    {
      name: "AMOUNT",
      selector: (row) =>
        moneyFormatter(row?.transaction_amount,row?.transaction_currency),
    },

    {
      name: "STATUS",
      selector: (row) =>
        row?.transaction_status === "FULFILLED" ? (
          <span className="txt-green">
            <FaCheckCircle />
          </span>
        ) : (
          <span className="txt-red">&#10060;</span>
        ),
    },
    {
      name: "PoID",
      selector: (row) => row?.__v,
    },
    {
      name: "Button",
      selector: (row) => (
        <Link className="link" to={`/withdrawal/pending/${row._id}`}>
          View
        </Link>
      ),
    },
  ];

  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const { USER_INFO } = useSelector((state) => state.user);

  useEffect(() => {
    axios
      .get("withdrawal/pending")
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        throw new Error(error, " Error encountered while fetching data");
      });
  }, [USER_INFO.account._id]);
  if (!data) return null;
  data?.pending_withdrawals?.sort(function (a, b) {
    return compareDesc(a.transaction_date, b.transaction_date);
  });

  return (
    <section className="dashboard light-gray-bg">
      <div className="table-container">
        {loading ? (
          "LOADING..."
        ) : (
          <DataTable columns={columns} data={data.pending_withdrawals} />
        )}
      </div>
    </section>
  );
};

export default PendingWithdrawals;
