const HowItWorks = () => {
  return (
    <div className="pages-container">
      <h1> Who Uses ZurePay?</h1>
      <p className="parag">
        Everyone. ZurePay target over 200 million users globally, with{" "}
        <u>35 million merchant accounts.</u> It’s been established. ZurePay is
        used by virtually every type of corporation and consumer. It has a low
        barrier of entry, unlike a bank account, so anyone can immediately sign
        up and start using the service. <br />
        <br />
        <h3>How Does ZurePay Work?</h3> ZurePay works as an intermediary between
        you and a bank. A user adds a bank account or credit/debit card to the
        ZurePay system, and whenever they’re making an online payment, they can
        select which account to debit.
        <br />
        <br /> All transactions are processed through ZurePay, instead of your
        bank. Any monies received will sit in your ZurePay account and can be
        used for e-commerce, point of sale (with a ZurePay card), or transferred
        to your bank account. Transfers can take a few days or minutes (
        <u>for an extra fee</u>). Your ZurePay account balance can also be
        topped off with a bank account or assigned card. For individuals,
        ZurePay offers simple
        <u>money transfers</u>, debit/credit cards, check cashing, and e-check
        services. <br />
        <br />
        For merchants, ZurePay has a variety of services at competitive rates,
        without long-term contracts. This includes features like ZurePay
        Shipping, Invoicing, Working Capital, and even ZurePay Loans. <br />
        <br />
        Setting up a ZurePay account is free, but there are various charges for
        using the service. It’s a slightly different business model if you’re an
        individual sending money to friends vs. a business facilitating
        payments. Fully expect ZurePay to send tax forms if you’re invoicing and
        accepting payments for services/goods rendered. ZurePay can be used via
        websites, mobile apps, programmatic interfaces, or integrations. <br />
        <br />
        It provides tools and services to support POS (point of sale),
        e-commerce, and subscription-based transactions. It safeguards users by
        offering seller protection, purchase resolution, and the best digital
        security in the industry. To sum it up, ZurePay:
        <li>• Enables online and in-person payments for goods and services</li>
        <li>• Provides a way to send and receive money internationally</li>
        <li>
          • Ensures various levels of digital security and payment protection
        </li>
        <li>• Charges a variable and/or fixed fee for services rendered</li>
        <br />
        ZurePay can also be set as a default payment method to be used online in
        place of a credit or debit card. This is what made it such a popular
        form of payment on eBay. <h3>Set Up ZurePay in 4 Simple Steps</h3>{" "}
        Signing up for ZurePay and getting started is not a huge feat. <br />
        <br />
        It’s actually quite simple, you just need to have the right information
        prepared. Here are the quickest steps to getting up and running in the
        digital payment space. <br />
        <br />
        <b>Step #1)</b> Create a Free Account Go to ZurePay.com and click the
        “Register” button in the upper-right hand corner. <br />
        <br />
        <b>Step #2)</b> Provide your email and password (It should be memorable
        and contain letters, numbers, and characters to ensure your account is
        protected from hacking or phishing). <br />
        <br />
        <b>Step #2B)</b> Sign into your email to confirm. <br />
        <br />
        <b>Step #3)</b> Provide Details When signing up for a ZurePay account,
        make sure you have all your data in order. Be prepared with the
        following:
        <li>• Name</li>
        <li>• Date of birth</li>
        <li>• Address</li>
        <li>• Phone number</li>
        <li>• National ID or Passport Number</li>
        <li>• Email Address</li>
        <li>• Bank account number</li>
        <li>• Bank routing number</li>
        <li>• Debit and/or credit card data</li>
        <br />
        If you open a personal account and need to do business, it can be
        upgraded at a later date. After you input all data, be sure to check to
        box to accept ZurePay’s “Terms and Conditions.” <br />
        <br />
        <b>Step #4)</b> Link Your Bank Card To link a bank account, look for the
        wallet icon on the top bar. This brings you to an overview of your
        current ZurePay balance, as well as any linked credit or debit cards.
        Click on “Link a Bank Account” to start the process. A user can then add
        their bank account details via bank verification or wire transfer. The
        following are details you may need:
        <li>• Bank name</li>
        <li>• Bank code</li>
        <li>• Branch location</li>
        <li>• Branch code</li>
        <li>• Bank account number</li>
        <br />
        <b>Step #5)</b> Verify all Data Certain mechanisms are in place to make
        sure you are a legitimate user. Subsequently, you will need to verify
        the email address that’s in the new account. Check your inbox for the
        ZurePay verification email. Click on “Confirm Email Address” to verify
        the information on your network. That’s all that’s needed to have your
        ZurePay set up and ready to go. <br />
        <br />
        If you connect a mobile number, ZurePay may also request you verify that
        information with a one-time passcode via text. Enter that to verify your
        cell data and make sure you have the notifications set to “on” to manage
        transactions in real-time. <h3>
          How to Make Payments with ZurePay
        </h3>{" "}
        It’s simple to make payments through ZurePay if the recipient has a
        ZurePay account. All you have to do is click on “send” and enter the
        seller’s email address. <br />
        <br />
        Then enter the amount and click “send.” All transactions can be tracked
        on your account activity page. Everything you send is protected by
        advanced encryption with 24/7 fraud monitoring.{" "}
        <h3>How to Receive Payments with ZurePay</h3> Receiving payments through
        ZurePay is super easy. You can either give out the email address of your
        account, or log into your ZurePay account and click the “request” button
        on the right-hand side. Enter the sender’s email and click “next.” Enter
        the amount, currency type, and a note (optional). Then, click “request
        now.” That’s it! You can also request money with ZurePay Invoicing.{" "}
        <br />
        <br />
        It’s a free service that enables a user to quickly create and send
        customized invoices via email or a shared link. You can even break up
        the invoice into partial payments or request a tip for services
        rendered. From the dashboard, view the billing history to track payments
        and send reminders on past-due invoices. <br />
        <br />
        <b>Withdrawing Payments to Your Bank Account</b> To withdraw funds from
        your ZurePay balance to your bank account, log into your ZurePay account
        then follow these steps:
        <li>
          1. On the summary page, click the “Transfer Money” button right below
          your ZurePay balance. Users can also access this option via the
          “Wallet” menu in the top toolbar.
        </li>
        <li>2. Click “Transfer to your Bank”</li>
        <li>
          3. If you have multiple cards or bank accounts attached to your
          ZurePay account, you will then be prompted to choose which one.
        </li>
        <li>
          4. Click “Next” and enter the amount you want to transfer. “Click
          “Next” again.
        </li>
        <li>
          5. On the final page, review the transfer amount and click “Transfer
          Now” to finalize the transaction.
        </li>
      </p>
    </div>
  );
};

export default HowItWorks;
