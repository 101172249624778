import "./dashboard/Dashboard.css";
import "./dashboard/Settings.css";
import "./pages/pages.css";
import "./index.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hero from "./components/Hero";
// import Signup from "./components/Signup";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Reach from "./components/Reach";
//screens
import RegisterScreen from "./screens/RegisterScreen";
import ContactScreen from "./screens/ContactScreen";
import LoginScreen from "./screens/LoginScreen";
import LoginAuthScreen from "./screens/LoginAuthScreen";
import RefreshLoginScreen from "./screens/RefreshLoginScreen";
import PasswordResetLinkScreen from "./screens/PasswordResetLinkScreen";
import RefreshPasswordResetLinkScreen from "./screens/RefreshPasswordResetLinkScreen";
import PasswordResetScreen from "./screens/PasswordResetScreen";
import VerifyEmail from "./screens/VerifyEmailScreen";
import ProfileScreen from "./screens/ProfileScreen";
//Card Entry form
import AddCard from "./screens/AddCard";
//Dashboard
import Navigationbar from "./dashboard/Navigationbar";
import Dashboard from "./dashboard/Dashboard";
import Settings from "./dashboard/Settings";
import Notifications from "./dashboard/Notifications";
import Fundings from "./dashboard/Fundings";
import FundTransactions from "./dashboard/FundTransactions";
import Wallet from "./dashboard/Wallet";
//pages
import About from "./pages/About";
import Fees from "./pages/Fees";
import HowItWorks from "./pages/HowItWorks";
import BuyersProtection from "./pages/BuyersProtection";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import PayoutAgreement from "./pages/PayoutAgreement";
import UsersAgreement from "./pages/UsersAgreement";
//Toastify
import Toastify from "./utils/Toastify";
//import useSelector
import { useSelector } from "react-redux";
import PrivateRoutes from "./components/PrivateRoutes";
import Payment from "./stripe/Payment";
import FundWallet from "./screens/FundWallet";
import Webhook from "./stripe/Webhook";
import KYCResidenceScreen from "./screens/KYCResidenceScreen";
import KYCIdentityScreen from "./screens/KYCIdentityScreen";
import Withdrawals from "./dashboard/Withdrawals";
import Activities from "./screens/Activities";
import TransactionsReceived from "./screens/TransactionsReceived";
import TransactionsSent from "./screens/TransactionsSent";
import Accounts from "./dashboard/Admin/Accounts";
import AccountOne from "./dashboard/Admin/AccountOne";
import InitiatedKYC from "./dashboard/Admin/InitiatedKYC";
import KYCOne from "./dashboard/Admin/KYCOne";
import PendingWithdrawals from "./dashboard/Admin/PendingWithdrawals";
import PendingWithdrawalOne from "./dashboard/Admin/PendingWithdrawalOne";
import Fundrequests from "./dashboard/Fundrequests";
import Pdfviewer from "./dashboard/Admin/PdfViewer";
import AdminDashboard from "./dashboard/AdminDashboard";
import Features from "./components/Features";
import Trustedby from "./components/Trustedby";
import Testimonial from "./components/Testimonial";
import PaymeScreen from "./screens/PaymeScreen";
import CurrencySwap from "./dashboard/CurrencySwapHistory";
import DeleteAccount from "./screens/DeleteAccount";
import DeleteAccountAdmin from "./screens/DeleteAccountAdmin";
import SuspendAccount from "./screens/SuspendAccount";
import UnSuspendAccount from "./screens/UnSuspendAccount";
import ApproveIdentity from "./screens/ApproveIdentity";
import ApproveResidence from "./screens/ApproveResidence";
function App() {
  const [loader, setLoader] = useState(true);
  const { USER_INFO, IS_LOGIN_ZPAY } = useSelector((state) => state.user);
  useEffect(() => {
    setTimeout(() => setLoader(false), 5000);
  }, []);

  return (
    <div className="app">
      <div className="container">
        {loader === false ? (
          <Router>
            <ToastContainer />
            {IS_LOGIN_ZPAY ? <Navigationbar /> : <Nav />}
            <Routes>
              <Route
                path="/"
                element={IS_LOGIN_ZPAY ? <Dashboard /> : <Hero />}
              />

              <Route path="/signup" element={<RegisterScreen />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/auth/login" element={<LoginAuthScreen />} />
              <Route path="/refresh/login" element={<RefreshLoginScreen />} />

              <Route
                path="/email/verify/:accountID/:zotaalCODE/:email"
                element={<VerifyEmail />}
              />
              <Route
                path="/passwordreset/link"
                element={<PasswordResetLinkScreen />}
              />
              <Route
                path="/refresh/link"
                element={<RefreshPasswordResetLinkScreen />}
              />
              <Route path="/reset" element={<PasswordResetScreen />} />

              <Route
                path="/password/reset/:accountID/:passResetCODE"
                element={<PasswordResetScreen />}
              />
              <Route path="/payme/:id" element={<PaymeScreen />} />
              <Route path="/my/profile" element={<ProfileScreen />} />
              <Route path="/kyc/residence" element={<KYCResidenceScreen />} />
              <Route path="/kyc/identity" element={<KYCIdentityScreen />} />
              <Route path="/toast" element={<Toastify />} />
              {/* start protected routes */}
              <Route element={<PrivateRoutes />}>
                {/* card entry form */}
                <Route path="/my/addcard" element={<AddCard />} />
                {/* Dashboard */}
                {USER_INFO?.account?.account_type === "Admin" ? (
                  <Route path="/my/dashboard" element={<AdminDashboard />} />
                ) : (
                  <Route path="/my/dashboard" element={<Dashboard />} />
                )}

                <Route path="/my/settings" element={<Settings />} />
                <Route path="/my/notifications" element={<Notifications />} />
                <Route path="/my/wallet" element={<Wallet />} />
                <Route
                  path="/funding/transactions"
                  element={<FundTransactions />}
                />
                <Route
                  path="/payments/received"
                  element={<TransactionsReceived />}
                />
                {/* Admin Dashboard */}
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/account/view/:id" element={<AccountOne />} />
                <Route
                  path="/account/delete/:id"
                  element={<DeleteAccountAdmin />}
                />
                <Route
                  path="/account/suspend/:id"
                  element={<SuspendAccount />}
                />
                  <Route
                  path="/account/unsuspend/:id"
                  element={<UnSuspendAccount />}
                />
                <Route
                  path="/identity/approve/:id"
                  element={<ApproveIdentity />}
                />
                <Route
                  path="/residence/approve/:id"
                  element={<ApproveResidence />}
                />
              
                <Route path="/kyc/initiated" element={<InitiatedKYC />} />
                <Route path="/kyc/initiated/:id" element={<KYCOne />} />
                <Route path="/uploads/kyc_docs/:id" element={<KYCOne />} />
                <Route path="/pdf/view" element={<Pdfviewer />} />

                <Route
                  path="/withdrawal/pending"
                  element={<PendingWithdrawals />}
                />
                <Route
                  path="/withdrawal/pending/:id"
                  element={<PendingWithdrawalOne />}
                />

                <Route path="/payments/sent" element={<TransactionsSent />} />
                <Route path="/my/swaps" element={<CurrencySwap />} />
                <Route path="/my/fundings" element={<Fundings />} />
                <Route path="/fundform" element={<FundWallet />} />
                <Route path="/funding" element={<Payment />} />
                <Route path="/webhook" element={<Webhook />} />

                <Route path="/my/withdrawals" element={<Withdrawals />} />
                <Route path="/my/fundrequests" element={<Fundrequests />} />
                <Route path="/my/funding/activities" element={<Activities />} />
                <Route
                  path="/account/delete/owner/:id"
                  element={<DeleteAccount />}
                />
              </Route>
              {/* end protected routes */}

              {/* pages */}
              <Route path="/pages/about" element={<About />} />
              <Route path="/pages/howitworks" element={<HowItWorks />} />
              <Route path="/pages/fees" element={<Fees />} />
              <Route path="/pages/contact" element={<ContactScreen />} />
              <Route path="/pages/legal/policy" element={<PrivacyPolicy />} />
              <Route
                path="/pages/legal/protection"
                element={<BuyersProtection />}
              />
              <Route path="/pages/legal/refund" element={<RefundPolicy />} />
              <Route path="/pages/legal/payout" element={<PayoutAgreement />} />
              <Route
                path="/pages/legal/agreement"
                element={<UsersAgreement />}
              />
            </Routes>
            {IS_LOGIN_ZPAY ? "" : <Reach />}
            {IS_LOGIN_ZPAY ? "" : <Trustedby />}
            {IS_LOGIN_ZPAY ? "" : <Features />}
            {IS_LOGIN_ZPAY ? "" : <Testimonial />}
            <Footer />
          </Router>
        ) : (
          <div className="loader">
            <SpinnerCircular
              size={20}
              thickness={200}
              speed={50}
              color="#fd7e14"
            />
            <h3 className="loader-text-color">Loading...</h3>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
