import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { passwordReset } from "../features/user/userActions";

// form styles
import "../styles/forms.css";
const PassScreen = () => {
  const params = useParams();
  const [customError, setCustomError] = useState(null);
  const [email, setEmail] = useState("");
  const [accountID, setAccountID] = useState(params.accountID);

  const [passResetCODE, setpassResetCODE] = useState(params.passResetCODE);

  const { loading, error, PASS_RESET, PASS_RESET_LINK, LOGIN_SUCCESS } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (PASS_RESET)
      setTimeout(() => {
        navigate("/login");
      }, 5000);
  }, [navigate, PASS_RESET]);

  useEffect(() => {
    // redirect authenticated user to profile screen
    if (LOGIN_SUCCESS)
      setTimeout(() => {
        navigate("/");
      }, 3000);
  }, [navigate, LOGIN_SUCCESS]);

  const submitForm = (data) => {
    // check if passwords match
    if (data.password !== data.confirmPassword) {
      setCustomError("Password mismatch");
      return;
    }
    dispatch(passwordReset(data));
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZurePayICON" />
        </div>
        <p className="divider">
          <span>Reset Password Below</span>
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {error && <h3 className="alert-warning">{error}</h3>}

          {!email && (
            <h3 className="alert-warning">Please provide your email</h3>
          )}

          {PASS_RESET && (
            <h3 className="alert-success">Your Password has been reset</h3>
          )}
          <input
            type="hidden"
            defaultValue={accountID}
            {...register("accountID")}
            required
          />
          <input
            type="hidden"
            defaultValue={passResetCODE}
            {...register("passResetCODE")}
            required
          />

          <input
            type="password"
            placeholder="Enter Password"
            {...register("password", { required: true })}
          />

          {errors.password && <span>This field is required</span>}
          <input
            type="password"
            placeholder="Confirm Password"
            {...register("confirmPassword", { required: true })}
          />
          {errors.confirmPassword && <span>This field is required</span>}

          <button type="submit" className="r-btn" disabled={loading}>
            {loading ? "Processing..." : "Reset Now"}
          </button>
          <Link to="/login" className="mt-10">
            Remember Password?
          </Link>
        </form>
      </div>
    </section>
  );
};

export default PassScreen;
