import React, { useEffect, useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { removeAddress } from "../features/user/userActions";
import { SpinnerCircular } from "spinners-react";

const RemoveAddressConfirm = (props) => {
  const [addID, setAddID] = useState(
    localStorage.getItem("ADDRESS_ID") ? localStorage.getItem("ADDRESS_ID") : ""
  );
  const [street, setStreet] = useState(
    localStorage.getItem("ADDRESS_STREET")
      ? localStorage.getItem("ADDRESS_STREET")
      : ""
  );
  const [city, setCity] = useState(
    localStorage.getItem("ADDRESS_CITY")
      ? localStorage.getItem("ADDRESS_CITY")
      : ""
  );
  const [postal_code, setPostalCode] = useState(
    localStorage.getItem("ADDRESS_POSTAL_CODE")
      ? localStorage.getItem("ADDRESS_POSTAL_CODE")
      : ""
  );
  const [country, setCountry] = useState(
    localStorage.getItem("ADDRESS_COUNTRY")
      ? localStorage.getItem("ADDRESS_COUNTRY")
      : ""
  );

  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const [reveal, setReveal] = useState(false);
  const removed = localStorage.getItem("USER_ADDRESS_REMOVED");
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email, addID } });

  useEffect(() => {
    if (removed) {
      const timer = setTimeout(() => {
        localStorage.removeItem("USER_ADDRESS_REMOVED");
        localStorage.removeItem("ADDRESS_ID");
        localStorage.removeItem("ADDRESS_STREET");
        localStorage.removeItem("ADDRESS_CITY");
        localStorage.removeItem("ADDRESS_POSTAL_CODE");
        localStorage.removeItem("ADDRESS_COUNTRY");
        return () => clearTimeout(timer);
      }, 1000);
    }
  }, [removed]);

  const submitForm = (data) => {
    dispatch(removeAddress(data));
    setReveal(false);
  };

  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <i className="sixteen">You are about to remove this address?</i>
          <div className="alert-warning">
            {street}, {city} , {postal_code}, {country}
          </div>
          {removed && (
            <div className="alert-success">Card Removed successfully</div>
          )}

          <div className="full-flex">
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />

            <input
              type="hidden"
              placeholder="addID"
              defaultValue={addID}
              {...register("addID", { required: true })}
            />
          </div>

          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {loading ? <SpinnerCircular size={20} /> : " Remove Address"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RemoveAddressConfirm;
