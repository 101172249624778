import { createSlice } from "@reduxjs/toolkit";
import {
  setWallet,
  stripeFund,
  hookWallet,
  revealWallet,
  revealWalletBalances,
  revealAllWalletBalances,
  revealPoolMarginBalances,
  revealIntraTransactionBalances,
  revealExchangeApiBalances,
  sendFund,
  requestFund,
  withdrawFund,
  walletStateUpdate,
  swapCurrency,
  createCardholder,
  createCard,
} from "./walletActions";
const initialState = {
  wLoading: false,
  error: null,
  FUNDS: null,
  WITHDRAWAL: null,
  WALLET_DATA: null,
  STRIPE_FUND: null,
  WALLET_HOOKED: null,
  BALANCES: null,
  ADMIN_BALANCES: null,
  ADMIN_INTRA_BALANCES: null,
  ADMIN_TOBANK_BALANCES: null,
  ADMIN_EXCHANGE_API_BALANCES: null,
  fund_sent: false,
  WH: null,
  CURRENCY_SWAP_HISTORY: null,
  CARDHOLDER: null,
  CARD_CREATED: null,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    resetWalletOnLogout: (state) => {
      state.error = null;
      state.wLoading = false;
      state.FUNDS = null;
      state.WITHDRAWAL = null;
      state.WALLET_DATA = null;
      state.STRIPE_FUND = null;
      state.WALLET_HOOKED = null;
      state.BALANCES = null;
      state.ADMIN_BALANCES = null;
      state.ADMIN_INTRA_BALANCES = null;
      state.ADMIN_TOBANK_BALANCES = null;
      state.ADMIN_EXCHANGE_API_BALANCES = null;
      state.fund_sent = false;
      state.request_success = null;
      state.swap_success = null;
      state.WH = null;
      state.CURRENCY_SWAP_HISTORY = null;
      state.CARDHOLDER = null;
      state.CARD_CREATED = null;
    },
  },
  extraReducers(builder) {
    builder
      // set fund data in state for wallet credit
      .addCase(setWallet.pending, (state) => {
        state.wLoading = true;
        state.error = null;
        state.WALLET_DATA = false;
      })
      .addCase(setWallet.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.WALLET_DATA = true;
        state.WALLET_DATA = payload;
      })
      .addCase(setWallet.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
      }) // add fund for wallet credit
      .addCase(stripeFund.pending, (state) => {
        state.wLoading = true;
        state.error = null;
        state.STRIPE_FUND = false;
      })
      .addCase(stripeFund.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.STRIPE_FUND = true;
        state.STRIPE_FUND = payload;
      })
      .addCase(stripeFund.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
      })
      // hook wallet
      .addCase(hookWallet.pending, (state) => {
        state.wLoading = true;
        state.error = null;
        state.WALLET_HOOKED = false;
      })
      .addCase(hookWallet.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.WALLET_HOOKED = true;
        state.WALLET_HOOKED = payload;
      })
      .addCase(hookWallet.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
      })
      .addCase(walletStateUpdate.pending, (state) => {
        state.wLoading = true;
        state.error = null;
        state.walletFundSet = false;
      })
      .addCase(walletStateUpdate.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.walletFundSet = true;
        state.FUNDS = payload;
      })
      .addCase(walletStateUpdate.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
      }) // reveal wallet
      .addCase(revealWallet.pending, (state) => {
        state.wLoading = true;
        state.error = null;
      })
      .addCase(revealWallet.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.FUNDS = payload;
      })
      .addCase(revealWallet.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
      }) // reveal sum wallet
      .addCase(revealWalletBalances.pending, (state) => {
        state.wLoading = true;
        state.error = null;
      })
      .addCase(revealWalletBalances.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.BALANCES = payload;
      })
      .addCase(revealWalletBalances.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
      })
      // reveal all wallet balances for admin
      .addCase(revealAllWalletBalances.pending, (state) => {
        state.wLoading = true;
        state.error = null;
      })
      .addCase(revealAllWalletBalances.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.ADMIN_BALANCES = payload;
      })
      .addCase(revealAllWalletBalances.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
      })
      // reveal all intra balances for admin
      .addCase(revealIntraTransactionBalances.pending, (state) => {
        state.wLoading = true;
        state.error = null;
      })
      .addCase(
        revealIntraTransactionBalances.fulfilled,
        (state, { payload }) => {
          state.wLoading = false;
          state.ADMIN_INTRA_BALANCES = payload;
        }
      )
      .addCase(
        revealIntraTransactionBalances.rejected,
        (state, { payload }) => {
          state.wLoading = false;
          state.error = payload;
        }
      ) // reveal all exchange api balances for admin
      .addCase(revealExchangeApiBalances.pending, (state) => {
        state.wLoading = true;
        state.error = null;
      })
      .addCase(revealExchangeApiBalances.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.ADMIN_EXCHANGE_API_BALANCES = payload;
      })
      .addCase(revealExchangeApiBalances.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
      })
      // reveal pool margin wallet balances for admin
      .addCase(revealPoolMarginBalances.pending, (state) => {
        state.wLoading = true;
        state.error = null;
      })
      .addCase(revealPoolMarginBalances.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.ADMIN_TOBANK_BALANCES = payload;
      })
      .addCase(revealPoolMarginBalances.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
      })
      // send fund to another account
      .addCase(sendFund.pending, (state) => {
        state.wLoading = true;
        state.fund_sent = false;
        state.error = null;
      })
      .addCase(sendFund.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.fund_sent = true;
        state.FUNDS = payload;
      })
      .addCase(sendFund.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.fund_sent = false;
        state.error = payload;
      }) // Request fund from another account owner
      .addCase(requestFund.pending, (state) => {
        state.wLoading = true;
        state.error = null;
        state.request_success = null;
      })
      .addCase(requestFund.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.FUNDS = payload;
        state.request_success = true;
      })
      .addCase(requestFund.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
        state.request_success = null;
      }) // make withdrawal request
      .addCase(withdrawFund.pending, (state) => {
        state.wLoading = true;
        state.error = null;
        state.request_success = null;
      })
      .addCase(withdrawFund.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.WITHDRAWAL = payload;
        state.request_success = true;
      })
      .addCase(withdrawFund.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
        state.request_success = null;
      }) // currency swap
      .addCase(swapCurrency.pending, (state) => {
        state.wLoading = true;
        state.error = null;
        state.swap_success = false;
      })
      .addCase(swapCurrency.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.CURRENCY_SWAP = payload;
        state.swap_success = true;
      })
      .addCase(swapCurrency.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
        state.swap_success = false;
      }) // cardholder status
      .addCase(createCardholder.pending, (state) => {
        state.wLoading = true;
        state.error = null;
        state.cardholder_success = false;
      })
      .addCase(createCardholder.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.CARDHOLDER = payload;
        state.cardholder_success = true;
      })
      .addCase(createCardholder.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
        state.cardholder_success = false;
      }) // card issuing status
      .addCase(createCard.pending, (state) => {
        state.wLoading = true;
        state.error = null;
        state.card_success = false;
      })
      .addCase(createCard.fulfilled, (state, { payload }) => {
        state.wLoading = false;
        state.CARD_CREATED = payload;
        state.card_success = true;
      })
      .addCase(createCard.rejected, (state, { payload }) => {
        state.wLoading = false;
        state.error = payload;
        state.card_success = false;
      });
  },
});
export const { resetWalletOnLogout, refreshBalancesOnAddFund } =
  walletSlice.actions;
export default walletSlice.reducer;
