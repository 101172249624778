import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const ClipboardRevealLink = () => {
  const [showLink, setShowLink] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");

  const { USER_INFO } = useSelector((state) => state.user);
  const [text] = useState(
    process.env.REACT_APP_PAYME_BASE_URL + USER_INFO?.account?.payme_link_code
  );

  const handleClick = () => {
    setShowLink(true);
    setCopySuccess("");
    setTimeout(() => {
      setShowLink(false);
      setCopySuccess("");
    }, 8000);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText("<a href=" + text + ">Pay me with Zurepay</a>")
      .then(() => {
        setCopySuccess("Copied!");
      })
      .catch((err) => {
        setCopySuccess("Failed to copy!");
      });
  };

  return (
    <div>
      <div className="fllex">
        <button onClick={handleClick}>
          Reveal Payment link for my website
        </button>
        {copySuccess && (
          <span style={{ marginLeft: "10px", color: "green" }}>
            {copySuccess}
          </span>
        )}
      </div>
      {showLink && (
        <div style={{ marginTop: "10px" }} className="overflow-hidden">
          <span className="txt-red">
            Add this clickable link on your website to receive zurepay payment
          </span>
          <br />
          <br />
          <code>&lt;a href="{text}"&gt;Pay me with Zurepay&lt;/a&gt;</code>
          <br />
          <br />
          <button className="login" onClick={handleCopy}>
            Click to Copy code
          </button>
        </div>
      )}
    </div>
  );
};

export default ClipboardRevealLink;
