import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { requestFund } from "../features/wallet/walletActions";
import { SpinnerCircular } from "spinners-react";

const RequestFund = (props) => {
  const [setCurrency] = useState("");
  const [setAmount] = useState("");
  const [setDescription] = useState("");

  const [requested_by, setRequestedBy] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const funded = localStorage.getItem("FUND_STATUS");
  const [email, setEmail] = useState("");
  const { wLoading, error } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email, requested_by } });
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("IS_LOGIN_ZPAY");
    if (!auth) {
      navigate("/login");
    }
    const uEmail = localStorage.getItem("USER_EMAIL");
    if (uEmail) {
      setEmail(uEmail);
      setRequestedBy(uEmail);
    }
  }, [navigate]);
  useEffect(() => {
    if (funded) {
      const timer = setTimeout(() => {
        localStorage.removeItem("FUND_STATUS");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [funded]);
  const submitForm = (data) => {
    // check input data
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(requestFund(data));
    console.log(data);
    setCurrency("");
    setAmount("");
    setDescription("");
  };
  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <i>Request Fund from a Zurepay User</i>
          {error && <div className="alert-warning">{error}</div>}
          {funded && (
            <i className="sixteen alert-success">Request sent successfully</i>
          )}
          <div className="full-flex">
            <input
              type="email"
              placeholder="Zurepay Account Email"
              defaultValue={email}
              {...register("email", { required: true })}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={(e) => setEmail(e.target.value)}
            />
            {errors.email && (
              <i className="sixteen alert-warning">
                Zurepay email account is required
              </i>
            )}
            <div className="half-flex">
              <select {...register("transaction_currency", { required: true })}>
                <option value="">Select currency</option>
                <option value="gbp">Pound</option>
                <option value="eur">Euro</option>
                <option value="usd">US dollar</option>
                <option value="ngn">Nigeria Naira</option>
                <option value="zar">South Africa Rand</option>
              </select>
              <input
                type="text"
                placeholder="Amount"
                {...register("transaction_amount", { required: true })}
                onChange={(e) => setAmount(e.target.value)}
                onFocus={(e) => setAmount(e.target.value)}
              />
            </div>
            {errors.transaction_currency && (
              <i className="sixteen alert-warning">
                Transaction currency is required
              </i>
            )}
            {errors.transaction_amount && (
              <i className="sixteen alert-warning">
                Transaction amount is required
              </i>
            )}
          </div>
          <div className="half-flex">
            <input
              type="hidden"
              placeholder="funded by"
              defaultValue={requested_by}
              {...register("requested_by", { required: true })}
            />
          </div>

          <input
            type="text"
            placeholder="Description"
            {...register("description", { required: true })}
            onChange={(e) => setDescription(e.target.value)}
            onFocus={(e) => setDescription(e.target.value)}
          />
          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {wLoading ? <SpinnerCircular size={20} /> : " Send Request"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestFund;
