import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios, { fURL } from "../../api/axios";

const KYCOne = () => {
  const [data, setData] = useState(false);
  const [msg, setMsg] = useState("");

  const params = useParams();
  const id = params.id;
  useEffect(() => {
    axios
      .get(`kyc/initiated/${id}`)
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  if (!data) return null;

  const ApproveKYCResidence = () => {
    axios
      .get(`residence/${id}`)
      .then((response) => {
        setMsg(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const ApproveKYCIdentity = () => {
    axios
      .get(`identity/${id}`)
      .then((response) => {
        setMsg(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <section className="wallet">
      <div className="container">
        <div className="dash-left dash-left-bg">
          <div className="medium-card">
            <h3>KYC</h3>
            <p className="links">{data.acc_view?.email}</p>
            <p className="links txt-red">{msg ? msg : ""}</p>

            <p className="fllex">
              KYC Residence Docs:{" "}
              {data.acc_view?.proof_of_residence_status !== "PENDING" && (
                <a
                  href={`/uploads/kyc_docs/${data.acc_view?.email}/${data.acc_view?.kyc?.proof_of_residence}`}
                  download={`${data.acc_view?.kyc?.proof_of_residence}`}
                >
                  Download
                </a>
              )}
              {data.acc_view?.proof_of_residence_status === "INITIATED" && (
                <button className="links" onClick={ApproveKYCResidence}>
                  Approve
                </button>
              )}
            </p>
            <p className="links">
              KYC Identity Docs:{" "}
              {data.acc_view?.proof_of_identity_status !== "PENDING" && (
                <a
                  href={`/uploads/kyc_docs/${data.acc_view?.email}/${data.acc_view?.kyc?.identity}`}
                  download={`${data.acc_view?.kyc?.identity}`}
                >
                  Download
                </a>
              )}
              {data.acc_view?.proof_of_identity_status === "INITIATED" && (
                <button className="links" onClick={ApproveKYCIdentity}>
                  Approve
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KYCOne;
