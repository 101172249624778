import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { refreshPasswordResetLink } from "../features/user/userActions";

// form styles
import "../styles/forms.css";
const RefreshPasswordResetLinkScreen = () => {
  const [email, setEmail] = useState(
    localStorage.getItem("PASS_RESET_EMAIL")
      ? localStorage.getItem("PASS_RESET_EMAIL")
      : ""
  );
  const [catchedError, setCatchedError] = useState(false);
  const updated = localStorage.getItem("PASS_RESET_LINK_SET");
  const { loading, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email } });

  useEffect(() => {
    if (updated) {
      const timer = setTimeout(() => {
        localStorage.removeItem("REFRESH_PASS_LINK");
        localStorage.removeItem("PASS_RESET_EMAIL");
        localStorage.removeItem("PASS_RESET_LINK_SET");
        localStorage.clear();
        setCatchedError(false);
        navigate("/passwordreset/link");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, updated]);

  const submitForm = (data) => {
    dispatch(refreshPasswordResetLink(data));
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZPAY-ICON" />
        </div>
        <p className="divider">
          <span>Reset Link</span>
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {catchedError && (
              <div className="alert-warning">{error}</div>
          
          )}

          {updated && (
              <h3 className="alert-success">Zurepay cache cleared...</h3>
           
          )}

          <input
            type="hidden"
            defaultValue={email}
            placeholder="Enter Email"
            {...register("email", { require: true })}
          />
          {errors.email && <span>Email is required</span>}

          <button type="submit" className="r-btn" disabled={loading}>
            {loading ? "Clearing cache..." : "Clear Zurepay Cache"}
          </button>
          <Link to="/login" className="mt-10">
            Or back to Login
          </Link>
        </form>
      </div>
    </section>
  );
};

export default RefreshPasswordResetLinkScreen;
