import React, { useEffect, useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { addEmail } from "../features/user/userActions";

const AddEmail = () => {
  const { error, loading } = useSelector((state) => state.user);
  const updated = localStorage.getItem("SECONDARY_EMAIL_ADDED");
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email },
  });
  const submitForm = (data) => {
    dispatch(addEmail(data));
    console.log(data);
  };

  useEffect(() => {
    if (updated) {
      const timer = setTimeout(() => {
        localStorage.removeItem("SECONDARY_EMAIL_ADDED");
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [updated]);

  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <i className="sixteen">Add Secondary Email</i>
          {error && <div className="alert-warning">{error}</div>}
          {updated && (
            <i className="alert-success">Secondary email added successfully</i>
          )}

          <div className="full-flex">
            <input
              type="hidden"
              placeholder="Email"
              defaultValue={email}
              {...register("email", { required: true })}
            />

            <input
              type="email"
              placeholder="Seconday Email"
              {...register("email_secondary", { required: true })}
            />
            {errors.email_secondary && <span>Secondary email is required</span>}
          </div>

          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {loading ? <SpinnerCircular size={20} /> : "Update Number"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEmail;
