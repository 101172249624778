import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { userAuthLogin } from "../features/user/userActions";
import { walletStateUpdate } from "../features/wallet/walletActions";
import { refreshLogin } from "../features/user/userActions";
import { logout } from "../features/user/userSlice";
import { resetWalletOnLogout } from "../features/wallet/walletSlice";
import { toast } from "react-toastify";
import axios from "../api/axios";

// form styles
import "../styles/forms.css";
const LoginAuthScreen = () => {
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const [ip, setIp] = useState(localStorage.getItem("CLIENT_IP"));
  const pay_me_link_email_init = localStorage.getItem("PAY_ME_LINK_EMAIL_INIT");
  const [pay_me_link] = useState(
    localStorage.getItem("PAY_ME_LINK")
      ? localStorage.getItem("PAY_ME_LINK")
      : ""
  );
  if (!ip) {
    setIp("0.0.0.0");
  }
  const { loading, error, IS_LOGIN_ZPAY } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email, ip } });

  useEffect(() => {
    if (IS_LOGIN_ZPAY && pay_me_link_email_init) {
      toast.success("You can proceed to fulfil payment");
      const timer = setTimeout(() => {
        navigate(`/payme/${pay_me_link}`);
      }, 3000);
      return () => clearTimeout(timer);
    } else if (IS_LOGIN_ZPAY) {
      toast.success("You are logged in");
      const timer = setTimeout(() => {
        localStorage.removeItem("CLIENT_IP");
        navigate("/my/dashboard");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, IS_LOGIN_ZPAY, pay_me_link, pay_me_link_email_init]);

  const submitForm = (data) => {
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    setIp(ip);
    dispatch(userAuthLogin(data));
    dispatch(walletStateUpdate(data));
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZPAY-ICON" />
        </div>
        <p className="divider">
          <span>Auth access</span>
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {error && <div className="alert-warning">{error}</div>}
          {!email && (
            <h3 className="alert-warning">Please provide your email</h3>
          )}

          {IS_LOGIN_ZPAY && (
            <h3 className="alert-success">
              Authenticated. Loading your dashboard...
            </h3>
          )}

          <input
            type="hidden"
            defaultValue={email}
            placeholder="Enter Email"
            {...register("email", { require: true })}
          />
          <input
            type="hidden"
            defaultValue={ip}
            placeholder="Enter Client's IP"
            {...register("ip")}
          />

          {errors.email && <span>Email is required</span>}
          <input type="text" placeholder="Enter OTP" {...register("otp")} />
          {errors.otp && <span>OTP is required</span>}
          <button type="submit" className="r-btn" disabled={loading}>
            {loading ? "Loging in..." : "Login"}
          </button>

          <Link to="/refresh/login" className="mt-10">
            Didn't receive OTP or OTP Expired?
          </Link>
        </form>
      </div>
    </section>
  );
};

export default LoginAuthScreen;
