import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RefreshAllWalletBalances from "../screens/RefreshAllWalletBalances";
import "react-credit-cards-2/es/styles-compiled.css";
import axios from "../api/axios";
import RefreshIntraBalances from "../screens/RefreshIntraBalances";
import RefreshToBankBalances from "../screens/RefreshToBankBalances";
import RefreshExchangeApiBalances from "../screens/RefreshExchangeApiBalances";

import { moneyFormatter } from "../utils/moneyFormatter";

const AdminDashboard = () => {
  const [data, setData] = useState(0);
  const [data1, setData1] = useState(0);
  const [data2, setData2] = useState(0);
  const { IS_LOGIN_ZPAY } = useSelector((state) => state.user);
  const {
    ADMIN_BALANCES,
    ADMIN_TOBANK_BALANCES,
    ADMIN_INTRA_BALANCES,
    ADMIN_EXCHANGE_API_BALANCES,
  } = useSelector((state) => state.wallet);
  let bal = ADMIN_BALANCES?.curr;
  let bal1 = ADMIN_TOBANK_BALANCES?.curr;
  let bal2 = ADMIN_INTRA_BALANCES?.curr;
  let bal3 = ADMIN_EXCHANGE_API_BALANCES?.curr;

  const navigate = useNavigate();
  useEffect(() => {
    if (!IS_LOGIN_ZPAY) {
      const timer = setTimeout(() => navigate("/login"), 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, IS_LOGIN_ZPAY]);
  //find wallet balances

  // find total users
  useEffect(() => {
    axios
      .get("users/total")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //KYC count
  useEffect(() => {
    axios
      .get("kyc/initiated")
      .then((response) => {
        setData1(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Pending withdrawal count
  useEffect(() => {
    axios
      .get("withdrawal/pending")
      .then((response) => {
        setData2(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section className="dashboard">
      <div className="container">
        <div className="dash-left dash-left-bg">
          <div className="medium-card">
            <h3>Wallet Balances</h3>
            <span className="">
              {bal?.length > 0
                ? bal?.map((b) => {
                    return (
                      <div key={b?._id}>
                        {moneyFormatter(b?.balance, b?.currency)}
                      </div>
                    );
                  })
                : 0.0}
            </span>
            <br />
            <RefreshAllWalletBalances />
          </div>
          <div className="medium-card">
            <h3>Margin from bank Withdrawals</h3>
            <span className="">
              {bal1?.length > 0
                ? bal1?.map((b) => {
                    return (
                      <div key={b?._id}>
                        {moneyFormatter(b?.balance, b?.currency)}
                      </div>
                    );
                  })
                : 0.0}
            </span>
            <br />
            <RefreshToBankBalances />
          </div>

          {/* Margin from Intra Transactions */}
          <div className="medium-card">
            <h3>Margin from Intra Transactions</h3>
            <span className="">
              {bal2?.length > 0
                ? bal2?.map((b) => {
                    return (
                      <div key={b?._id}>
                        {moneyFormatter(b?.balance, b?.currency)}
                      </div>
                    );
                  })
                : 0.0}
            </span>
            <br />
            <RefreshIntraBalances />
          </div>

          {/* Margin from Exchange API balances */}
          <div className="medium-card">
            <h3>Margin from Exchange API Call</h3>
            <span className="">
              {bal3?.length > 0
                ? bal3?.map((b) => {
                    return (
                      <div key={b?._id}>
                        {moneyFormatter(b?.balance, b?.currency)}
                      </div>
                    );
                  })
                : 0.0}
            </span>
            <br />
            <RefreshExchangeApiBalances />
          </div>
        </div>

        {/* Right conts */}
        <div className="dash-right">
          {/* Add Bank details */}

          <div className="settings-large-card flex-out-bio">
            <div className="bio">
              <h3>Admin Corner</h3>
              <h4 className="my_flex">
                Total Users: {data?.user_count}
                <span className="link_orange">
                  <Link to="/accounts">View</Link>
                </span>
              </h4>
              <h4 className="my_flex">
                Waiting KYCs: {data1?.user_count}
                <span className="link_orange">
                  <Link to="/kyc/initiated">View</Link>
                </span>
              </h4>
              <h4 className="my_flex">
                Pending Withdrawals: {data2?.user_count}
                <span className="link_orange">
                  <Link to="/withdrawal/pending">View</Link>
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminDashboard;
