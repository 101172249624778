import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../features/user/userSlice";
import { resetWalletOnLogout } from "../features/wallet/walletSlice";
import { Link } from "react-router-dom";
import { BsX, BsList } from "react-icons/bs";

import { FaBell } from "react-icons/fa";
import { AiFillLock, AiFillSetting } from "react-icons/ai";
import { toast } from "react-toastify";
import AuthSessionLogout from "../components/AuthSessionLogout";

import { scrollToTop } from "../utils/scroller";
import { fURL } from "../api/axios";
const Navigationbar = () => {
  const [navOpen, SetNavOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = localStorage.getItem("IS_LOGIN_ZPAY");

  const { USER_INFO } = useSelector((state) => state.user);
  const handleNavOpen = () => {
    SetNavOpen(!navOpen);
  };
  const handleLogout = () => {
    dispatch(resetWalletOnLogout());
    dispatch(logout());
    toast.success("You are logged out");
    navigate("/");
  };
  return (
    <>
      <div className="dash-nav">
        <div className="dash-logo">
          <img src="/images/icon.png" alt="ZPAY-ICON" />
          <Link className="logo text-3xl" to="/">
            ZurePay
          </Link>
        </div>
        <div
          onClick={handleNavOpen}
          className={
            navOpen ? "dash-menu dash-menu-open" : "dash-menu dash-menu-close"
          }
        >
          <ul className="dash-menu-ul">
            <div className="">
              <li>
                <Link to="/my/dashboard">Dashboard</Link>
              </li>
              {USER_INFO?.account?.account_type !== "Admin" && (
                <>
                  <li>
                    <Link to="/my/wallet">Wallet</Link>
                  </li>
                  <li>
                    <Link to="/payments/sent">Pay Sent</Link>
                  </li>

                  <li>
                    <Link to="/payments/received">Pay Received</Link>
                  </li>
                </>
              )}
            </div>
            <div className="settings-menu-show">
              <li>
                <Link to="/my/dashboard">Dashboard</Link>
              </li>
              {USER_INFO?.account?.account_type !== "Admin" && (
                <>
                  <li>
                    <Link to="/my/wallet">Wallet</Link>
                  </li>
                  <li>
                    <Link to="/my/fundings">Funding History</Link>
                  </li>
                  <li>
                    <Link to="/my/withdrawals">Withdrawal History</Link>
                  </li>
                  <li>
                    <Link to="/payments/sent">Pay Sent</Link>
                  </li>

                  <li>
                    <Link to="/payments/received">Pay Received</Link>
                  </li>
                  <li>
                    <Link to="/my/profile">Profile</Link>
                  </li>
                </>
              )}
              <li>
                <Link>Security</Link>
              </li>
              <li>
                <Link
                  onClick={() => scrollToTop()}
                  className="link"
                  to="/pages/legal/protection"
                >
                  Data Protection & Privacy
                </Link>
              </li>
              <li>
                <Link to="/">Tools</Link>
              </li>
            </div>
          </ul>
          {isLogged ? <AuthSessionLogout /> : ""}
        </div>

        <div className="dash-menu dash-menu-open dash-flex">
          <li>
            <Link to="/my/notifications">
              <FaBell color="#454545" size={20} />
            </Link>
          </li>
          <li>
            <Link
              to="/my/settings"
              onClick={handleNavOpen}
              className={
                navOpen
                  ? "dash-menu dash-menu-open"
                  : "dash-menu dash-menu-close"
              }
            >
              <AiFillSetting color="#454545" size={20} />
            </Link>
          </li>
          <li>
            <Link to="/my/settings">
              <img
                className="form_icon_2"
                src={`/uploads/profile_images/${
                  USER_INFO?.account?.bio_data?.avatar ===
                  `zurepay_zero_avatar.png`
                    ? `zurepay_zero_avatar.png`
                    : `${USER_INFO?.account?.email}/${USER_INFO?.account?.bio_data?.avatar}`
                }`}
                alt="ZPAY-ICON"
              />
            </Link>
          </li>
          <li>{USER_INFO?.account?.bio_data?.firstname}</li>
          <li className="logout-icon">
            <Link onClick={() => handleLogout()}>
              <AiFillLock color="#454545" size={20} />
            </Link>
          </li>
          <button
            className="signup  logout-button"
            onClick={() => handleLogout()}
          >
            Logout
          </button>
        </div>
        {/* Hamburger */}
        <div onClick={handleNavOpen} className="hamburger-container">
          {navOpen ? (
            <BsX className="hamburger" size={30} />
          ) : (
            <BsList className="hamburger" size={30} />
          )}
        </div>
      </div>
      <div className="settings-nav">
        <div className="settings-menu">
          <li>
            {USER_INFO?.account?.account_type !== "Admin" && (
              <>
                <Link onClick={() => scrollToTop()} to="/my/profile">
                  Profile
                </Link>
              </>
            )}
          </li>
          <li>
            <Link>Security</Link>
          </li>
          <li>
            <Link
              onClick={() => scrollToTop()}
              className="link"
              to="/pages/legal/protection"
            >
              Data Protection & Privacy
            </Link>
          </li>
          <li>
            <Link to="/">Tools</Link>
          </li>
        </div>
      </div>
    </>
  );
};

export default Navigationbar;
