import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";
import { creditCardType } from "../utils/cardIssuer";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentCard } from "../features/user/userActions";

import {
  normalizeCardNum,
  normalizeCVV,
  normalizeCardED,
} from "../utils/blindit";
import { SpinnerCircular } from "spinners-react";

const AddCard = () => {
  const [card_number, setNumber] = useState("");
  const [name_on_card, setNameOnCard] = useState("");
  const [card_expiry_date, setExpiry] = useState("");
  const [card_cvv, setCVV] = useState("");
  const [card_issuer, setIssuer] = useState(creditCardType(card_number));
  const [focus, setFocus] = useState("");
  const [cardAdded, setCardAdded] = useState(false);
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );

  const { USER_INFO, loading, error } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const card_added = localStorage.getItem("CARD_ADDED");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email, card_issuer, name_on_card } });
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("IS_LOGIN_ZPAY");
    if (!auth) {
      navigate("/login");
    }
    const uEmail = localStorage.getItem("USER_EMAIL");
    if (uEmail) {
      setEmail(uEmail);
    }
  }, [navigate]);

  useEffect(() => {
    if (card_added) {
      const timer = setTimeout(() => {
        localStorage.removeItem("CARD_ADDED");
        setCardAdded(false);
        navigate("/my/settings");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [navigate, card_added]);

  const submitForm = (data) => {
    // check input data
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(addPaymentCard(data));
    console.log(data);
    setNumber("");
    setNameOnCard("");
    setExpiry("");
    setCVV("");
    setIssuer("");
  };
  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="full-flex">
            <i>ADD CARD TO YOUR ACCOUNT</i>
            {error && (
                <div className="alert-warning">{error}</div>
          
            )}
            {card_added && (
                <div className="alert-success">
                  Card Details added successfully
                </div>
            
            )}

            <input
              type="tel"
              placeholder="Card Number"
              value={card_number}
              {...register("card_number", { required: true })}
              onChange={(e) => setNumber(normalizeCardNum(e.target.value))}
              onFocus={(e) => setNumber(e.target.value)}
            />

            <select {...register("card_issuer")}>
              <option value="">Select Card Brand</option>
              <option value="Visa">Visa</option>
              <option value="Mastercard">Mastercard</option>
              <option value="Maestro">Maestro</option>
              <option value="Amex">America Express</option>
              <option value="Discover">Discover</option>
              <option value="Dinner">Dinner's Club</option>
            </select>
            <input
              type="text"
              defaultValue={name_on_card}
              placeholder="Name on card"
              {...register("name_on_card", { required: true })}
              onChange={(e) => setNameOnCard(e.target.value)}
              onFocus={(e) => setNameOnCard(e.target.value)}
            />
          </div>
          <div className="half-flex">
            <input
              type="tel"
              placeholder="MM/YY"
              pattern="\d\d/\d\d"
              value={card_expiry_date}
              {...register("card_expiry_date", { required: true })}
              onChange={(e) => setExpiry(normalizeCardED(e.target.value))}
              onFocus={(e) => setExpiry(e.target.value)}
            />

            <input
              type="number"
              placeholder="CVV/CVC"
              pattern="\d{3,4}"
              value={card_cvv}
              {...register("card_cvv", { required: true })}
              onChange={(e) => setCVV(normalizeCVV(e.target.value))}
              onFocus={(e) => setCVV(e.target.value)}
            />
          </div>
       
          <div className="btn-container">
          <button></button>
            <button type="submit" className="login">
              {loading ? <SpinnerCircular size={20} /> : "Add Card"}
            </button>
          </div>
        </form>
      </div>
      <div className="left">
        <Cards
          number={card_number}
          expiry={card_expiry_date}
          cvc={card_cvv}
          name={name_on_card}
          issuer={card_issuer}
          focused={focus}
          preview={true}
        />
      </div>
    </div>
  );
};

export default AddCard;
