import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-credit-cards-2/es/styles-compiled.css";
import RefreshWalletBalances from "../screens/RefreshWalletBalances";
import { moneyFormatter } from "../utils/moneyFormatter";

const Wallet = () => {
  const { USER_INFO } = useSelector((state) => state.user);
  const { BALANCES } = useSelector((state) => state.wallet);
  let bal = BALANCES?.curr;
  //const dispatch = useDispatch();

  return (
    <section className="wallet">
      {USER_INFO?.account?.account_type === "Admin" ? (
        <div className="container">
          <div className="dash-left dash-left-bg">
            <div className="medium-card">
              <h3>Admin Wallet Balance(s)</h3>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="dash-left dash-left-bg">
            <div className="medium-card">
              <h3>My Wallet Balance(s)</h3>

              <p>
                <span className="bal">
                  {bal?.length > 0 ? (
                    bal.map((b, idx) => {
                      return (
                        <>
                          <div key={idx}>
                            {moneyFormatter(
                              b?.balance,
                              b?.transaction_currency
                            )}
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      0.00
                      {/* <RevealWalletBalances /> */}
                    </>
                  )}
                </span>
              </p>
              <RefreshWalletBalances />
            </div>

            {/* <div className="large-card">
              <h3>My Wallet Activities</h3>
              <p className="links">
                <li>
                  <Link to="/my/fundings">Funding History</Link>
                </li>
                <li>
                  <Link to="/my/withdrawals">Withdrawal History</Link>
                </li>
                <li>
                  <Link to="/payments/sent">Pay Sent</Link>
                </li>

                <li>
                  <Link to="/payments/received">Pay Received</Link>
                </li>
              </p>
            </div> */}
          </div>
        </div>
      )}
    </section>
  );
};

export default Wallet;
