import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { HideString, blindEmail } from "../utils/blindit";
import ProfilePhotoUploadScreen from "../screens/ProfilePhotoUploadScreen";
import Popup from "./Popup";
import AddAddress from "../screens/AddAddress";
import RemoveAddress from "../screens/RemoveAddress";
import UpdatePhone from "../screens/UpdatePhone";
import DeleteAccountInit from "../screens/DeleteAccountInit";
import SwitchToMerchant from "../screens/SwitchToMerchant";
import AddEmail from "../screens/AddEmail";
import UpdateEmail from "../screens/UpdateEmail";
const Settings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [updatePhone, setUpdatePhone] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [switchAccountType, setSwitchAccountType] = useState(false);

  const [addEmail, setAddEmail] = useState(false);
  const [updateEmail, setUpdateEmail] = useState(false);
  const [filename, setFilename] = useState(
    localStorage.getItem("FILE_NAME")
      ? localStorage.getItem("FILE_NAME")
      : "zurepay_zero_avatar.png"
  );
  // form handling functions
  const toggleUpdatePhoto = () => {
    setIsOpen(!isOpen);
  };
  const toggleAddAddress = () => {
    setAddAddress(!addAddress);
  };
  const toggleAccountDeletion = () => {
    setDeleteAccount(!deleteAccount);
  };
  const toggleUpdatePhone = () => {
    setUpdatePhone(!updatePhone);
  };
  const toggleAddEmail = () => {
    setAddEmail(!addEmail);
  };
  const toggleUpdateEmail = () => {
    setUpdateEmail(!updateEmail);
  };
  const toggleSwitchAccountType = () => {
    setSwitchAccountType(!switchAccountType);
  };

  const { USER_INFO } = useSelector((state) => state.user);

  useEffect(() => {
    if (filename) {
      const timer = setTimeout(() => {}, 3000);
      return () => clearTimeout(timer);
    }
    setFilename("FILE_NAME");
  }, [filename]);

  return (
    <section className="settings-dashboard light-gray-bg">
      <div className="settings-container">
        <div className="settings-dash-left light-gray-bg">
          {USER_INFO?.account?.profile_status === "COMPLETED" && (
            <>
              {/* settings */}
              <div className="settings-large-card flex-out-bio">
                <img
                  src={`/uploads/profile_images/${
                    USER_INFO?.account?.bio_data?.avatar ===
                    `zurepay_zero_avatar.png`
                      ? `zurepay_zero_avatar.png`
                      : `${USER_INFO?.account?.email}/${USER_INFO?.account?.bio_data?.avatar}`
                  }`}
                  alt="PROFILE_IMG"
                />
                <div className="bio">
                  <h3>Account Owner</h3>
                  <h6>
                    {`${
                      USER_INFO?.account?.bio_data
                        ? USER_INFO?.account?.bio_data?.firstname
                        : "First Name "
                    }  
                    ${
                      USER_INFO?.account?.bio_data
                        ? USER_INFO?.account?.bio_data?.lastname
                        : " Last Name"
                    }`}
                  </h6>
                  <div className="links">
                    <p>
                      Since:
                      {DateTime.fromISO(USER_INFO?.account?.createdAt).toFormat(
                        " ff"
                      )}
                    </p>
                    <span>
                      <li>
                        <Link>
                          <input
                            className="cursor"
                            type="button"
                            value="Update Photo"
                            onClick={toggleUpdatePhoto}
                          />
                        </Link>
                      </li>
                    </span>
                  </div>
                </div>

                <div>
                  {isOpen && (
                    <Popup
                      content={
                        <>
                          <ProfilePhotoUploadScreen />
                        </>
                      }
                      handleClose={toggleUpdatePhoto}
                    />
                  )}
                  {addAddress && (
                    <Popup
                      content={<AddAddress />}
                      handleClose={toggleAddAddress}
                    />
                  )}
                  {updatePhone && (
                    <Popup
                      content={<UpdatePhone />}
                      handleClose={toggleUpdatePhone}
                    />
                  )}
                  {/* account deletion pop form */}
                  {deleteAccount && (
                    <Popup
                      content={<DeleteAccountInit />}
                      handleClose={toggleAccountDeletion}
                    />
                  )}
                  {/* account switch to merchant */}
                  {switchAccountType && (
                    <Popup
                      content={<SwitchToMerchant />}
                      handleClose={toggleSwitchAccountType}
                    />
                  )}
                  {addEmail && (
                    <Popup
                      content={<AddEmail />}
                      handleClose={toggleAddEmail}
                    />
                  )}
                  {updateEmail && (
                    <Popup
                      content={<UpdateEmail />}
                      handleClose={toggleUpdateEmail}
                    />
                  )}
                </div>
              </div>
              <div className="medium-card">
                <h3> More Details</h3>

                <p>
                  <span className="title">Resides In:</span>
                  <span>
                    {USER_INFO?.account?.address
                      ? USER_INFO?.account?.address[0]?.country
                      : "Not-Available"}
                  </span>
                </p>
                <p>
                  <span className="title">Account Type:</span>
                  <span>{USER_INFO?.account?.account_type?.toUpperCase()}</span>
                </p>

                <p>
                  {USER_INFO?.account?.account_type === "Personal" && (
                    <>
                      <span></span>
                      <span
                        className="cursor login"
                        onClick={toggleSwitchAccountType}
                      >
                        Become a Merchant
                      </span>
                    </>
                  )}
                  {USER_INFO?.account?.account_type === "Merchant" && (
                    <>
                      <span></span>
                      <span
                        className="cursor login"
                        onClick={toggleSwitchAccountType}
                      >
                        Update Merchant Website
                      </span>
                    </>
                  )}
                </p>

                {USER_INFO?.account?.account_type !== "Admin" && (
                  <>
                    <h3>Account Deletion</h3>
                    <hr />
                    <p className="links">
                      <span>Account Deletion</span>
                      <span className="cursor" onClick={toggleAccountDeletion}>
                        DELETE ACCOUNT
                      </span>
                    </p>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {/* Right conts */}
        <div className="settings-dash-right">
          {/* Link Bank/Cards */}
          {USER_INFO?.account?.account_type !== "Admin" &&
            USER_INFO?.account?.profile_status !== "COMPLETED" && (
              <Link to="/my/profile" className="signup">
                Click here to complete your profile
              </Link>
            )}
          {USER_INFO?.account?.account_type !== "Admin" &&
            USER_INFO?.account?.profile_status === "COMPLETED" && (
              <div className="medium-card">
                <h3>Address</h3>
                <p>
                  <span className="login">Primary</span>
                  <span className="signup" onClick={toggleAddAddress}>
                    Add Address
                  </span>
                </p>
                {USER_INFO?.account?.account_type !== "Admin" &&
                  USER_INFO?.account?.address?.map((item, idx) => {
                    return <RemoveAddress key={idx} {...item} />;
                  })}
                <h3>Email Primary</h3>
                <p>
                  <span>{blindEmail(USER_INFO?.account?.email)}</span>
                </p>
                <h3>Email Secondary</h3>
                <p className="links">
                  <span>
                    {blindEmail(
                      USER_INFO?.account?.email_secondary
                        ? USER_INFO?.account?.email_secondary
                        : "N/A"
                    )}
                  </span>
                  <li>
                    {USER_INFO?.account?.email_secondary ? (
                      <span className="cursor" onClick={toggleUpdateEmail}>
                        Update
                      </span>
                    ) : (
                      <span className="cursor" onClick={toggleAddEmail}>
                        Add
                      </span>
                    )}
                  </li>
                </p>
                <hr />
                <h3>Phone Number</h3>
                <p className="links">
                  <span>
                    {USER_INFO?.account
                      ? HideString(`${USER_INFO?.account?.phone}`)
                      : HideString("00000000000")}
                  </span>
                  <li>
                    <span className="cursor" onClick={toggleUpdatePhone}>
                      Update
                    </span>
                  </li>
                </p>
              </div>
            )}
        </div>
      </div>
    </section>
  );
};

export default Settings;
