//currency formatter
export const moneyFormatter = (amount, currency) => {
  try {
    const formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
    });
    if (formatted) {
      return formatted.format(amount, currency);
    } else {
     throw new Error("Unable to call API");
    }
  } catch (error) {
    throw Error("Error occured: ", error);
  }
};
