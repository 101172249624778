import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const PrivateRoutes = () => {
  const { IS_LOGIN_ZPAY } = useSelector((state) => state.user);
  const location = useLocation();
  return IS_LOGIN_ZPAY ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default PrivateRoutes;
