import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsX, BsList } from "react-icons/bs";

const Navigation = () => {
  const [navOpen, SetNavOpen] = useState(false);

  const handleNavOpen = () => {
    SetNavOpen(!navOpen);
  };
  return (
    <div className="nav">
      <div className="logo">
        <img src="/images/icon.png" alt="ZPAY-ICON" />
        <Link className="logo text-3xl" to="/">
          ZurePay
        </Link>
      </div>
      <div
        onClick={handleNavOpen}
        className={navOpen ? "menu menu-open" : "menu menu-close"}
      >
        <ul className="menu-ul">
          <li>
            <Link className="login" to="/login">
              Login
            </Link>
          </li>
          <li>
            <Link className="signup" to="/signup">
              Register
            </Link>
          </li>
        </ul>
      </div>
      {/* Hamburger */}
      <div onClick={handleNavOpen} className="hamburger-container">
        {navOpen ? (
          <BsX className="hamburger" size={30} />
        ) : (
          <BsList className="hamburger" size={30} />
        )}
      </div>
    </div>
  );
};

export default Navigation;
