import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios, { fURL } from "../../api/axios";
import Popup from "../Popup";
import RequestWithdrawal from "../../screens/RequestWithdrawal";

const AccountOne = () => {
  const [data, setData] = useState(false);
  const [view, setView] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [msg, setMsg] = useState("");
  const [isWait, setIsWait] = useState(false);
  const { USER_INFO } = useSelector((state) => state.user);
  const config = {
    headers: {
      Authorization: `Bearer ${USER_INFO.USER_TOKEN}`,
    },
  };

  const toggleView = () => {
    setView(!view);
  };
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    const timer = setTimeout(() => {
      axios
        .get(`account/view/${id}`)
        .then((response) => {
          setData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setFeedback(false);
        });
    }, 3000);
    return () => clearTimeout(timer);
  }, [USER_INFO?.account?._id, id]);
  if (!data) return null;

  return (
    <section className="wallet">
      <div className="container">
        <div className="dash-left dash-left-bg">
          <div className="medium-card">
            <h3> User KYC</h3>
            {view && (
              <Popup content={<RequestWithdrawal />} handleClose={toggleView} />
            )}

            {feedback && !isWait && <p className="alert-success">{msg}</p>}
            <p className="links">{data.acc_view?.email}</p>
            <p className="links">
              KYC RESIDENCE STATUS {data.acc_view?.proof_of_residence_status}
            </p>
            <p className="links">
              KYC ID STATUS: {data.acc_view?.proof_of_identity_status}
            </p>
            <p className="links">
              KYC RESIDENCE DOCS:{" "}
              {data.acc_view?.proof_of_residence_status !== "PENDING" && (
                <a
                  href={`/uploads/kyc_docs/${data.acc_view?.email}/${data.acc_view?.kyc?.proof_of_residence}`}
                  download={`${data.acc_view?.kyc?.proof_of_residence}`}
                >
                  Download
                </a>
              )}
            </p>
            <p className="links">
              KYC ID DOCS:{" "}
              {data.acc_view?.proof_of_identity_status !== "PENDING" && (
                <a
                  href={`/uploads/kyc_docs/${data.acc_view?.email}/${data.acc_view?.kyc?.identity}`}
                  download={`${data.acc_view?.kyc?.identity}`}
                >
                  Download
                </a>
              )}
            </p>
            <p className="links">
              <hr />
              {data.acc_view?.email && (
                <Link
                  className="text_link_gray"
                  to={`/account/delete/${data.acc_view._id}`}
                >
                  Delete Account
                </Link>
              )}
            </p>
            <h1>
              {data?.acc_view?._id &&
                localStorage.setItem("ACCOUNT-ID", data.acc_view._id)}
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountOne;
