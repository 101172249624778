import axios from "../../api/axios";
//import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fundWalletOriginal = createAsyncThunk(
  "fundwallet",
  async (
    {
      email,
      transaction_currency,
      transaction_amount,
      transaction_type,
      funded_by,
      description,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "fund/wallet",
        {
          email,
          transaction_currency,
          transaction_amount,
          transaction_type,
          funded_by,
          description,
        },
        config
      );
      localStorage.setItem("FUND_STATUS", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const setWallet = createAsyncThunk(
  "setwallet",
  async (
    {
      email,
      transaction_currency,
      transaction_amount,
      transaction_type,
      funded_by,
      description,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "set/wallet",
        {
          email,
          transaction_currency,
          transaction_amount,
          transaction_type,
          funded_by,
          description,
        },
        config
      );
      localStorage.setItem("WALLET_DATA_STATUS", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const stripeFund = createAsyncThunk(
  "stripe-fund",
  async (
    { amount, currency, email, recipient, accountID, description, name },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "stripe-fund",
        {
          amount,
          currency,
          email,
          recipient,
          accountID,
          description,
          name,
        },
        config
      );
      localStorage.setItem("STRIPE_FUND", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const hookWallet = createAsyncThunk(
  "hookwallet",
  async ({ payment_intent }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "stripe-webhook",
        {
          payment_intent,
        },
        config
      );
      localStorage.setItem("WALLET_HOOKED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const walletStateUpdate = createAsyncThunk(
  "wallet/state",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        "walletstate/update",
        { email },
        config
      );
      console.log(data);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (!error?.response) {
        return rejectWithValue("No Server Response: Server may be down");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        // some or more pre conditions failed;
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const revealWallet = createAsyncThunk(
  "fundreveal",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "fund/reveal",
        {
          email,
        },
        config
      );
      //localStorage.setItem("FUND_STATUS", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const revealWalletBalances = createAsyncThunk(
  "revealbalances",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "reveal/balances",
        {
          email,
        },
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const revealAllWalletBalances = createAsyncThunk(
  "allbalances",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "balances/all",
        {
          email,
        },
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const revealPoolMarginBalances = createAsyncThunk(
  "balances/tobank",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "balances/tobank",
        {
          email,
        },
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const revealIntraTransactionBalances = createAsyncThunk(
  "balances/intra",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "balances/intra",
        {
          email,
        },
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const revealExchangeApiBalances = createAsyncThunk(
  "balances/exchange",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "balances/exchange",
        {
          email,
        },
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const sendFund = createAsyncThunk(
  "sendfund",
  async (
    { email, transaction_currency, transaction_amount, funded_by, description },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "fund/send",
        {
          email,
          transaction_currency,
          transaction_amount,
          funded_by,
          description,
        },
        config
      );
      localStorage.setItem("FUND_STATUS", true);
      return data;
    } catch (error) {
      localStorage.setItem("FUND_ERROR_STATUS", true);
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const requestFund = createAsyncThunk(
  "requestfund",
  async (
    {
      email,
      transaction_currency,
      transaction_amount,
      transaction_type,
      requested_by,
      description,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Crendentials": true,
          "Access-Control-Allow-Headers": true,
          //"Access-Control-Allow-Origin": "*",
        },
      };
      const { data } = await axios.post(
        "fund/request",
        {
          email,
          transaction_currency,
          transaction_amount,
          transaction_type,
          requested_by,
          description,
        },
        config
      );
      localStorage.setItem("FUND_STATUS", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const withdrawFund = createAsyncThunk(
  "fund/withdraw",
  async (
    { transaction_amount, transaction_currency, requested_by, description },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Crendentials": true,
          "Access-Control-Allow-Headers": true,
        },
      };
      const { data } = await axios.post(
        "fund/withdraw",
        {
          transaction_amount,
          transaction_currency,
          requested_by,
          description,
        },
        config
      );
      localStorage.setItem("WITHDRAW_STATUS", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const withdrawalHistory = createAsyncThunk(
  "withdrawal/history",
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Crendentials": true,
          "Access-Control-Allow-Headers": true,
          //"Access-Control-Allow-Origin": "*",
        },
      };
      const { data } = await axios.post(
        "withdrawal/history",
        {
          id,
        },
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const swapCurrency = createAsyncThunk(
  "swapcurrency",
  async (
    {
      firstAmount,
      resultCurrency,
      codeFromCurrency,
      codeToCurrency,
      newAmount,
      email,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Crendentials": true,
          "Access-Control-Allow-Headers": true,
          //"Access-Control-Allow-Origin": "*",
        },
      };
      const { data } = await axios.post(
        "currency/swap",
        {
          firstAmount,
          resultCurrency,
          codeFromCurrency,
          codeToCurrency,
          newAmount,
          email,
        },
        config
      );
      localStorage.setItem("SWAP_STATUS", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createCardholder = createAsyncThunk(
  "create/cardholder",
  async (
    {
      name,
      email,
      phone_number,
      street,
      city,
      state,
      country,
      postal_code,
      bearerToken,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Crendentials": true,
          "Access-Control-Allow-Headers": true,
          Authorization: bearerToken,
        },
      };
      const { data } = await axios.post(
        "create/cardholder",
        {
          name,
          email,
          phone_number,
          street,
          city,
          state,
          country,
          postal_code,
          bearerToken,
        },
        config
      );
      localStorage.setItem("CARD_HOLDER_STATUS", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createCard = createAsyncThunk(
  "create/card",
  async ({ currency, cardholder, bearerToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Crendentials": true,
          "Access-Control-Allow-Headers": true,
          Authorization: bearerToken,
        },
      };
      const { data } = await axios.post(
        "create/card",
        {
          currency,
          cardholder,
          bearerToken,
        },
        config
      );
      localStorage.setItem("CARD_CREATED", true);
      localStorage.setItem("CARD_ID", data.card_id);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
