import React, { useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useSelector } from "react-redux";
import Popup from "../dashboard/Popup";
import RemoveAddressConfirm from "./RemoveAddressConfirm";
import EditAddressConfirm from "./EditAddressConfirm";

const RemoveAddress = ({ _id, street, city, postal_code, country }) => {
  const [removeAddress, setRemoveAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [addID, setAddID] = useState(_id ? _id : "");
  const setStreet = useState(street ? street : "");
  const { USER_INFO } = useSelector((state) => state.user);
  const [email, setEmail] = useState(USER_INFO?.account?.email);

  const toggleRemoveAddress = () => {
    if (!addID) {
      console.log("Details of address is missing");
    } else {
      localStorage.setItem("ADDRESS_ID", addID);
      localStorage.setItem("ADDRESS_STREET", street);
      localStorage.setItem("ADDRESS_CITY", city);
      localStorage.setItem("ADDRESS_POSTAL_CODE", postal_code);
      localStorage.setItem("ADDRESS_COUNTRY", country);
      setRemoveAddress(!removeAddress);
    }
  };
  const toggleEditAddress = () => {
    if (!addID) {
      console.log("Details of address is missing");
    } else {
      localStorage.setItem("ADDRESS_ID", addID);
      localStorage.setItem("ADDRESS_STREET", street);
      localStorage.setItem("ADDRESS_CITY", city);
      localStorage.setItem("ADDRESS_POSTAL_CODE", postal_code);
      localStorage.setItem("ADDRESS_COUNTRY", country);
      setEditAddress(!editAddress);
    }
  };
  return (
    <div className="addr" key={addID} /* key id changed from _id to addID*/>
      <div className="address">
        <span>{street}</span>
        <span>{city}</span>
        <span>{postal_code}</span>
        <span>{country}</span>
      </div>

      <div className="full-flex">
        <input
          type="hidden"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email to get started"
        />
        <input
          type="hidden"
          name="addId"
          value={addID}
          onChange={(e) => setAddID(e.target.value)}
          placeholder="Enter your email to get started"
        />
      </div>

      <p className="links">
        <li>
          <span className="cursor" onClick={toggleRemoveAddress}>
            Remove
          </span>
        </li>
        <li>
          <span className="cursor" onClick={toggleEditAddress}>
            Update
          </span>
        </li>
      </p>
      <br />
      <hr />
      <br />
      {removeAddress && (
        <Popup
          content={
            <>
              <RemoveAddressConfirm />
            </>
          }
          handleClose={toggleRemoveAddress}
        />
      )}
      {editAddress && (
        <Popup
          content={
            <>
              <EditAddressConfirm />
            </>
          }
          handleClose={toggleEditAddress}
        />
      )}
    </div>
  );
};

export default RemoveAddress;
