import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { withdrawFund } from "../features/wallet/walletActions";
import { SpinnerCircular } from "spinners-react";

const RequestWithdrawal = (props) => {
  const [setCurrency] = useState("");
  const [setAmount] = useState("");
  const [setDescription] = useState("");

  const [requested_by, setRequestedBy] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const withdrawn = localStorage.getItem("WITHDRAW_STATUS");
  const { wLoading, error } = useSelector((state) => state.wallet);
  const { USER_INFO } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm({
    defaultValues: { requested_by },
  });
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("IS_LOGIN_ZPAY");
    if (!auth) {
      navigate("/login");
    }
    const uEmail = localStorage.getItem("USER_EMAIL");
    if (uEmail) {
      setRequestedBy(uEmail);
    }
  }, [navigate]);
  useEffect(() => {
    if (withdrawn) {
      const timer = setTimeout(() => {
        localStorage.removeItem("WITHDRAW_STATUS");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [withdrawn]);
  const submitForm = (data) => {
    dispatch(withdrawFund(data));
    setCurrency("");
    setAmount("");
    setDescription("");
  };
  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <i>Make fund Withdrawal</i>
          <div className="alert-warning">
            <b>Bank Name:</b>{" "}
            {USER_INFO?.account?.bank_data?.banker
              ? USER_INFO?.account?.bank_data?.banker
              : ""}
            <br />
            <b>Account Number:</b>{" "}
            {USER_INFO?.account?.bank_data?.account_number
              ? USER_INFO?.account?.bank_data?.account_number
              : ""}
            <br />
            <b>Routing Number:</b>{" "}
            {USER_INFO?.account?.bank_data?.routing_number
              ? USER_INFO?.account?.bank_data?.routing_number
              : ""}
            <br />
            <b>Swift Code:</b>{" "}
            {USER_INFO?.account?.bank_data?.swift_code
              ? USER_INFO?.account?.bank_data?.swift_code
              : ""}
            <br />
            <br />
            Amount Withdrawn would be deposited into the above bank account
          </div>

          {error && <div className="alert-warning">{error}</div>}
          {withdrawn && (
            <i className="sixteen alert-success">
              Withdrawal initiated, Kindly wait for the next cut-off time for
              approval
            </i>
          )}
          <div className="full-flex">
            <div className="half-flex">
              <select {...register("transaction_currency", { required: true })}>
                <option value="">Select currency</option>
                <option value="gbp">British Pound</option>
                <option value="eur">Euro</option>
                <option value="usd">US dollar</option>
                <option value="ngn">Nigeria Naira</option>
                <option value="zar">South Africa Rand</option>
              </select>
              <input
                type="text"
                placeholder="Amount"
                {...register("transaction_amount", { required: true })}
                onChange={(e) => setAmount(e.target.value)}
                onFocus={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="half-flex">
            <input
              type="hidden"
              placeholder="Account Owner"
              defaultValue={requested_by}
              {...register("requested_by", { required: true })}
            />
          </div>

          <input
            type="text"
            placeholder="Description"
            {...register("description", { required: true })}
            onChange={(e) => setDescription(e.target.value)}
            onFocus={(e) => setDescription(e.target.value)}
          />

          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {wLoading ? (
                <SpinnerCircular size={20} />
              ) : (
                "Make Cash Withdrawal"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestWithdrawal;
