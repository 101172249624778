import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../features/user/userActions";
// form styles
import "../styles/forms.css";
const ProfileScreen = () => {
  const [email, setEmail] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );
  const [profile_status, setProfileStatus] = useState("COMPLETED");
  //setProfileStatus("COMPLETED");
  const { loading, error, PROFILE_COMPLETED, USER_INFO } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email, profile_status } });
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("IS_LOGIN_ZPAY");
    if (!auth) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (PROFILE_COMPLETED) {
      const timer = setTimeout(() => {
        navigate("/my/settings");
      }, 3000);
      return () => clearTimeout(timer);
    }
    if (USER_INFO?.account?.profile_status === "COMPLETED") {
      const timer = setTimeout(() => {
        navigate("/my/settings");
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [navigate, PROFILE_COMPLETED, USER_INFO?.account?.profile_status]);

  const submitForm = (data) => {
    // check if email is present to ensure user is authenticated
    if (email !== "") {
      if (data.email !== email) {
        return new Error("User's Email Error");
      }
    }
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(userProfile(data));
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZPAY-ICON" />
        </div>
        <p className="divider">
          <span>Complete Your Profile</span>
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {error && (
              <h3 className="alert-warning">{error}</h3>
          )}
          {PROFILE_COMPLETED && (
              <h3 className="alert-success">Profile completed successfully</h3>
          
          )}
          <input
            type="hidden"
            placeholder="Enter Email"
            defaultValue={email}
            {...register("email", { required: true })}
          />
          {errors.email && <span>Email is required</span>}
          <input
            type="hidden"
            defaultValue={profile_status}
            {...register("profile_status", { required: true })}
          />
          {errors.profile_status && <span>Profile status is required</span>}
          <input
            type="text"
            placeholder="Enter Firstname"
            {...register("firstname", { required: true })}
          />
          {errors.firstname && <span>Firstname is required</span>}
          <input
            type="text"
            placeholder="Enter Lastname"
            {...register("lastname", { required: true })}
          />
          {errors.lastname && <span>Lastname is required</span>}
          <input
            type="text"
            placeholder="Enter Phone Number"
            {...register("phone", { required: true })}
          />
          {errors.phone && <span>Phone is required</span>}

          <input
            type="text"
            placeholder="Enter Street address"
            {...register("street", { required: true })}
          />
          {errors.street && <span>Street add is required</span>}

          <input
            type="text"
            placeholder="Enter City"
            {...register("city", { required: true })}
          />
          {errors.city && <span>City is required</span>}

          <input
            type="text"
            placeholder="Enter Country of Residence"
            {...register("country", { required: true })}
          />
          {errors.country && <span>CoR is required</span>}
          <input
            type="text"
            placeholder="Enter Postal Code"
            {...register("postal_code", { required: true })}
          />
          {errors.postal_code && <span>Postal code is required</span>}
          <button type="submit" className="r-btn" disabled={loading}>
            {loading ? "Completing..." : "Complete My Profile"}
          </button>
          <Link to="/" className="mt-10">
            Not ready? complete it later
          </Link>
        </form>
      </div>
    </section>
  );
};

export default ProfileScreen;
