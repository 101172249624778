import axios from "../../api/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const userRegister = createAsyncThunk(
  "register",
  async ({ email, password, confirmPassword }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post(
        "account/signup",
        { email, password, confirmPassword },
        config
      );
      localStorage.setItem("REG_USER_EMAIL", email);
      localStorage.setItem("REG_SUCCESS", true);
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 400) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 409) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogin = createAsyncThunk(
  "login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "account/login",
        { email, password },
        config
      );

      // store user's email in local storage
      localStorage.setItem("USER_EMAIL", data?.account?.email);
      localStorage.setItem("IS_LOGIN_ZPAY_INIT", true);
      console.log(data);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (!error?.response) {
        return rejectWithValue("No Server Response: Server may be down");
      }
      if (error?.response?.status === 401) {
        // some or more pre conditions failed;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 412) {
        // some or more pre conditions failed;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        // some or more pre conditions failed;
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userAuthLogin = createAsyncThunk(
  "auth/login",
  async ({ email, otp, ip }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "account/auth/login",
        { email, otp, ip },
        config
      );

      // store user's token in local storage
      localStorage.setItem("USER_TOKEN", JSON.stringify(data?.USER_TOKEN));

      localStorage.setItem("IS_LOGIN_ZPAY", true);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (!error?.response) {
        return rejectWithValue("No Server Response: Server may be down");
      }
      if (error?.response?.status === 401) {
        // some or more pre conditions failed;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 412) {
        // some or more pre conditions failed;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        // some or more pre conditions failed;
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const userEmailVerify = createAsyncThunk(
  "email/verify",
  async ({ accountID, zotaalCODE }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "account/email/verify",
        { accountID, zotaalCODE },
        config
      );
      // store user's token in local storage
      localStorage.setItem("EMAIL_VERIFIED", true);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        // some or more pre conditions failed;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 412) {
        // some or more pre conditions failed;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        // some or more pre conditions failed;
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const passwordResetLink = createAsyncThunk(
  "passwordreset/link",
  async ({ email }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "passwordreset/link",
        { email },
        config
      );

      localStorage.setItem("PASS_RESET_EMAIL", email);
      localStorage.setItem("PASS_RESET_LINK_SET", true);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error?.response?.status === 401) {
        //Not valid zurepay account;
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const passwordReset = createAsyncThunk(
  "password/reset",
  async (
    { accountID, passResetCODE, password, confirmPassword },
    { rejectWithValue }
  ) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "password/reset",
        { accountID, passResetCODE, password, confirmPassword },
        config
      );

      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const userProfile = createAsyncThunk(
  "profile",
  async (
    {
      email,
      firstname,
      lastname,
      phone,
      street,
      city,
      //country,
      profile_status,
      country,
      postal_code,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "account/profile",
        {
          email,
          firstname,
          lastname,
          phone,
          street,
          city,
          //country,
          profile_status,
          country,
          postal_code,
        },
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }

      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addPaymentCard = createAsyncThunk(
  "card/add",
  async (
    {
      email,
      card_number,
      card_expiry_date,
      card_cvv,
      name_on_card,
      card_issuer,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "account/card/add",
        {
          email,
          card_number,
          card_expiry_date,
          card_cvv,
          name_on_card,
          card_issuer,
        },
        config
      );
      localStorage.setItem("CARD_ADDED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removePaymentCard = createAsyncThunk(
  "card/remove",
  async ({ email, cardID }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "account/card/remove",
        {
          email,
          cardID,
        },
        config
      );
      localStorage.setItem("CARD_REMOVED", true);
      localStorage.removeItem("CARD_NUM");
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userStateUpdate = createAsyncThunk(
  "user/state",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("userstate/update", { email }, config);
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response: Server may be down");
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const addAddress = createAsyncThunk(
  "add address",
  async (
    { email, street, city, country, postal_code },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "address/add",
        {
          email,
          street,
          city,
          country,
          postal_code,
        },
        config
      );
      localStorage.setItem("USER_ADDRESS_ADDED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removeAddress = createAsyncThunk(
  "address/remove",
  async ({ email, addID }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "address/remove",
        {
          email,
          addID,
        },
        config
      );
      localStorage.setItem("USER_ADDRESS_REMOVED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editAddress = createAsyncThunk(
  "address/edit",
  async (
    { email, street, city, country, postal_code, primary, addID },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "address/edit",
        {
          email,
          street,
          city,
          country,
          postal_code,
          primary,
          addID,
        },
        config
      );
      localStorage.setItem("USER_ADDRESS_EDITED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const refreshLogin = createAsyncThunk(
  "refresh/login",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "refresh/login",
        {
          email,
        },
        config
      );
      localStorage.removeItem("USER_EMAIL");
      localStorage.removeItem("IS_LOGIN_ZPAY_INIT");
      localStorage.clear();
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const refreshPasswordResetLink = createAsyncThunk(
  "refresh/link",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "refresh/link",
        {
          email,
        },
        config
      );
      localStorage.setItem("REFRESH_PASS_LINK", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updatePhone = createAsyncThunk(
  "phone/update",
  async ({ email, phone }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "phone/update",
        {
          email,
          phone,
        },
        config
      );
      localStorage.setItem("USER_PHONE_UPDATED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const addEmail = createAsyncThunk(
  "email/add",
  async ({ email, email_secondary }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "email/add",
        {
          email,
          email_secondary,
        },
        config
      );
      localStorage.setItem("SECONDARY_EMAIL_ADDED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateEmail = createAsyncThunk(
  "email/update",
  async ({ email, email_secondary }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "email/update",
        {
          email,
          email_secondary,
        },
        config
      );
      localStorage.setItem("SECONDARY_EMAIL_UPDATED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userMessage = createAsyncThunk(
  "send/message",
  async ({ email, phone, message }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post("send/message", { email, phone, message }, config);
      localStorage.setItem("MSG_SUCCESS", true);
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }

      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addBankDetails = createAsyncThunk(
  "add bank details",
  async (
    {
      email,
      banker,
      account_name,
      account_number,
      routing_number,
      swift_code,
      country,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "bankdetails/add",
        {
          email,
          banker,
          account_name,
          account_number,
          routing_number,
          swift_code,
          country,
        },
        config
      );
      localStorage.setItem("BANK_DETAILS_ADDED", true);
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 400) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteBank = createAsyncThunk(
  "bankdetails/delete",
  async ({ email, bankID }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "bankdetails/delete",
        {
          email,
          bankID,
        },
        config
      );
      localStorage.setItem("BANK_DETAILS_DELETED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const suspendAccount = createAsyncThunk(
  "account/suspend",
  async ({ id, bearerToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerToken,
        },
      };
      const { data } = await axios.post(
        "account/suspend",
        {
          id,
          bearerToken,
        },
        config
      );
      localStorage.setItem("ACCOUNT_SUSPENDED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const unSuspendAccount = createAsyncThunk(
  "account/unsuspend",
  async ({ id, bearerToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerToken,
        },
      };
      const { data } = await axios.post(
        "account/unsuspend",
        {
          id,
          bearerToken,
        },
        config
      );
      localStorage.setItem("ACCOUNT_UNSUSPENDED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const approveIdentity = createAsyncThunk(
  "identity/approve",
  async ({ id, bearerToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerToken,
        },
      };
      const { data } = await axios.post(
        "identity/approve",
        {
          id,
          bearerToken,
        },
        config
      );
      localStorage.setItem("APPROVE_KYC_IDENTITY", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const approveResidence = createAsyncThunk(
  "residence/approve",
  async ({ id, bearerToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerToken,
        },
      };
      const { data } = await axios.post(
        "residence/approve",
        {
          id,
          bearerToken,
        },
        config
      );
      localStorage.setItem("APPROVE_KYC_RESIDENCE", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteAccountByOwner = createAsyncThunk(
  "account/deletion/owner",
  async ({ id, bearerToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerToken,
        },
      };
      const { data } = await axios.post(
        "account/delete/owner",
        {
          id,
          bearerToken,
        },
        config
      );
      localStorage.setItem("ACCOUNT_DELETION_INIT", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteAccountByAdmin = createAsyncThunk(
  "account/deletion/admin",
  async ({ id, bearerToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerToken,
        },
      };
      const { data } = await axios.post(
        "account/delete/admin",
        {
          id,
          bearerToken,
        },
        config
      );
      localStorage.setItem("ACCOUNT_DELETED", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }
      if (error?.response?.status === 401) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 403) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 500) {
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const switchAccountToMerchant = createAsyncThunk(
  "account/to/merchant",
  async (
    { email, account_type, merchant_website_url, merchant_name, bearerToken },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerToken,
        },
      };
      const { data } = await axios.post(
        "account/to/merchant",
        {
          email,
          account_type,
          merchant_website_url,
          merchant_name,
          bearerToken,
        },
        config
      );
      localStorage.setItem("ACCOUNT_TO_MERCHANT", true);
      return data;
    } catch (error) {
      if (!error?.response) {
        return rejectWithValue("No Server Response");
      }

      if (error?.response?.status === 403) {
        //Not valid Email or Mismatched password;
        return rejectWithValue(error.response.data.error);
      }
      if (error?.response?.status === 401) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }

      if (error?.response?.status === 500) {
        //return rejectWithValue("User Already taken");
        return rejectWithValue(error.response.data.error);
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
