import './fees.css';
const Fees = () => {
  return (
    <div className="pages-container">
      <h3>Zurepay Fees</h3>
      <p> Zurepay fees structure for all supported currencies</p>

      <table className="fees">
        <tr>
          <td colspan="100">
            Withdrawal from Zurepay to owner's local bank account (All fees in
            USD)
          </td>
        </tr>
        <tbody>
          <tr>
            <td className="bgb" colspan="1">
              Currencies
            </td>
            <td className="bgb" colspan="9">
              USD
            </td>
            <td className="bgb" colspan="9">
              GBP
            </td>
            <td className="bgb" colspan="9">
              EUR
            </td>
            <td className="bgb" colspan="9">
              SA Rands (ZAR)
            </td>
            <td className="bgb" colspan="9">
              Naira (NGN)
            </td>
          </tr>

          <tr>
            <td colspan="1">Min/Transaction</td>

            <td colspan="9">100.00</td>
            <td colspan="9">100.00</td>
            <td colspan="9">100.00</td>

            <td colspan="9">100.00</td>
            <td colspan="9">500.00</td>
          </tr>
          <tr>
            <td colspan="1">Max/Transaction</td>
            <td colspan="9">10,000.00</td>
            <td colspan="9">10,000.00</td>
            <td colspan="9">10,000.00</td>
            <td colspan="9">50,000.00</td>
            <td colspan="9">100,000.00</td>
          </tr>
          <tr>
            <td colspan="1">Fees (USD)</td>
            <td colspan="9">4.9% + 7.25</td>
            <td colspan="9">4.9% + 4.99</td>
            <td colspan="9">4.89% + 4.98</td>
            <td colspan="9">5.1% + 14.56</td>
            <td colspan="9">5.03% + 5.27</td>
          </tr>
          <tr>
            <td colspan="1">Max Fees (Bank Withdrawal)</td>
            <td colspan="9">N/A</td>
            <td colspan="9">N/A</td>
            <td colspan="9">N/A</td>
            <td colspan="9">N/A</td>
            <td colspan="9">N/A</td>
          </tr>
        </tbody>

        <tr>
          <td colspan="100">
            Intra transfer (Sending fund to another Zurepay accounts by email,
            payme link or payme on my website)
          </td>
        </tr>
        <tbody>
          <tr>
            <td colspan="1">Currencies</td>
            <td colspan="9">USD</td>
            <td colspan="9">GBP</td>
            <td colspan="9">EUR</td>
            <td colspan="9">SA Rands (ZAR)</td>
            <td colspan="9">Naira (NGN)</td>
          </tr>

          <tr>
            <td colspan="1">Min/Transaction</td>
            <td colspan="9">10.00</td>
            <td colspan="9">10.00</td>
            <td colspan="9">10.00</td>
            <td colspan="9">100.00</td>
            <td colspan="9">500.00</td>
          </tr>
          <tr>
            <td colspan="1">Max/Transaction</td>
            <td colspan="9">10,000.00</td>
            <td colspan="9">10,000.00</td>
            <td colspan="9">10,000.00</td>
            <td colspan="9">15,000.00</td>
            <td colspan="9">100,000.00</td>
          </tr>
          <tr>
            <td colspan="1">Fees</td>
            <td colspan="9">1.5% + 1.5</td>
            <td colspan="9">1.5% + 1.5</td>
            <td colspan="9">1.5% + 1.5</td>
            <td colspan="9">1.5% + 5</td>
            <td colspan="9">1.5% + 20</td>
          </tr>
          <tr>
            <td colspan="1">Max Fees (Intra transfer)</td>
            <td colspan="9">350.00</td>
            <td colspan="9">350.00</td>
            <td colspan="9">350.00</td>
            <td colspan="9">2,000.00</td>
            <td colspan="9">10,000.00</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Fees;
