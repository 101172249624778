const PayoutAgreement = () => {
  return (
    <div className="pages-container">
      <h1> Payout Agreement </h1>
      <h6>Last Updated on 10 May 2023</h6>
      <p className="parag">
        ZurePay Payouts Agreement May 1 10, 2023 This agreement governs your use
        of ZurePay Payouts (formerly Mass Pay). Your use of ZurePay Payouts is
        also subject to the terms of the ZurePay User Agreement, the Privacy
        Statement and other applicable legal agreements between you and ZurePay.
        <br />
        <br />
        You can use ZurePay Payouts service to send payouts to multiple
        recipients in one batch, such as commissions, rebates, rewards,
        incentives, and bulk business payments. You may be able to choose to
        send payouts to ZurePay accounts. In order to use this service, you must
        have:
        <br />
        <br />
        a business ZurePay account in good standing and with no withdrawal
        limits; and applied for and received our permission to use these
        services. Fees for ZurePay Payouts can be found in the Fee page.
        <br />
        <br />
        If an intended payout recipient does not have a ZurePay account, as
        applicable, they can claim the payout by opening one. Any payouts that
        are not claimed by the intended recipient within thirty (30) days will
        be refunded to you. We are not liable for amounts sent in error through
        ZurePay Payouts, and we will have no responsibility to reverse any
        payouts.
        <br />
        <br />
        ZurePay Payouts is built for customers with multiple, bulk, and
        recurring payment needs. If you wish to make one-off payments, please
        use the ZurePay services intended for that purpose. ZurePay Payouts may
        not be used for payroll, selling products, sending money to family and
        friends, paying a merchant for a purchase, or purchasing unlock codes,
        or for any use that we determine presents a higher than acceptable level
        of risk. We may add further restrictions at any time in our sole
        discretion.
        <br />
        <br />
        You are solely responsible for ensuring that each of your payout
        recipients has opted in to receive payments from you through the
        applicable services and has agreed to be contacted by email or text
        message, as applicable. By using the ZurePay Payouts service, you
        represent and warrant that the emails or phone numbers you use to
        contact your customers were provided to you by each payout recipient,
        and that your use of the services to contact your customers complies
        with applicable laws and regulations. You understand that ZurePay
        Payouts is only intended to facilitate bulk payments and transactions
        and may not be used to send marketing messages for your business.
        <br />
        <br />
        Without limiting our other rights and remedies under this agreement, we
        may, in our sole discretion, at any time suspend or terminate your
        access to the ZurePay Payouts service or your ZurePay account, if
        <li>
          (i) we determine that you are misusing the ZurePay Payouts service,{" "}
        </li>
        <li>
          (ii) there is a higher than acceptable level of risk associated with
          your use of the ZurePay Payouts service, or
        </li>
        <li> (iii) for any violation of these terms.</li>
      </p>
    </div>
  );
};

export default PayoutAgreement;
