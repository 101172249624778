import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { bURL } from "../api/axios.js";
export default function Webhook() {
  const [searchParams] = useSearchParams();
  const payment_intent = searchParams.get("payment_intent");
  const [hook, setHook] = useState(true);
  const [done, setDone] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${bURL}stripe-webhook`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ payment_intent }),
    }).then(async (result) => {
      let { hook } = await result.json();
      setHook(hook);
      setDone(true);
    });
  }, [payment_intent]);

  // redirect authenticated user to authorized screen
  useEffect(() => {
    if (done) {
      const timer = setTimeout(() => {
        setDone(false);
        navigate("/my/dashboard");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [navigate, done]);

  return (
    <section className="wallet">
      <div className="container">
        <div className="dash-left dash-left-bg">
          <div className="medium-card">
            <h1>
              {hook
                ? "We are finalizing funding your wallet, please wait and do not close your browser..."
                : "Wallet funding confirmed. Thank you for using zurepay!! 🎉"}
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}
