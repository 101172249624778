const UsersAgreement = () => {
  return (
    <div className="pages-container">
      <h3> ZUREPAY USER AGREEMENT</h3>
      <br />
      <h6>
        This user agreement will be effective for all users as of December 15,
        2022.
      </h6>
      <br />
      <h3>About Your Account</h3>

      <p className="parag">
        Welcome to ZurePay! This user agreement is a contract between{" "}
        <b>
          you and DE LINCOM HITECH LLC, Doing Business As zurepay.com a United
          States company
        </b>
        , governing your use of your ZurePay account and the ZurePay services.
        If you are an individual, you must be a resident of one of the
        countries/regions listed on the ZurePay Worldwide page and at least 18
        years old, or the age of majority in your country/region of residence to
        open a ZurePay account and use the ZurePay services. <br />
        <br />
        If you are a business, the business must be organized in, operating in,
        or a resident of one of the countries/regions listed on the ZurePay
        Worldwide page to open a ZurePay account and use the ZurePay services.
        By opening and using a ZurePay account, you agree to comply with all of
        the terms and conditions in this user agreement. You also agree to
        comply with the following additional policies and each of the other
        agreements on the Legal Agreements page that apply to you, including
        <li>Acceptable Use Policy</li>
        <li>Alternative Payment Methods Terms</li>
      </p>
      <p className="parag">
        Please read carefully all of the terms and conditions of this user
        agreement, the terms of these policies and each of the other agreements
        that apply to you. We may amend this user agreement and any of the
        policies listed above from time to time.
        <br />
        <br /> The revised version will be effective at the time we post it on
        our website, unless otherwise noted. If our changes reduce your rights
        or increase your responsibilities we will post a notice on the Policy
        Updates page of our website and provide you with at least 14 days
        notice. <br />
        <br />
        All future changes set out in the Policy Updates page already published
        on the Legal Agreements page at the time you register for the ZurePay
        services are incorporated by reference into this user agreement and will
        take effect as specified in the Policy Update. By continuing to use our
        services after any changes to this user agreement or any of the other
        applicable terms, agreements, or policies listed above, you agree to
        abide and be bound by those changes. <br />
        <br />
        If you do not agree to the revised terms and conditions, you must stop
        using the ZurePay services, close your account and terminate your
        relationship with us without cost or penalty. This user agreement will
        continue to apply to your previous use of our services.
      </p>

      <p className="parag">
        About our main business ZurePay is a payment services provider and acts
        as such by creating, hosting, maintaining and providing our ZurePay
        services to you via the internet. Our services allow you to send
        payments to anyone with a ZurePay account, and, where available, to
        receive payments.
        <br />
        <br /> Our service availability varies by country/region. You can see
        what services are available in your country/region by logging into your
        ZurePay account. The ZurePay services are offered by ZurePay Bytes
        Consults. This user agreement is not a solicitation of the ZurePay
        services and ZurePay is not targeting any country/region or market
        through this user agreement.
        <br />
        <br /> Opening an Account We offer two types of accounts: ZurePay
        accounts personal (or "personal accounts") and ZurePay accounts business
        (or "business accounts").
        <br />
        <br />
        <b>All ZurePay accounts let you do things like:</b>
        <li>Send and receive payments.</li>
        <li>Buy things online, using mobile devices or in stores.</li>
        <li>
          Make payments using your credit card, debit card, bank account, the
          balance in your ZurePay account, eCheque or other payment methods.
        </li>
        <li>
          Accept credit card, debit card, bank account, ZurePay balance, eCheque
          or other payment methods from others.
        </li>
      </p>
      <p className="parag">
        You are responsible for maintaining adequate security and control of any
        and all IDs, passwords, personal identification numbers, or any other
        codes that you use to access your ZurePay account and the ZurePay
        services. In order to open and maintain a ZurePay account, you must list
        your correct country/region of residence and provide us with correct and
        updated account information, including but not limited to personal
        information, financial information, or other information related to you
        or your business. You must keep your mailing address, email address and
        other contact information current in your ZurePay account profile.
        Personal accounts If you primarily need to make purchases and send
        personal transactions to family and friends, a personal account is
        probably right for you. <br />
        <br /> <b>With a personal account you can do things like:</b>
        <li>Buy goods and services.</li>
        <li>
          Send personal transactions to and request personal transactions from
          friends and family.
        </li>
        You can also use a personal account to receive payments for the sale of
        goods and services, but if you plan to use your personal account
        primarily to sell things, you should consider a business account. You
        can convert your ZurePay account from a personal account to a business
        account should circumstances change.
      </p>
      <p className="parag">
        <h1>Business accounts</h1>
        We recommend business accounts for people and organizations that
        primarily use ZurePay to sell goods or services, even if your business
        is not incorporated. With a business account, you can do things like:
        <li>
          Use a company or business name as the name on your business account.
        </li>
        <li>
          Allow employees access to some of the features of your business
          account.
        </li>
        <li>Sign up for ZurePay products that meet your business needs.</li>
      </p>
      <p className="parag">
        Business accounts may be subject to fees that differ from the fees
        applicable to personal accounts. Business accounts are not eligible to
        receive “personal transactions,” although they may send personal
        transactions to friends and family with an eligible ZurePay account.
        <br />
        <br /> By opening up a business account or converting a personal account
        to a business account, you certify to us that you are using it primarily
        for a business or commercial purpose. <br />
        <br />
        You also consent to ZurePay obtaining your personal and/or business
        credit report from a credit reporting agency at account opening, when
        you request certain new products and whenever we reasonably believe
        there may be an increased level of risk associated with your business
        account.
        <br />
        <br />
        <b>Commercial Entity Agreement</b>
        <br /> If the activity through any type of ZurePay account you hold
        reaches certain thresholds or involves certain business segments or
        activities, you are required by the card networks to agree to a
        Commercial Entity Agreement to allow you to continue accepting Visa and
        MasterCard payments. In this case, these Commercial Entity Agreements
        will apply to any payment processed by ZurePay on your behalf and will
        form part of this user agreement.
        <br />
        <br /> <b>Third Party permissions</b>
        <br /> You must be the beneficial owner of your ZurePay account, and
        conduct business only on behalf of yourself. You may expressly grant,
        remove and manage permissions for some third parties to take certain
        actions on your behalf. <br />
        <br />
        In some cases, you can do this when logged into your ZurePay account –
        in other cases, you can do this directly with the third party. <br />
        <br />
        You acknowledge that if you grant permission for a third party to take
        actions on your behalf, we may disclose certain information about your
        ZurePay account to this third party. <br />
        <b>
          You may permit third party service providers licensed by applicable
          law to:
        </b>
        <li>
          provide account information services to access information about your
          account on your behalf;
        </li>
        <li>
          confirm whether an amount necessary for the execution of a card-based
          payment transaction is available on your account; or
        </li>
        <li>
          provide payment initiation services to initiate payments from your
          account on your behalf.
        </li>
        Granting permission to any third party to access your ZurePay account in
        any way does not relieve you of any of your responsibilities under this
        user agreement. You are liable to us for the actions that you authorize
        the third parties to carry out. You will not hold us responsible for,
        and you will indemnify us from, any liability arising from the actions
        or inactions of such third parties in connection with the permissions
        you granted, subject to your mandatory legal rights.
      </p>
      <p className="parag">
        <h3>Closing Your ZurePay Account</h3> You may close your account and
        terminate your relationship with us at any time without cost or penalty,
        but you will remain liable for all obligations related to your ZurePay
        account even after the ZurePay account is closed. When you close your
        ZurePay account, we will cancel any scheduled or incomplete
        transactions.
        <br />
        <br /> You must withdraw or transfer any balance from your ZurePay
        account before closing it. You cannot withdraw or transfer digital gift
        certificates/cards that are purchased through ZurePay Digital Gifts and
        linked to your ZurePay account as payment methods. However, even without
        your ZurePay account, you can still use the codes you received by email
        when you purchased the gift certificates/cards to make purchases. In
        certain cases, you may not close your ZurePay account, including:
        <li>To evade an investigation.</li>
        <li>If you have a pending transaction or an open dispute or claim.</li>
        <li>If your ZurePay account has a negative balance.</li>
        <li>
          If your ZurePay account is subject to a hold, limitation or reserve.
        </li>
      </p>
      <p className="parag">
        <h3>Link or Unlink a Payment Method</h3>
        You can link or unlink a credit card, debit card or a bank account to
        your ZurePay account as a payment method. Please keep your payment
        method information current (i.e. credit card number and expiration
        date). If this information changes, we may update it using information
        and third party sources available to us without any action on your part.
        If you do not want us to update your card information, you may remove
        your payment method from your ZurePay account. If we update your payment
        method, we will keep any preference setting attached to such payment
        method. You may choose to confirm your card, so that we can verify that
        the card is valid and that you are the card owner.
        <br />
        <br /> Holding a ZurePay Balance Any balance you hold in your ZurePay
        account represents an unsecured claim against ZurePay. ZurePay combines
        your balance with the balances of other users and invests those funds in
        liquid investments. These pooled amounts are held separate from
        ZurePay's corporate funds, and ZurePay will neither use these funds for
        its operating expenses or any other corporate purposes nor will it
        voluntarily make these funds available to its creditors in the event of
        bankruptcy. You will not receive interest or other earnings on the
        amounts in your balance. You agree ZurePay shall own the interest or
        other earnings on these investments. You agree to assign any rights to
        any interest derived from your funds to ZurePay. <br />
        <br />
        Adding or Withdrawing Funds <br />
        <br />
        Adding funds to your balance
        <br />
        <br /> You may use the payment methods linked to your ZurePay account to
        fund transactions you make using your ZurePay account. You don't need a
        balance in your ZurePay account to buy something or send payments. You
        can add funds your ZurePay account from the bank account that's linked
        to your ZurePay account by requesting an electronic transfer to your
        ZurePay account.
        <br />
        <br /> The amount transferred will be held as a balance in your ZurePay
        account. Credit cards cannot be used to add funds to your ZurePay
        balance. Withdrawing funds from your balance If you have a ZurePay
        balance, log into your ZurePay account to see which of these withdrawal
        options are available in your country/region:
        <li>
          transferring it to a bank account linked to your ZurePay account; or
        </li>
        <li>
          transferring it to your debit or credit card, where available; or
        </li>
        <li>requesting a physical cheque through the mail, where available.</li>
        Depending on the country/region in which your account is registered, you
        may be able to withdraw your funds through a third party service
        provider. Please see terms of such third party for information regarding
        currency conversions.
        <br />
        <br /> To protect us and our users from loss, we may delay a withdrawal
        in certain situations, including if we need to confirm that you have
        authorized the withdrawal or if other payments to your ZurePay account
        have been subject to a reversal (for example, as a result of a
        chargeback, bank reversal or dispute by a buyer). <br />
        <br />
        If we place a limitation on your ZurePay account, a payment is subject
        to a hold, or your account or an associated account has a negative
        balance in any currency while a withdrawal from your ZurePay account is
        pending, you will have to reinitiate the withdrawal once the limitation
        or hold has been lifted, or the negative balance is fully paid off.
        <br />
        <br />
        We may set limits on your withdrawals, and you can view any withdrawal
        limit by logging into your ZurePay account. Completing the following
        steps can help us verify your ZurePay account, which may allow us to
        remove any withdrawal cap:
        <li>Verifying your bank account; and</li>
        <li>
          Linking and confirming your credit card or debit card information.
        </li>
        <br />
        We may charge a fee to make a transfer to your bank account. If your
        debit card is eligible to receive withdrawals from your ZurePay account,
        you will be provided the option to use it when you transfer funds out of
        your ZurePay balance, subject to the fees applicable to such transfers
        that can be found on the Fees for Transferring Balance (for personal
        accounts) page and the Fees for Transferring Balance (for business
        accounts) page. The applicable fees will be disclosed to you in advance
        each time you initiate such a withdrawal. <br />
        <br />
        Generally, we will send physical cheques through the mail only to
        confirmed addresses, unless we have verified your ZurePay account. We
        will not send cheques to post office boxes. If you would like us to send
        a cheque to an address that does not meet these criteria, you must
        contact customer service and provide the documentation that we request
        to verify your association with the address. If you fail to cash a
        cheque within 180 Days of the date of issuance, we will return the funds
        to your balance (minus a fee).
        <br />
        <br />
        Managing Your Balance in Multiple Currencies
        <br />
        Holding multiple currencies
        <br />
        Your balance of your ZurePay account may be held in any of the
        currencies supported by ZurePay, and you may hold a balance in more than
        one of these currencies at the same time.
        <br /> <br />
        <h3>If you hold a balance in your ZurePay account:</h3>
        <li>
          We may allow you to convert the balance into another currency. If you
          convert the balance in your account, ZurePay's transaction exchange
          rate (including our currency conversion fee) will be used. We may, at
          our discretion, impose limits on the amount of your balance that you
          can convert or the number of conversions you can perform.
        </li>
        <li>
          You may only withdraw the balance in the opening currency of your
          account or such other currency that ZurePay supports for withdrawal in
          your registered country/region. In order to withdraw the balance in
          your account which is held in another currency you will have to
          convert the currency to the opening currency of your account, or it
          will be converted for you at the time of your withdrawal. ZurePay's
          transaction exchange rate, including our currency conversion fee, will
          be used.
        </li>
        <br />
        <br />
        To receive funds in a currency that your account is not currently
        configured to accept, it may be necessary to create a balance in your
        ZurePay account in that currency or convert the balance into another
        currency. Certain currencies can only be received by converting the
        balance into another currency that ZurePay allows you to hold. If the
        balance is converted, ZurePay's transaction exchange rate (including our
        currency conversion fee) will be used.
        <br />
        <br />
        You are responsible for all risks associated with maintaining multiple
        currencies in your ZurePay account. You may not manage or convert
        currencies for speculative trading purposes, conversion arbitrage,
        conversion options, or any other activity that ZurePay determines is
        primarily for the purpose of gaining or making money based on currency
        conversion rates. ZurePay may hold, cancel, or reverse any transaction
        we determine to violate this policy.
      </p>
      <p className="parag">
        <h3>How we convert currency</h3>
        If ZurePay converts currency, it will be completed at the transaction
        exchange rate we set for the relevant currency exchange. The transaction
        exchange rate is adjusted regularly and includes a currency conversion
        fee applied and retained by us on a base exchange rate to form the rate
        applicable to your conversion. <br />
        <br />
        The base exchange rate is based on rates within the wholesale currency
        markets on the conversion day or the prior Business Day; or, if required
        by law or regulation, set at the relevant government reference rate(s).
        For some uses of your ZurePay accounts, ZurePay may determine currency
        conversion is necessary. The currency conversion fee applicable can be
        found on our fees page under the heading currency conversion fees.
        <br />
        <br />
        <h3>Currency conversion choices</h3>
        When your payment is funded by a debit or credit card and involves a
        currency conversion by ZurePay, you consent to and authorize us to
        convert the currency in place of your debit or credit card issuer. You
        may have the right to have your card issuer perform the currency
        conversion, if applicable for that card issuer and network. <br />
        <br /> This currency preference selection may be presented to you in
        various forms, including setting the currency of your card, a choice of
        which currency is used for the transaction, whether ZurePay or your card
        issuer performs the conversion, or which conversion rate is used for the
        transaction, among others. If your card issuer converts the currency,
        your card issuer will determine the currency conversion rate and the
        fees they may charge. ZurePay will always perform the conversion for
        transactions where you use your existing balance in your ZurePay account
        or your linked bank account is the payment method.
      </p>
      <p className="parag">
        <h3>Account Statements</h3>
        You have the right to receive an account statement showing your ZurePay
        account activity. You may view your ZurePay account statement by logging
        into your ZurePay account.
        <br />
        Sending payments and buying
        <br />
        Sending payments to or Receiving payments from a Friend or Family Member
        <br />
        <br />
        <h3>Sending payments</h3>
        In some countries/regions, you can send funds to a friend or family
        member from your ZurePay account using the send payments feature in your
        ZurePay account. <br />
        <br />
        You can see which services are available in your country/region by
        logging into your ZurePay account. <br />
        <br />
        You can send funds to a friend or family member even if they don't have
        an eligible ZurePay account at the time you send them funds, using their
        email address or mobile number, in any currency that ZurePay supports,
        and you can choose which payment method you want to use. If the person
        to whom you are sending funds does not have a ZurePay account, they can
        claim it by opening a ZurePay account. If they don't claim it, it will
        be refunded to you. Receiving payments from a friend or family member is
        described under Receiving payments. <br />
        <br />
        We may, at our discretion, impose limits on the amount of funds you can
        send, including funds you send for purchases. You can view any sending
        limit by logging in to your ZurePay account. We may increase your
        sending limits if you complete the same steps to verify your information
        as is required for the removal of withdrawal limits. <br />
        <br />
        When you send funds to a friend or family member, one of three things
        may happen: they may accept, decline or fail to claim the funds. If they
        either decline to accept the funds or don't claim them within 30 days of
        the date they are sent, the funds (including any fees you were charged)
        will be refunded to:
        <li>
          The original payment method you used for the transaction, if you used
          a credit card, debit card or ZurePay Credit as the payment method, or
        </li>
        <li>
          The balance in your ZurePay account, if you used your ZurePay account
          balance as the payment method or a bank account as the payment method
          and we cannot refund it to your bank account.
        </li>
      </p>
      <p className="parag">
        <h3>Receiving payments</h3>
        If a friend or family member sends funds to you, the funds will appear
        in the balance of your ZurePay account. To receive funds in a currency
        your account is not currently configured to accept, it may be necessary
        to create a balance in that currency or convert the funds into another
        currency. Certain currencies can only be received by converting the
        balance into another currency that ZurePay allows you to hold. If the
        balance is converted, ZurePay's transaction exchange rate (including our
        currency conversion fee) will be used.
        <h3>Fees for Sending payments to Friends and Family</h3>
        The fees applicable to sending personal transactions can be found in our
        Sending funds table and will be disclosed to you in advance each time
        you initiate sending payments to a friend or family member. If you
        convert the balance in your ZurePay account from one currency to
        another, ZurePay's transaction exchange rate (including our currency
        conversion fee) will be used. <br />
        <br />
        If you send payments to a friend or family member from a third party
        (non-ZurePay) website or by using a third party's product or service,
        then the third party will determine if the sender or recipient pays the
        fee. This will be disclosed to you by the third party before the payment
        is initiated. <br />
        <br />
        You must not send payments as a personal transaction (often referred to
        as use using the “send payments to a friend or family member” feature in
        your ZurePay account) when you are paying for goods or services.
        <br />
        <br /> Note that the “send payments to a friend or family member”
        feature may not be available when sending money to a business account as
        such accounts are not eligible to receive personal transactions.{" "}
        <h3>
          Buying Something From, Donating to, or Returning Something to, a
          Seller Who Accepts ZurePay
        </h3>
        <h3>How to buy something or make a donation</h3>
        You can buy something from a seller who accepts ZurePay or make a
        donation in any currency that the recipient accepts, and that ZurePay
        supports, using the balance in your ZurePay account, or using any
        payment method linked to your ZurePay account. This includes, for
        example:
        <li>
          Buying something at an online retailer's website and selecting ZurePay
          as your payment method at checkout.
        </li>
        <li>Sending a payment to a seller for good or services.</li>
        <li>
          Using your ZurePay account to buy something at a seller's physical
          store.
        </li>
        <li>Making donations using ZurePay.</li>
        <br />
        <br />
        If the seller you are buying from sells goods or services and that
        seller does not already have a ZurePay account, they can claim your
        payment by opening a ZurePay account. If they don't open a ZurePay
        account within 30 days, your purchase will be refunded. In order to
        manage risk, ZurePay may limit the payment methods available for a
        transaction when you buy something or make a donation. In addition,
        payment methods may be limited for certain sellers or recipients,
        including ZurePay payments made through certain third-party websites or
        applications, for example:
        <li>
          American Express may not be available as a payment method for certain
          merchants, such as branded airlines and certain travel merchants;
        </li>
        <li>
          Some credit cards may not be available as a payment method for certain
          merchants, such as those in the gambling industry; and
        </li>
        <li>
          Credit cards cannot be used to send personal payments or to top up the
          balance in your ZurePay account.
        </li>
        <br />
        <br />
        When you authorize a payment to a seller who accepts ZurePay, some
        sellers may take up to 30 days to complete the transaction. In these
        instances, your payment may appear as a pending order in your ZurePay
        account. In that case, your authorization of the payment will remain
        valid until the seller completes the transaction (but no longer than 30
        days).
        <br />
        <br />
        If you used a debit or credit card as the payment method, your debit or
        credit card issuer also may show a pending authorization for a period of
        time until they release the hold or receive a completed transaction.
        <br />
        <br />
        If your payment requires a currency conversion by us, the transaction
        exchange rate will be determined and applied as described in the How we
        convert currency section and will be determined at the time the payment
        is processed.
      </p>
      <p className="parag">
        <h3>Fees</h3>
        When you buy something from a seller who accepts ZurePay or make a
        donation, you don't pay a fee to ZurePay. If ZurePay performs a currency
        conversion for your purchase or donation, ZurePay's transaction exchange
        rate (including our currency conversion fee) will be used. <br />
        <br />
        <b>
          Your credit card or debit card issuer may also charge you a separate
          fee for transactions.
        </b>
        <br />
        <br />
        <h3> Payment review</h3> When ZurePay identifies a potentially high-risk
        transaction, we review the transaction more closely before allowing it
        to proceed. When this happens, ZurePay will place a hold on the
        transaction and notify the seller to delay shipping of the item. As a
        buyer, this may delay your receipt of the item you purchased. If we
        clear the transaction, we will notify the seller and direct them to ship
        the item. If we don't clear the transaction, we will cancel it and
        return the funds to you, unless we are legally required to take other
        action.
      </p>
      <p className="parag">
        <h3>Automatic payments</h3>
        You can agree with a seller who accepts ZurePay to use ZurePay as the
        payment method for future purchases with that seller. This agreement is
        between you and the seller and allows the seller to take funds from your
        ZurePay account with your authorization on a one-time, regular or
        sporadic basis.
        <br />
        <br /> Examples of automatic payments that can be arranged by you either
        with a seller or with ZurePay include those that ZurePay calls a
        "billing agreement," "subscription," "recurring payment," "reference
        transaction," "preauthorized debit or PAD", "preauthorized transfer" or
        "preapproved payment." <br />
        <br />
        You may cancel an automatic payment up to 3 Business Days before the
        date of the next scheduled payment from your account settings or by
        contacting us through the ZurePay Help Centre. Once an automatic payment
        is canceled, all future automatic payments under your agreement with
        that seller will be stopped. <br />
        <br />
        If you cancel an automatic payment, you may still owe the seller funds
        for the purchase or have additional obligations to the seller for any
        goods or services that you receive but have not paid for and you may be
        required to pay the seller through alternative means. <br />
        <br />
        If you have given advance authorization, either to a seller or to
        ZurePay, that permits a seller to take or receive payments from your
        ZurePay account on a regularly recurring basis (for example, every month
        or otherwise on a routine billing cycle), and if such payments will vary
        in amount, you have the right to advance notice of the amount and date
        of the transfer from the seller at least 10 days before the transfer is
        made. <br />
        <br />
        If the seller provides the option, you may choose to receive this
        advance notice only when the amount of your automatic payment will fall
        outside a range established between you and the seller.
        <br />
        <br /> If you have authorized an automatic payment and ZurePay performs
        a currency conversion for an automatic payment transaction, ZurePay will
        use the transaction exchange rate (including ZurePay's currency
        conversion fee) in effect at the time the automatic payment transaction
        is processed.
      </p>
      <p className="parag">
        <h3>Refunds</h3>
        When you buy something from a seller online using ZurePay and the
        transaction is ultimately refunded, the payment will typically be
        refunded to the original payment method you used for the transaction if
        you used a credit card, debit card or a balance in your ZurePay account.
        If you used a bank account as the payment method for the transaction, we
        will refund the payment to your bank account, or to the balance in your
        ZurePay account if we cannot refund it to your bank account. For
        purchases you make in a seller's store location that you paid for using
        your ZurePay account and the transaction is ultimately refunded, the
        payment will be refunded to the balance in your ZurePay account.
        <br />
        <br />
        <b>
          If ZurePay performed a currency conversion for your transaction and a
          refund is issued:
        </b>
        <li>
          within 1 day of the date of the original payment, the transaction
          exchange rate (including our currency conversion fee) used at the time
          of the original payment will apply.
        </li>
        <li>
          beyond 1 day of the date of the original payment, ZurePay's
          transaction exchange rate (including our currency conversion fee) on
          the date of the refund will apply. The transaction exchange rate may
          be applied immediately and without notice to you. This means that you
          may not receive the full amount of your original payment due to the
          fees above and fluctuations in currency conversion rates.
        </li>
        Payments will be refunded in the currency you paid, or if we are unable
        to refund in the currency you paid, in your primary holding currency.
        <h3>Payment Method Used for My Transaction</h3>
        <br />
        <b>Selecting a preferred payment method</b>
        <br />
        In certain countries, you can choose any of the payment methods in your
        ZurePay account as your preferred payment method. You can select a
        preferred payment method in your account preferences. There may be times
        when your preferred payment method cannot be used, for example, if you
        select a credit card that is expired.
        <br />
        <br /> You can set separate preferred payment methods for online
        transactions, in-store transactions and automatic payments with a
        seller. If you have chosen a preferred payment method, it will be shown
        as the primary method of payment. The availability of certain payment
        methods may be limited based on that particular seller or the third
        party website you are using to complete the transaction. <br />
        <br />
        If you have not selected a preferred payment method, or your preferred
        payment method is unavailable, we will show you available payment
        methods, including the payment method you used most often or most
        recently, at the time of transaction. You can also click on the "Manage"
        link to see all of your available payment methods, or add a new one, and
        select a payment method during the transaction.
        <h3>Backup payment method</h3>
        Certain one-time online transactions may require that a backup funding
        method be used in the event that your selected or preferred payment
        method is unavailable. In those instances, the backup funding method may
        be displayed to you on your transaction review page, before you complete
        the transaction. <br />
        <br /> Note that this only applies for one-time, online transactions,
        and not for automatic payments. If ZurePay determines currency
        conversion is necessary for a transaction that also requires a backup
        payment method, you may not be able to separately choose whether ZurePay
        or your card issuer performs the currency conversion on your backup
        payment method.
        <h3>Sending payments to friends and family</h3>
        When you send personal transactions to friends and family using the
        balance in your ZurePay account (if available) or your bank account, we
        waive all fees, so we always show you these payment options first, even
        if you have a set preferred payment method for your online purchases.
        Remember, you always have the choice to select any payment method in
        your ZurePay account by clicking the "Change" link on the Send Payments
        page. If you select a payment method with a fee, we will always show you
        the fee before you send the payment.
        <h3>Automatic payments</h3>
        Some sellers allow you to store ZurePay as the way to pay when making
        purchases on their site, so you can check out faster. Often, this
        entails creating an agreement with the seller that permits them to
        request that we charge your ZurePay account each time you make a
        purchase.
        <br />
        <br /> You can select a payment method for future purchases with a
        particular seller either at the time of creating the agreement or in
        your account settings.
        <br />
        <br /> For example, you can instruct your monthly movie subscription
        service to always charge your credit card for the monthly cost. If your
        chosen payment method is unavailable (e.g. credit card expired), a
        particular agreement with a seller does not provide for the ability to
        specify a payment method, or if you have not designated a payment method
        for future transactions with a seller, the payment method used will be
        in the following order, as applicable: <br />
        <br /> 1. balance;
        <br /> 2. bank account (instant transfer);
        <br /> 3. ZurePay co-branded debit card;
        <br /> 4. ZurePay co-branded credit card;
        <br /> 5. debit card;
        <br /> 6. credit card; and
        <br /> 7. eCheque. You can cancel any agreement in your account
        settings.
        <h3>Bank account transfers</h3>
        When you use your bank account as a payment method, you are allowing
        ZurePay to initiate a transfer from your bank account to the recipient.
        For these transactions, ZurePay will make electronic transfers from your
        bank account in the amount you specify. You authorize ZurePay to try
        this transfer again if the initial transfer is rejected by your bank for
        any reason.
        <h3>eCheque</h3>
        eCheque is a payment method where you use your bank account as your
        payment method, and the payment is not received by the recipient until
        the transaction is processed from the bank. This process usually takes
        3-5 Business Days, but this amount of time will increase if the payment
        is sent from a bank account located outside of your country/region. If
        eCheque is your preferred payment method, it will be used to fund your
        ZurePay payment, even if you have a balance in your ZurePay account.
        <h3>ZurePay’s Buyer Protection Program</h3>
        <br />
        <b>ZurePay's Buyer Protection</b>
        <br />
        When you buy something from a seller who accepts ZurePay, you may be
        eligible for a refund under ZurePay's Buyer Protection program. When
        applicable, ZurePay's Buyer Protection program entitles you to
        reimbursement for the full purchase price of the item plus the original
        shipping costs you paid, if any. <br />
        <br /> ZurePay determines, in its sole discretion, whether your claim is
        eligible for ZurePay’s Buyer Protection program. ZurePay's original
        determination is considered final, but you may be able to file an appeal
        of the decision with ZurePay if you have new or compelling information
        not available at the time of the original determination or you believe
        there was an error in the decision-making process.
        <br />
        <br />
        The full program terms and conditions are set out in ZurePay’s Buyer
        Protection program page and form part of this user agreement.
        <h3>Selling and Accepting Payments</h3>
        <b>Accepting Payments</b>
        <br />
        <b>Receiving payments</b>
        <br />
        The ability to receive payments varies by country/region. You can
        determine whether you have the ability to receive payments by logging
        into your ZurePay account. If you use your ZurePay account to receive
        payments for the sale of goods or services or to receive donations, you
        must:
        <li>Pay any applicable fees for receiving the payments.</li>
        <li>
          Not ask your buyer to send you a payment as a personal transaction
          (often referred to as using the “send payments to a friend or family
          member” feature).
        </li>
        <br />
        <br />
        If you do so, ZurePay may remove your ZurePay account's ability to
        accept payments from friends or family members. By integrating into your
        online checkout/platform any functionality intended to enable a payer
        without a ZurePay account to send a payment to your ZurePay account, you
        agree to all further terms of use of that functionality which ZurePay
        will make available to you on any page on the ZurePay or Braintree
        website (including any page for developers and our Legal Agreements
        page) or online platform. Such further terms include the ZurePay
        Alternative Payment Methods Terms.
        <h3>No surcharges</h3>
        You agree that you will not impose a surcharge or any other fee for
        accepting ZurePay as a payment method. You may charge a handling fee in
        connection with the sale of goods or services as long as the handling
        fee does not operate as a surcharge and is not higher than the handling
        fee you charge for non-ZurePay transactions.
        <h3>Presentation of ZurePay</h3>
        You must treat ZurePay payment methods or marks at least on par with any
        other payment methods offered at your points of sale, wherever ZurePay’s
        branded services are integrated, including your websites or mobile
        applications. This includes at least equal or better: logo placement,
        position within any point of sale, and treatment in terms of payment
        flow, terms, conditions, restrictions, and fees, in each case as
        compared to other marks and payment methods at your points of sale.
        Further, you must not present any payment method or mark upstream (or at
        an earlier point in the checkout experience) from the presentment of any
        of ZurePay’s services or marks. <br />
        <br />
        In representations to your customers or in public communications, you
        must not mischaracterize any ZurePay service as a payment method or
        exhibit a preference for other payment methods over any ZurePay service.
        Within all of your points of sale, you agree not to try to dissuade or
        inhibit your customers from using ZurePay or encourage the customer to
        use an alternate payment method. <br />
        <br />
        If you enable your customers to pay you with ZurePay, whenever you
        display or exhibit the payment methods that you accept (either within
        any point of sale or in your marketing materials, advertising and other
        customer communications), you agree to display the ZurePay services
        payment marks at least as prominently, and in at least as positive a
        manner, as you do for all other payment methods.
        <h3>Taxes, information reporting</h3>
        Some of our fees may be subject to applicable taxes, levies, duties or
        similar governmental assessments, including, for example, value-added
        tax, sales tax, income tax, use or withholding taxes, assessable by any
        jurisdiction (collectively, "taxes") and, unless expressly noted, our
        fees are exclusive of applicable taxes. <br />
        <br />
        It is your responsibility to determine what, if any, taxes apply to the
        payments you make or receive, and it is solely your responsibility to
        assess, collect, report and remit the correct taxes to the appropriate
        authority. ZurePay is not responsible for determining whether any taxes
        apply to your transaction, or for calculating, collecting, reporting or
        remitting taxes arising from any transaction. Notwithstanding the above,
        ZurePay may request that you provide your tax identification number
        and/or other tax-related documentation or information. <br />
        <br />
        If you do not provide ZurePay the requested information or
        documentation, you understand and agree that you may be subject to
        account limitations and withholding tax at the applicable rates on gross
        payments received. ZurePay will send such withholding taxes to the
        appropriate tax authorities and cannot refund those amounts.
        <h3>Your refund policy and privacy policy</h3>
        You must publish a refunds and return policy, as well as a privacy
        policy, where required by law.
        <h3>Payment review</h3>
        ZurePay reviews certain potentially high-risk transactions. If ZurePay
        determines, in its sole discretion, that a transaction is high-risk, we
        place a hold on the payment and provide notice to you to delay shipping
        of the item. ZurePay will conduct a review and either complete or cancel
        the payment. If the payment is completed, ZurePay will provide notice to
        you to ship the item. <br />
        <br />
        Otherwise, ZurePay will cancel the payment and the funds will be
        returned to the buyer, unless we are legally required to take other
        action. All payments that complete this payment review will be eligible
        for ZurePay's Seller Protection program if they meet ZurePay's Seller
        Protection program requirements. We will notify you by email and/or
        through your ZurePay account.
        <h3>In-store payments</h3>
        If you accept ZurePay payments at your physical store, you must
        communicate the total amount of the transaction to the customer before
        it takes place. You may charge your customer's account only for
        transactions that they have authorized. <br />
        <br />
        You must also provide customers with a physical receipt if they request
        one. You agree that any transaction that you make shall have an accurate
        and true description of the goods and services being purchased.
        <h3>Marketplace sellers</h3>
        If you're a seller on a marketplace or through a third-party application
        where ZurePay is offered, you must comply with any rules that apply to
        the marketplace's or the third party application's buyer protection
        program for sales you make through that forum. Any such protections may
        require you to take certain actions and may impact how claims are
        processed.
        <h3>Card not present transactions</h3>
        Whenever a buyer uses a debit or credit card as the payment method for a
        transaction using their account to buy something from you as a seller,
        the transaction will be processed as a "card not present" transaction,
        even if the buyer is at your store location.
        <h3>Accepting preauthorized payments</h3>
        As a seller, you can accept payments from a buyer's account through
        preauthorized payments either on a one-time, regular or sporadic basis.
        This type of transaction is sometimes called a "billing agreement,"
        "subscription," "recurring payment," "reference transaction,"
        "preauthorized debit or PAD", "preauthorized transfer, "pre-approved
        payment " or "automatic payment."
        <h3>If you receive preauthorized payments from buyers:</h3>
        <br />
        <b>You must: You must not:</b>
        <br />
        Get each buyer's prior authorization for the amount, frequency, and
        duration of any such payment. Restart future payments without the
        buyer's written authorization, if a buyer has stopped or canceled a
        preauthorized payment. Provide a simple and easily accessible online
        cancellation procedure, if buyers sign up for preauthorized payments
        online. Provide buyers the ability to stop any such payment up to 3
        Business Days before the date scheduled for payment. <br />
        <br />
        Notify the buyer at least 10 days in advance of the amount and date of
        each preauthorized payment if the preauthorized payment will vary from
        the preauthorized amount or (at the buyer's option) any payment that is
        in an amount that falls outside of the pre-determined range.
        <h3>Automatic Transfer Countries</h3>
        If you are a resident of an Automatic Transfer Country, then you have
        the ability to receive payments, but you must withdraw the full amount
        of your payment through an available withdrawal method. If you do not do
        so, the amounts will be automatically withdrawn from your ZurePay
        account to your withdrawal method on a regular basis. For additional
        terms regarding Automatic Transfer, please click here.
        <br />
        <br />
        <h3>Transaction Fees for Online and In-Store Payments</h3>
        <br />
        <b>Standard transaction fees</b>
        <br />
        Your ZurePay transaction fees depend on the buyer's country/region. The
        fees you pay when selling goods or services or receiving donations, and
        the buyer pays using their ZurePay account (or using another authorized
        wallet) can be found on the Commercial Payments Fees table. Please note
        that:
        <li>
          We may adjust the fees applicable to future transactions that you
          process using ZurePay. We will provide you at least 14 days' advance
          notice of any fee increase or the introduction of a new type of fee.
        </li>
        <li>
          If you accept payments using a ZurePay product (like ZurePay Payments
          Pro), the fees applicable to those products will apply to your
          transactions.
        </li>
        <h3>Merchant rate</h3>
        To qualify for our merchant rate, you must submit a one-time
        application, have a qualifying monthly sales volume, and have a business
        account in good standing. To view Merchant rate criteria click here.
        Please note that the merchant rate is only available to merchants with
        ZurePay accounts registered in certain countries/regions.
        <h3>Micropayments Fees</h3>
        You may qualify to receive micropayments pricing for the sale of goods
        and services through your ZurePay account, if your transactions
        typically average less than $10 USD. In order to qualify, you must have
        a ZurePay account that is in good standing (for example, no limitations
        or negative balance in your ZurePay account), you may not be processing
        payments using ZurePay Payments Pro and you must submit an application
        and have it approved by us. <br />
        <br />
        If your ZurePay account is approved to accept micropayments, then the
        fees found on the Micropayment Fees table will apply to all transactions
        for the sale of goods or services processed through your ZurePay
        account, instead of the Commercial Payments Fees. If you have multiple
        ZurePay accounts, you must route your micropayments transactions through
        the appropriate account. <br />
        <br /> Once a transaction is processed, ZurePay will not re-route the
        transaction through a different account. By applying for Micropayments
        for Digital Goods, you agree that for digital goods transactions you
        receive up to the amounts in the table, then if a buyer opens a Dispute,
        ZurePay may reverse the transaction, and remove the funds from your
        account without requiring the buyer to escalate the dispute to a claim.
      </p>
      <p className="parag">
        <h3>ZurePay Payouts</h3>
        If you are using ZurePay Payouts (formerly Mass Pay), the terms of the
        ZurePay Payouts Agreement will apply. Your responsibility to notify
        ZurePay of pricing or fee errors. Once you have access to any account
        statement(s) or other account activity information made available to you
        by ZurePay with respect to your business account(s), you will have sixty
        (60) days to notify ZurePay in writing of any errors or discrepancies
        with respect to the pricing or other fees applied by ZurePay.
        <br />
        <br /> If you do not notify ZurePay within such timeframe, you accept
        such information as accurate, and ZurePay shall have no obligation to
        make any corrections, unless otherwise required by applicable law. For
        the purposes of this provision, such pricing or fee errors or
        discrepancies are different than unauthorized transactions and other
        electronic transfer errors which are each subject to different
        notification timeframes as set forth in this user agreement.
        <h3>Refunds, Reversals and Chargebacks</h3>
        <b>General information</b>
        <br />
        If you receive a payment for selling goods or services that is later
        refunded or invalidated for any reason, you are responsible for the full
        amount of the payment sent to you plus any fees (including any
        applicable chargeback fee or Dispute fee described below). Whenever a
        transaction is refunded or otherwise reversed, ZurePay will refund or
        reverse the transaction from your ZurePay account in the same currency
        as the original transaction. <br />
        <br />
        If the balance in your ZurePay account for a particular currency is
        insufficient to cover the amount of a refund or reversal, ZurePay will
        perform a currency conversion in order to refund or reverse the
        transaction. ZurePay's transaction exchange rate (including our currency
        conversion fee) at the time the refund or reversal is processed will be
        used. If you refund a transaction, we'll retain the fees you paid as set
        out on our Fees page.
        <h3>Payments that are invalidated and reversed</h3>
        <br />
        <b>Payments to you may be invalidated and reversed by ZurePay if:</b>
        <li>
          You lose a ZurePay Buyer Protection claim submitted to us by a buyer,
          including as a result of your failure to respond in a timely manner.
        </li>
        <li>
          Your buyer pursues a chargeback related to a card-funded transaction
          and the transaction is not eligible for ZurePay's Seller Protection.
          The card issuer, not ZurePay, determines whether a buyer is successful
          when they pursue a chargeback related to a card-funded transaction.
        </li>
        <li>
          You do not fulfill the transaction as promised or you cannot provide
          proof of shipment or proof of delivery when required.
        </li>
        <li>
          Our investigation of a bank reversal made by a buyer or the buyer's
          bank finds that the transaction was fraudulent.
        </li>
        <li>ZurePay sent the payment to you in error.</li>
        <li>The payment was unauthorized.</li>
        <li>
          You received the payment for activities that violated this user
          agreement, ZurePay's Acceptable Use Policy or any other agreement
          between you and ZurePay.
        </li>
        When you receive a payment, you are liable to ZurePay for the full
        amount of the payment sent to you plus any fees if the payment is later
        invalidated for any reason. If the buyer paid in another currency, the
        full amount of the payment sent to you may be calculated in that
        currency, using ZurePay's transaction exchange rate (including our
        currency conversion fee) at the time the refund or reversal is
        processed. If the balance in your ZurePay account is insufficient to
        cover your liability for the payment amount plus the fees, then this
        will result in a negative balance in your ZurePay account. Any negative
        balance in your ZurePay account represents an amount that you owe to us,
        and, in this situation, you must immediately add funds to your ZurePay
        account to eliminate it. If you do not do so, ZurePay may:
        <li>
          recover any amounts due to ZurePay by debiting the balance of your
          ZurePay account;
        </li>
        <li>engage in collection efforts to recover such amounts from you;</li>
        <li>
          take any and all action as outlined under Amounts owed to ZurePay; or
        </li>
        <li>
          place a limitation or take other action on your ZurePay account as
          outlined under Restricted Activities, Holds & Other Actions We May
          Take.
        </li>
        <h3>Dispute fees</h3>
        ZurePay will charge a Dispute fee to sellers for facilitating the online
        dispute resolution process for transactions that are processed either
        through a buyer’s ZurePay account or through a ZurePay guest checkout.
        The Dispute fee applies when the buyer pursues a claim directly with
        ZurePay, a chargeback with their card issuer, or a reversal with their
        bank. The Dispute fee will be charged at either the Standard Dispute fee
        rate or the High Volume Dispute fee rate. The Dispute fee will be
        charged in the currency which you selected for the original transaction
        listing. If the transaction was in a currency not listed in the Dispute
        fee table, the fee charged will be in your primary holding currency. The
        Dispute fee will be deducted from your ZurePay account after the claim
        is decided. The Dispute fee amount will be determined when the dispute
        is created. <br />
        <br />
        The fee is based on the ratio of the total transaction amount of all
        Item Not Received and Significantly Not as Described claims you receive
        compared to the total amount of your sales for the previous three
        calendar months. Your total claims include all Item Not Received and
        Significantly Not as Described claims that are filed either directly
        with and escalated to ZurePay or with the buyer’s card issuer or bank.
        Your total claims do not include claims for Unauthorized Transactions.
        <br />
        <br />
        For example, for the month of September, your disputes ratio will be
        calculated by considering your total claims to sales ratio over June,
        July and August. The claims ratio for September will determine the
        dispute fee for all claims filed in October. If your Disputes Ratio is
        1.5% or more and you had more than 100 sales transactions in the
        previous three full calendar months, you will be charged the High Volume
        Dispute fee for each dispute. Otherwise, you will be charged the
        Standard Dispute fee for each dispute.
        <h3>
          You will not be charged a Standard Dispute fee for disputes that are:
        </h3>
        <li>
          Inquiries in ZurePay’s Resolution Center that are not escalated to a
          claim with ZurePay.
        </li>
        <li>
          Resolved directly between you and the buyer and not escalated to a
          claim with ZurePay.
        </li>
        <li>
          Filed by the buyer directly with ZurePay as an Unauthorized
          Transaction.
        </li>
        <li>
          Deemed by ZurePay in its sole discretion to have met all the
          requirements under ZurePay’s Seller Protection program.
        </li>
        <li>
          Claims with a transaction value that is less than twice the amount of
          a Standard Dispute fee.
        </li>
        <li>Decided in your favour by ZurePay or your issuer.</li>
        You will not be charged a High Volume Dispute fee for disputes that are:
        <li>
          Inquiries in ZurePay’s Resolution Center and not escalated to a claim
          with ZurePay.
        </li>
        <li>
          Resolved directly between you and the buyer and not escalated to a
          claim with ZurePay.
        </li>
        <li>
          Filed by the buyer directly with ZurePay as an Unauthorized
          Transaction.
        </li>
        Sellers charged High Volume Dispute fees may be required to provide a
        remediation plan which includes an explanation of the cause of the
        increased dispute rate, the actions taken to reduce disputes, and the
        timelines for those actions. <br />
        <br />
        If you engage in a Restricted Activity, ZurePay may charge the High
        Volume Dispute fees for all current and future disputes irrespective of
        your dispute ratio or sales volumes, given ZurePay’s increased
        involvement as a result of such Restricted Activity. <br />
        <br />
        Disputes listed above may be excluded from being charged a Standard
        Dispute fee or a High Volume Dispute fee, but the claim itself may still
        be included in the overall calculation of your dispute ratio.
        <br />
        <h3>Chargeback fees</h3>
        For transactions that are not processed either through a buyer’s ZurePay
        account or through a guest checkout, and where the buyer pursues a
        chargeback for the transaction with their card issuer, ZurePay will
        charge you a chargeback fee for facilitating the chargeback process.
        <br />
        <br />
        This fee will apply regardless of whether the buyer is successful in
        pursuing the chargeback with the card issuer. The applicable chargeback
        fee will be deducted from your ZurePay account. The chargeback fee is
        the amount specified on the Chargeback Fees table in the currency of the
        original transaction. If the transaction was in a currency not listed in
        the Chargeback fee table, the fee charged will be in your primary
        holding currency.
        <h3>
          If a buyer files a chargeback, the card issuer, not ZurePay, will
          determine who wins the chargeback.
        </h3>
        Impact of various Buyer Protection processes on sellers You should read
        and understand ZurePay's Buyer Protection program and if you sell goods
        and services to and receive payments from buyers with ZurePay accounts
        in countries/regions other than your own, you should also be familiar
        with ZurePay's Buyer Protection available to buyers in each of those
        countries/regions. Buyers' rights under these programs may impact you as
        a seller. You can find this information for ZurePay's programs on the
        Legal Agreements page by selecting your buyer's location at the top of
        the page and referring to the applicable user agreement for that
        geography.
        <br />
        <b>
          If you lose a claim under ZurePay's Buyer Protection program in any
          country/region:
        </b>
        <li>You will be required to reimburse ZurePay for your liability.</li>
        <li>
          Your liability will include the full purchase price of the item plus
          the original shipping cost (and in some cases you may not receive the
          item back), and the ZurePay fees that you were charged for the
          transaction. This applies when you are the primary seller or a
          secondary seller of goods or services. For example, event ticketing
          agents, or online travel agents will forfeit the full purchase amount
          paid by the buyer. In some cases you may not receive the item back.
        </li>
        <li>
          The Buyer Protection claim will only be considered fully resolved if:
        </li>
        <li>the refund to a buyer is processed through ZurePay, or</li>
        <li>
          you provide evidence acceptable to ZurePay, in its sole discretion,
          that the buyer agreed to the alternative resolution provided.
        </li>
        <li>
          You will not receive a refund of the ZurePay fees that you paid in
          connection with the sale.
        </li>
        <li>
          If the claim was that the item received was Significantly Not as
          Described, you may not receive the item back, or you may be required
          to accept the item back and pay for the return shipping costs.
        </li>
        <li>
          If the claim was that the item received was Significantly Not as
          Described and related to an item you sold that is counterfeit, you
          will be required to provide a full refund to the buyer and you may not
          receive the item back.
        </li>
        <h3>ZurePay's Seller Protection Program</h3>
        <br />
        <b>What's eligible</b>
        If you sell a good or service to a buyer, you may be eligible for
        ZurePay's Seller Protection program. When it applies, ZurePay's Seller
        Protection program entitles you to retain the full purchase amount.
        ZurePay determines, in its sole discretion, whether your claim is
        eligible for ZurePay’s Seller Protection program. <br />
        <br />
        There is no limit on the number of payments eligible for ZurePay's
        Seller Protection program. By accessing the transaction details page in
        your ZurePay account you can see your transactions that may be eligible
        for protection under this program. The program terms and conditions are
        set out in ZurePay’s Seller Protection program page and form part of
        this user agreement. Restricted Activities, Holds & Other Actions We May
        Take
      </p>
      <p className="parag">
        <h3>Restricted Activities</h3>
        In connection with your use of our websites, your ZurePay account, the
        ZurePay services, or in the course of your interactions with ZurePay,
        other ZurePay customers, or third parties, you will not:
        <li>
          Breach this user agreement, ZurePay's Acceptable Use Policy, the
          Commercial Entity Agreements (if they apply to you), or any other
          agreement between you and ZurePay.
        </li>
        <li>
          Violate any law, statute, ordinance, or regulation (for example, those
          governing financial services, consumer protections, unfair
          competition, anti-discrimination or false advertising).
        </li>
        <li>
          Infringe ZurePay's or any third party's copyright, patent, trademark,
          trade secret or other intellectual property rights, or rights of
          publicity or privacy.
        </li>
        <li> Sell counterfeit goods.</li>
        <li>
          Act in a manner that is defamatory, trade libelous, threatening or
          harassing.
        </li>
        <li> Provide false, inaccurate or misleading information.</li>
        <li>
          Send or receive what we reasonably believe to be potentially
          fraudulent funds.
        </li>
        <li>
          Engage in potentially fraudulent or suspicious activity and/or
          transactions.
        </li>
        <li>
          Refuse to cooperate in an investigation or provide confirmation of
          your identity or any information you provide to us.
        </li>
        <li>
          Attempt to double dip during the course of a dispute by receiving or
          attempting to receive funds from both ZurePay and the seller, bank or
          card issuer for the same transaction.
        </li>
        <li>
          Control an account that is linked to another account that has engaged
          in any of these restricted activities.
        </li>
        <li>
          Conduct your business or use the ZurePay services in a manner that
          results in or may result in:
        </li>
        <li> complaints;</li>
        <li>
          requests by buyers (either filed with us or card issuers) to
          invalidate payments made to you; or;
        </li>
        <li>
          fees, fines, penalties or other liability or losses to ZurePay, other
          ZurePay customers, third parties or you.
        </li>
        <li>
          Use your ZurePay account or the ZurePay services in a manner that
          ZurePay, Visa, MasterCard, American Express, Discover or any other
          electronic funds transfer network reasonably believes to be an abuse
          of the card system or a violation of card association or network
          rules.
        </li>
        <li> Allow your ZurePay account to have a negative balance.</li>
        <li>
          Provide yourself a cash advance from your credit card (or help others
          to do so).
        </li>
        <li>
          Access the ZurePay services from a country that is not included on
          ZurePay's permitted countries list;
        </li>
        <li>
          Take any action that imposes an unreasonable or disproportionately
          large load on our websites, software, systems (including any networks
          and servers used to provide any of the ZurePay services) operated by
          us or on our behalf or the ZurePay services; facilitate any viruses,
          trojan horses, malware, worms or other computer programming routines
          that attempts to or may damage, disrupt, corrupt, misuse,
          detrimentally interfere with, surreptitiously intercept or
          expropriate, or gain unauthorized access to any system, data,
          information or ZurePay services; use an anonymizing proxy; use any
          robot, spider, other automatic device, or manual process to monitor or
          copy our websites without our prior written permission; use any
          device, software or routine to bypass our robot exclusion headers; or
          interfere or disrupt or attempt to interfere with or disrupt our
          websites, software, systems (including any networks and servers used
          to provide any of the ZurePay services) operated by us or on our
          behalf, any of the ZurePay services or other users' use of any of the
          ZurePay services.
        </li>
        <li>
          Take any action that may cause us to lose any of the services from our
          Internet service providers, payment processors, or other suppliers or
          service providers.
        </li>
        <li> Use the ZurePay services to test credit card behaviors.</li>
        <li>
          Circumvent any ZurePay policy or determinations about your ZurePay
          account such as temporary or indefinite suspensions or other account
          holds, limitations or restrictions, including, but not limited to,
          engaging in the following actions: attempting to open new or
          additional ZurePay account(s) when a ZurePay account has a negative
          balance or has been restricted, suspended or otherwise limited;
          opening new or additional ZurePay accounts using information that is
          not your own (e.g. name, address, email address, etc.); or using
          someone else's ZurePay account;
        </li>
        <li> Harass and/or threaten our employees, agents, or other users.</li>
        <li>
          Abuse of our online dispute resolution process and/or ZurePay's Buyer
          Protection and/or ZurePay’s Seller Protection program.
        </li>
        <li>
          Cause us to receive a disproportionate number of claims that have been
          closed in favour of the claimant regarding your ZurePay account or
          business.
        </li>
        <li>
          Have a credit score from a credit reporting agency that indicates a
          high level of risk associated with your use of the ZurePay services.
        </li>
        <li>
          Disclose or distribute another user's information to a third party, or
          use such information for marketing purposes unless you receive the
          user's express consent to do so.
        </li>
        <li>
          Send unsolicited emails to users or use the ZurePay services to
          collect payments for sending, or assisting in sending, unsolicited
          emails to third parties.
        </li>
        <li>
          Copy, reproduce, communicate to any third party, alter, modify, create
          derivative works, publicly display or frame any content from the
          ZurePay websites without our or any applicable third party's written
          consent.
        </li>
        <li>
          Reveal your account password(s) to anyone else, nor use anyone else's
          password. We are not responsible for losses incurred by you including,
          without limitation, the use of your account by any person other than
          you, arising as the result of misuse of passwords.
        </li>
        Actions We May Take if You Engage in Any Restricted Activities If we
        believe that you've engaged in any of these activities, we may take a
        number of actions to protect ZurePay, its customers and others at any
        time in our sole discretion.
        <br />
        <b>
          The actions we may take include, but are not limited to, the
          following:
        </b>
        <li>
          Terminate this user agreement, limit your ZurePay account, and/or
          close or suspend your ZurePay account, immediately and without penalty
          to us;
        </li>
        <li>
          Refuse to provide the ZurePay services to you now and in the future;
        </li>
        <li>
          Limit your access to our websites, software, systems (including any
          networks and servers used to provide any of the ZurePay services)
          operated by us or on our behalf, your ZurePay account or any of the
          ZurePay services, including limiting your ability to pay or send
          payments with any of the payment methods linked to your ZurePay
          account, restricting your ability to send payments or make
          withdrawals;
        </li>
        <li>
          Hold the balance in your ZurePay account if reasonably needed to
          protect against the risk of liability to ZurePay or a third party, or
          if you have violated our Acceptable Use Policy. The hold may remain in
          place longer than 180 days according to Court Orders, Regulatory
          Requirements or Other Legal Processes;
        </li>
        <li>
          Suspend your eligibility for ZurePay's Buyer Protection program and/or
          ZurePay's Seller Protection program;
        </li>
        <li>
          Contact buyers who have purchased goods or services from you using
          ZurePay, your bank or credit card issuer, other impacted third parties
          or law enforcement about your actions;
        </li>
        <li> Update inaccurate information you provided us;</li>
        <li> Take legal action against you;</li>
        <li>
          Hold, apply or transfer the funds in your ZurePay account as required
          by judgments and orders which affect you or your ZurePay account,
          including judgments and orders issued by courts in Singapore or
          elsewhere and directed to ZurePay or its affiliates;
        </li>
        <li>
          If you've violated our Acceptable Use Policy, or if you are a seller
          and receive funds for transactions that violate the Acceptable Use
          Policy, then you're also responsible for damages to ZurePay caused by
          your violation of this policy; or
        </li>
        <li>
          If you are a seller and receive funds for transactions that violate
          the Acceptable Use Policy and said violation is associated with fraud
          or the sale of goods that are counterfeit or otherwise infringe on
          intellectual property rights, then in addition to being subject to the
          above actions you will be liable to ZurePay for the amount of
          ZurePay's damages caused by said violation. <br />
          <br />
          You acknowledge and agree that $2,500 USD (or equivalent) per
          violation is presently a reasonable minimum estimate of ZurePay's
          actual damages - including, but not limited to internal administrative
          costs incurred by ZurePay to monitor and track violations, damage to
          ZurePay’s brand and reputation, and penalties imposed upon ZurePay by
          its business partners resulting from said violation associated with
          fraud or the sale of goods that are counterfeit or otherwise infringe
          on intellectual property rights - considering all currently existing
          circumstances, including the relationship of the sum to the range of
          harm to ZurePay that reasonably could be anticipated because, due to
          the nature of the violation, actual damages would be impractical or
          extremely difficult to calculate. ZurePay may deduct such damages
          directly from any existing balance in any ZurePay account you control.
        </li>
        <li>
          Charging the High Volume Dispute fees for all current and future
          disputes irrespective of your dispute ratio or sales volumes given
          ZurePay’s increased involvement as a result of such Restricted
          Activity.
        </li>
        If we close your ZurePay account or terminate your use of the ZurePay
        services for any reason, we'll provide you with notice of our actions
        and make any unrestricted funds held in your ZurePay account available
        for withdrawal. You are responsible for all reversals, chargebacks,
        claims, fees, fines, penalties and other liability incurred by ZurePay,
        any ZurePay customer, or a third party caused by or arising out of your
        breach of this user agreement, and/or your use of the ZurePay services.
        <h3>Holds, Limitations and Reserves</h3>
        <h3>What are holds, limitations and reserves</h3>
        Under certain circumstances, in order to protect ZurePay and the
        security and integrity of the network of buyers and sellers that use the
        ZurePay services, ZurePay may take account-level or transaction-level
        actions. Unless otherwise noted, if we take any of the actions described
        here, we'll provide you with notice of our actions, but we retain the
        sole discretion to take these actions. <br />
        <br />
        To request information in connection with an account limitation, hold or
        reserve, you should visit the Resolution Centre or follow the
        instructions in our email notice with respect to the limitation, hold or
        reserve. Our decision about holds, limitations and reserves may be based
        on confidential criteria that are essential to our management of risk
        and the protection of ZurePay, our customers and/or service providers.
        <br />
        <br /> We may use proprietary fraud and risk modeling when assessing the
        risk associated with your ZurePay account. In addition, we may be
        restricted by regulation or a governmental authority from disclosing
        certain information to you about such decisions. We have no obligation
        to disclose the details of our risk management or security procedures to
        you. In order to facilitate ZurePay’s actions described above and allow
        us to assess the level of risk associated with your ZurePay account, you
        agree to cooperate with ZurePay’s reasonable requests for financial
        statements and other documentation or information in a timely fashion.
        <h3>Holds</h3>A hold is an action that ZurePay may take under certain
        circumstances either at the transaction level or the account level. When
        ZurePay places a temporary hold on a payment, the funds shall not be
        available to either the sender or the recipient. ZurePay reviews many
        factors before placing a hold on a payment, including: account tenure,
        transaction activity, business type, past customer disputes, and overall
        customer satisfaction. Some common situations where ZurePay will hold
        payments include:
        <li>New sellers or sellers who have limited selling activity.</li>
        <li>
          Payments for higher-risk categories like electronics or tickets.
        </li>
        <li>
          Sellers who have performance issues, or a high rate of buyer
          dissatisfaction or disputes.
        </li>
        <h3>Holds based on ZurePay’s risk decisions</h3>
        We may place a hold on payments sent to your ZurePay account if, in our
        sole discretion, we believe that there may be a high level of risk
        associated with you, your ZurePay account, or your transactions or that
        placing such a hold is necessary to comply with regulatory requirements.
        We make decisions about whether to place a payment hold based on a
        number of factors, including information available to us from both
        internal sources and third parties. <br />
        <br />
        When we place a hold on a payment, the funds will appear in your ZurePay
        account with an indication that they are unavailable or pending. We'll
        notify you, either through your ZurePay account or directly by phone or
        email, whenever we place a hold. Risk-based holds generally remain in
        place for up to 30 days from the date the payment was received into your
        ZurePay account unless ZurePay has a reason to continue to hold the
        payment. <br />
        <br />
        We may release the hold earlier under certain circumstances (for
        example, if you've uploaded shipment tracking information related to the
        transaction), but any earlier release is at our sole discretion. The
        hold may last longer than 30 days if the payment is challenged as a
        payment that should be invalidated and reversed based on a disputed
        transaction as discussed in the following paragraph below. In this case,
        we'll hold the payment in your ZurePay account until the matter is
        resolved.
        <h3>Holds related to Marketplace transactions</h3>
        If you're a seller on a marketplace or through a third-party application
        where ZurePay is offered, a hold may be placed on a payment sent to you
        at the instruction of the applicable marketplace or third-party. This is
        done once you have granted us permission to have your funds held and
        will be in accordance with your agreement with the third-party. These
        holds will appear in your ZurePay account. If you have questions about
        why the applicable marketplace or third party instructed ZurePay to put
        these holds in place, you will need to contact the marketplace or
        third-party directly.
        <h3>Holds based on disputed transactions</h3>
        If a payment sent to you as a seller is challenged as a payment that
        should be invalidated and reversed, we may place a temporary hold on the
        funds in your ZurePay account to cover the amount that could be
        reversed. Any of the situations described under Refunds, Reversals and
        Chargebacks are situations that could result in us placing a hold on a
        payment. If we determine the transaction should not be reversed, we'll
        lift the temporary hold. If we determine the transaction should be
        reversed, we'll remove the funds from your ZurePay account.
        <h3>Account Limitations</h3>
        Limitations prevent you from completing certain actions with your
        ZurePay account, such as withdrawing, sending or receiving payments.
        These limitations are implemented to help protect ZurePay, buyers and
        sellers when we notice restricted activities, an increased financial
        risk, or activity that appears to us as unusual or suspicious.
        Limitations also help us collect information necessary for keeping your
        ZurePay account open. There are several reasons why we may limit your
        access to your ZurePay account or the ZurePay services, and/or limit
        access to your funds, including:
        <li>
          If we suspect someone could be using your ZurePay account without your
          knowledge, we'll limit it for your protection and look into the
          fraudulent activity.
        </li>
        <li>
          If your debit or credit card issuer alerts us that someone has used
          your card without your permission. Similarly, if your bank lets us
          know that there have been unauthorized transfers between your ZurePay
          account and your bank account.
        </li>
        <li>In order to comply with applicable law.</li>
        <li>
          If we reasonably believe you have breached this agreement or violated
          the Acceptable Use Policy.
        </li>
        <li>
          Seller performance indicating your ZurePay account is high risk.
          Examples include: indications of poor selling performance because
          you've received an unusually high number of claims and chargebacks
          selling an entirely new or high cost product, or if your typical sales
          volume increases rapidly.
        </li>
        If we limit access to your ZurePay account, we'll provide you with
        notice of our actions and the opportunity to request restoration of
        access if, in our sole discretion, we deem it appropriate. You will need
        to resolve any issues with your account before a limitation can be
        removed. Normally, this is done after you provide us with the
        information we request. However, if we reasonably believe a risk still
        exists after you have provided us that information, we may take action
        to protect ZurePay, our users, a third party, or you from reversals,
        fees, fines, penalties, legal and/or regulatory risks and any other
        liability.
        <h3>Reserves</h3>
        We may place a reserve on your ZurePay account if we believe there may
        be a high level of risk associated with you, your ZurePay account, your
        business model, or your transactions. When we place a reserve on your
        ZurePay account, it means that all or some of the transactions will be
        shown as "pending" in the balance of your ZurePay account, and you will
        not be able to withdraw funds in a "pending" status, in order to protect
        against the risk of transactions made by you being reversed or
        invalidated or any other risk related to your ZurePay account or use of
        the ZurePay services. We make decisions about whether to place a reserve
        based on a number of factors, including information available to us from
        both internal sources and from third parties. ZurePay considers a list
        of non-exclusive factors, and whether and how these factors have changed
        over time, including:
        <li>How long you have been in business.</li>
        <li>Whether your industry has a higher likelihood of chargebacks.</li>
        <li>
          Your payment processing history with ZurePay and other providers.
        </li>
        <li>Your business and/or personal credit history.</li>
        <li>Your delivery time frames.</li>
        <li>
          Whether you have higher than average number of returns, chargebacks,
          claims or disputes.
        </li>
        If we place a reserve on funds in your account, we'll notify you of our
        actions and the terms of the reserve. There are two categories of
        reserves that may be placed on your ZurePay account, and one or both may
        be applied at the same time:
        <li>
          A Rolling reserve is a reserve where a percentage of each transaction
          you receive each day is held and then released later on a scheduled
          basis. For example, your reserve could be set at 10% and held for a
          90-day rolling period – meaning 10% of the funds you receive on day 1
          are held and then released on day 91, 10% of the funds you receive on
          day 2 are held until day 92, etc. Rolling reserves are the most common
          type of reserve.
        </li>
        <li>
          A Minimum reserve is a specific minimum amount that you're required to
          keep available in the balance of your ZurePay account at all times.
          The minimum reserve is either taken as an upfront amount deposited all
          at once (also known as called an Upfront reserve) or is established on
          a rolling basis from percentages of sales until the minimum reserve is
          achieved, much like a rolling reserve.
        </li>
      </p>
      <p className="parag">
        If we change the terms of the reserve due to a change in our risk
        assessment, we'll notify you of the new terms.
        <h3>Court Orders, Regulatory Requirements or Other Legal Processes</h3>
        If we are notified of a court order or other legal process (including
        garnishment or any equivalent process) affecting you, or if we otherwise
        believe we are required to do so in order to comply with applicable law
        or regulatory requirements, we may be required to take certain actions,
        including holding payments to/from your ZurePay account, placing a
        reserve or limitation on your ZurePay account, or releasing your funds.
        We will decide, in our sole discretion, which action is required of us.
        <br />
        <br />
        Unless the court order, applicable law, regulatory requirement or other
        legal process requires otherwise, we will notify you of these actions.
        We do not have an obligation to contest or appeal any court order or
        legal process involving you or your ZurePay account. When we implement a
        hold, reserve or limitation as a result of a court order, applicable
        law, regulatory requirement or other legal process, the hold, reserve or
        limitation may remain in place as long as reasonably necessary as
        determined by ZurePay.
        <h3>Liability for Unauthorized Transactions</h3>
        <h4>Protection from Unauthorized Transactions</h4>
        To protect yourself from unauthorized activity in your ZurePay account,
        you should regularly log into your ZurePay account and review your
        ZurePay account statement. ZurePay will notify you of each transaction
        by sending an email to your primary email address on file. You should
        review these transaction notifications to ensure that each transaction
        was authorized and accurately completed. ZurePay will protect you from
        unauthorized activity in your ZurePay account. When this protection
        applies, ZurePay will cover you for the full amount of the unauthorized
        activity as long as you cooperate with us and follow the procedures
        described below.
        <h3>What is an Unauthorized Transaction</h3>
        An "Unauthorized Transaction" occurs when a payment is sent from your
        ZurePay account that you did not authorize and that did not benefit you.
        For example, if someone steals your password, uses the password to
        access your ZurePay account, and sends a payment from your ZurePay
        account, an Unauthorized Transaction has occurred.
        <h3>What is not considered an Unauthorized Transaction</h3>
        The following are NOT considered Unauthorized Transactions:
        <li>
          If you give someone access to your ZurePay account (by giving them
          your login information) and they use your ZurePay account without your
          knowledge or permission. You are responsible for transactions made in
          this situation.
        </li>
        <li>
          Invalidation and reversal of a payment as a result of the actions
          described under Refunds, Reversals and Chargebacks.
        </li>
        <h3>Reporting an Unauthorized Transaction</h3>
        You should contact ZurePay customer service immediately if you believe:
        <li>
          there has been an Unauthorized Transaction sent from your account;
        </li>
        <li>there has been unauthorized access to your account;</li>
        <li>your ZurePay login information has been lost or stolen; or</li>
        <li>
          any device you have used to access your ZurePay account has been lost,
          stolen or deactivated.
        </li>
        You must give us all the available information relating to circumstances
        of any Unauthorized Transactions, and/or misappropriated or unauthorized
        use of your account, and take all reasonable steps requested to assist
        ZurePay in its investigation. If you notify us of an Unauthorized
        Transaction within sixty (60) days of the transaction and we are
        satisfied that it was an Unauthorized Transaction, you will be eligible
        for 100% protection for the Unauthorized Transaction.
        <h3>Error Resolution</h3>
        <h3>What is an Error:</h3>
        <h3>An "Error" means the following:</h3>
        <li>
          a processing error made by ZurePay or its suppliers in which your
          ZurePay account is mistakenly debited or credited, or when a
          transaction is incorrectly recorded in your ZurePay account.
        </li>
        <li>
          You send a payment and the incorrect amount is debited from your
          ZurePay account.
        </li>
        <li>
          A transaction is missing from or not properly identified in your
          ZurePay account statement.
          <li>
            We make a computational or mathematical error related to your
            ZurePay account. What is not considered an Error The following are
            NOT considered errors:
          </li>
        </li>
        If you give someone access to your ZurePay account (by giving them your
        login information) and they use your ZurePay account without your
        knowledge or permission. You are responsible for transactions made in
        this situation.{" "}
        <li>
          Invalidation and reversal of a payment as a result of the actions
          described under Refunds, Reversals and Chargebacks.
        </li>
        <li>
          If you erroneously send a payment to the wrong party, or send a
          payment for the wrong amount (based on a typographical error, for
          example). (Your only recourse in this instance will be to contact the
          party to whom you sent the payment and ask them to refund the payment.
          ZurePay will not reimburse you or reverse a payment that you have made
          in error.)
        </li>
        <h3>In case of errors or questions about your electronic transfers</h3>
        <h3>Contact us at our Resolution Centre.</h3>
        Notify us as soon as you can, if you think your statement or receipt is
        wrong or if you need more information about a transfer listed on the
        statement or receipt. We must hear from you no later than sixty (60)
        days after we sent the FIRST statement on which the problem or error
        appeared. Tell us your name, email address and account number (if any).
        <li>
          Describe the error or the transfer you are unsure about, and explain
          as clearly as you can why you believe it is an error or why you need
          more information.
        </li>
        <li>Tell us the dollar amount of the suspected error.</li>
        If you tell us orally, we may require that you send us your complaint or
        question in writing within 10 Business Days. We will complete our
        investigation within 10 Business Days after we receive your notification
        of the suspected error. If we need more time, however, we may take up to
        45 days to investigate your complaint or question. If we decide to do
        this, we will credit your ZurePay account within 10 Business Days for
        the amount you think is in error, so that you will have the provisional
        credit during the time it takes us to complete our investigation. We
        will notify you of the provisional credit within 2 Business Days of the
        crediting. If we ask you to put your complaint or question in writing
        and we do not receive it within 10 Business Days, we may not
        provisionally credit your ZurePay account. For errors involving new
        ZurePay accounts, point-of-sale transactions, or foreign-initiated
        transactions, we may take up to ninety (90) days to investigate your
        complaint or question. For new ZurePay accounts (the first transaction
        from your account was less than 30 Business Days from the date you
        notify us), we may take up to 20 Business Days to complete the
        investigation. We will tell you the results within 3 Business Days after
        completing our investigation. If we decide that there was no error, we
        will send you a written explanation of our decision. If you received a
        provisional credit, we will remove it from your account and notify you
        of the date and amount of the debit. You may ask for copies of the
        documents that we used in our investigation. If we determine that there
        was an error, we will promptly credit the full amount into your account
        within 1 Business Day of our determination. Or, if you have already
        received a provisional credit, you will be allowed to retain those
        amounts.
        <h3>Processing Errors</h3>
        <h4>
          We will rectify any processing error that we discover. If the error
          results in:
        </h4>
        <li>
          You receive less than the correct amount to which you were entitled,
          then we will credit your ZurePay account for the difference between
          what you should have received and what you actually received.
        </li>
        <li>
          You receive more than the correct amount to which you were entitled,
          then we will debit your ZurePay account for the difference between
          what you actually received and what you should have received.
        </li>
        <li>
          Our not completing a transaction on time or in the correct amount,
          then we will be responsible to you for your losses or damages directly
          caused by this failure, unless:
        </li>
        <li>
          through no fault of ours, you did not have enough available funds to
          complete the transaction;
        </li>
        <li>
          our system was not working properly and you knew about the breakdown
          when you started the transaction; or
        </li>
        <li>
          the error was due to extraordinary circumstances outside our control
          (such as fire, flood or loss of Internet connection), despite our
          reasonable precautions.
        </li>
        <h3>Processing errors are not:</h3>
        <li>
          Delays that result from ZurePay applying holds, limitations or
          reserves.
        </li>
        <li>Delays based on a payment review.</li>
        <li>
          Delays described under How to buy something related to the time it may
          take for a purchase transaction to be completed in some situations.
        </li>
        <li>
          Your errors in making a transaction (for example, mistyping an amount
          that you are sending).
        </li>
        <h3>Other Legal Terms</h3>
        <h4>Communications Between You and Us</h4>
        You agree that ZurePay and its affiliates may contact you by email for
        marketing purposes. You may opt-out of receiving marketing
        communications when you open a ZurePay account, by changing your account
        preferences or by clicking on the unsubscribe link in any ZurePay email
        or receipt you receive from us. Please allow up to 10 Business Days for
        the opt-out to take effect. We will provide factual information about
        your account or the ZurePay services, even if you have opted-out of
        receiving marketing communications. <br />
        <br />
        If you provide us your mobile phone number, you agree that ZurePay and
        its affiliates may contact you at that number using autodialed or
        prerecorded message calls or text messages to: <br />
        <br />
        (i) inform you about your use of the ZurePay services and/or service
        your ZurePay accounts,
        <br />
        (ii) investigate or prevent fraud, or
        <br />
        (iii) collect a debt. We will not use autodialed or prerecorded message
        calls or texts to contact you for marketing purposes unless we receive
        your prior consent.
        <br />
        <br />
        We may share your mobile phone number with service providers with whom
        we contract to assist us with the activities listed above, but will not
        share your mobile phone number with third parties for their own purposes
        without your consent. <br />
        <br />
        You do not have to agree to receive autodialed or prerecorded message
        calls or texts to your mobile phone number in order to use and enjoy the
        products and services offered by ZurePay. <br />
        <br />
        You can decline to receive autodialed or prerecorded message calls or
        texts to your mobile phone number in several ways, including, via an
        email transaction receipt, in your account settings, or by contacting
        ZurePay Customer Service. The frequency of messages may vary, and
        standard telephone minute and text charges may apply. Neither we nor
        your phone carriers are liable for delayed or undelivered messages.
        However, we may still call you directly using other means if we need to
        speak with you. <br />
        <br />
        ZurePay may communicate with you about any ZurePay account or
        transaction information and the ZurePay services electronically. It is
        your responsibility to keep your primary email address up to date so
        that ZurePay can communicate with you electronically. You understand and
        agree that if ZurePay sends you an electronic communication but you do
        not receive it because your primary email address on file is incorrect,
        out of date, blocked by your service provider, or you are otherwise
        unable to receive electronic communications, ZurePay will be deemed to
        have provided the communication to you effectively. <br />
        <br />
        Please note that if you use a spam filter that blocks or re-routes
        emails from senders not listed in your email address book, you must add
        ZurePay to your email address book so that you will be able to view the
        communications we send to you. You can update your primary email address
        or street address at any time by logging into the ZurePay website.{" "}
        <br />
        <br />
        If your email address becomes invalid such that electronic
        communications sent to you by ZurePay are returned, ZurePay may deem
        your account to be inactive, and you will not be able to transact any
        activity using your ZurePay Account until we receive a valid, working
        primary email address from you.
        <br />
        <br /> ZurePay reserves the right to close your account if you withdraw
        your consent to receive electronic communications. You will be
        considered to have received a communication from us, if it's delivered
        electronically, 24 hours after the time we post it to our website or
        email it to you. You will be considered to have received a communication
        from us, if it's delivered by mail, 3 Business Days after we send it.{" "}
        <br />
        <br />
        Unless you're communicating with us about a matter where we've specified
        another notice address (for example, our Liability for Unauthorized
        Transactions and Other Errors process), written notices to ZurePay must
        be sent by postal mail to: ZurePay Bytes Consults., Attention: Legal
        Department, 5 Temasek Boulevard #09-01, Suntec Tower Five, Singapore
        038985.
        <br />
        <br /> You understand and agree that, to the extent permitted by law,
        ZurePay may, without further notice or warning, monitor or record
        telephone conversations you or anyone acting on your behalf has with
        ZurePay or its agents for quality control and training purposes or for
        our own protection. You acknowledge and understand that while your
        communications with ZurePay may be overheard, monitored, or recorded not
        all telephone lines or calls may be recorded by ZurePay, and ZurePay
        does not guarantee that recordings of any particular telephone calls
        will be retained or retrievable.
        <h3>ZurePay's Rights</h3>
        <h4>ZurePay suspension and termination rights</h4>
        ZurePay, in its sole discretion, reserves the right to suspend or
        terminate this user agreement, access to or use of its websites,
        software, systems (including any networks and servers used to provide
        any of the ZurePay services) operated by us or on our behalf or some or
        all of the ZurePay services for any reason and at any time upon notice
        to you and, upon termination of this user agreement, the payment to you
        of any unrestricted funds held in the balance of your ZurePay account.
        <h3>Security interest</h3>
        As security for the performance of your obligations under this user
        agreement, you grant to ZurePay a lien on, and security interest in and
        to, the balance in your ZurePay account in the possession of ZurePay.
        <h3>Amounts owed to ZurePay</h3>
        If the balance in your ZurePay account becomes negative for any reason,
        that negative balance represents an amount that you owe to ZurePay.
        ZurePay may set off these amounts from funds that are subsequently added
        to your ZurePay account, either by you or from payments you receive.{" "}
        <br />
        <br />
        If you have more than one ZurePay account, we may set off a negative
        balance in one ZurePay account against a balance in your other ZurePay
        account(s). If you continue using your ZurePay account when it has a
        negative balance, you authorize ZurePay to combine the negative balance
        with any debit or transaction sent from your account when that
        combination is disclosed to you in advance of initiating the debit or
        transaction. If you hold funds in a ZurePay account in multiple
        currencies, and the balance for one of the currencies becomes negative
        for any reason, ZurePay may set off the negative balance by using funds
        you maintain in a different currency. <br />
        <br />
        If you have a negative balance in non-relevant currency for a period of
        21 days or longer, ZurePay will convert this negative balance to
        relevant currency. <br />
        <br />
        In either case, a currency conversion will be necessary, and ZurePay's
        transaction exchange rate (including our currency conversion fee) will
        be used. In addition to the above, if you have a past due amount owed to
        ZurePay or one of our affiliates, ZurePay may debit your ZurePay account
        to pay any amounts that are past due. This includes amounts owed by
        using our various products such as Xoom or Braintree.
        <h3>Insolvency proceedings</h3>
        If any proceeding by or against you is commenced under any provision of
        any bankruptcy or insolvency law, we'll be entitled to recover all
        reasonable costs or expenses (including reasonable legal fees and
        expenses) incurred in connection with the enforcement of this user
        agreement.
        <h3>Assumption of rights</h3>
        If ZurePay invalidates and reverses a payment that you made to a
        recipient (either at your initiative or otherwise), you agree that
        ZurePay assumes your rights against the recipient and third parties
        related to the payment, and may pursue those rights directly or on your
        behalf, in ZurePay's discretion.
        <h3>No waiver</h3>
        Our failure to act with respect to a breach of any of your obligations
        under this user agreement by you or others does not waive our right to
        act with respect to subsequent or similar breaches. Indemnification and
        Limitation of Liability In this section, we use the term "ZurePay" to
        refer to ZurePay Bytes Consults. and our affiliates, and each of their
        respective directors, officers, employees, agents, joint ventures,
        service providers and suppliers. Our affiliates include each entity that
        we control, we are controlled by or we are under common control with.
        <h3>Indemnification</h3>
        You must indemnify ZurePay for actions related to your ZurePay account
        and your use of the ZurePay services. You agree to defend, indemnify and
        hold ZurePay harmless from any claim or demand (including reasonable
        legal fees) made or incurred by any third party due to or arising out of
        your breach of this user agreement, your improper use of the ZurePay
        services, your violation of any law or the rights of a third party
        and/or the actions or inactions of any third party to whom you grant
        permissions to use your ZurePay account or access our websites,
        software, systems (including any networks and servers used to provide
        any of the ZurePay services) operated by us or on our behalf, or any of
        the ZurePay services on your behalf.
        <h3>Limitation of liability</h3>
        ZurePay's liability is limited with respect to your ZurePay account and
        your use of the ZurePay services. In no event shall ZurePay be liable
        for lost profits or any special, incidental or consequential damages
        (including without limitation damages for loss of data or loss of
        business) arising out of or in connection with our websites, software,
        systems (including any networks and servers used to provide any of the
        ZurePay services) operated by us or on our behalf, any of the ZurePay
        services, or this user agreement (however arising, including
        negligence), unless and to the extent prohibited by law. Our liability
        to you or any third parties in any circumstance is limited to the actual
        amount of direct damages. In addition, to the extent permitted by
        applicable law, ZurePay is not liable, and you agree not to hold ZurePay
        responsible, for any damages or losses (including, but not limited to,
        loss of money, goodwill, or reputation, profits, or other intangible
        losses or any special, indirect, or consequential damages) resulting
        directly or indirectly from: <br />
        <br />
        (1) your use of, or your inability to use, our websites, software,
        systems operated by us or on our behalf (including any networks and
        servers used to provide any of the ZurePay services) or any of the
        ZurePay services; <br />
        (2) delays or disruptions in our websites, software, systems operated by
        us or on our behalf (including any networks and servers used to provide
        any of the ZurePay services), and any of the ZurePay services; <br />
        (3) viruses or other malicious software obtained by accessing our
        websites, software, systems operated by us or on our behalf (including
        any networks and servers used to provide any of the ZurePay services),
        any of the ZurePay services, or any website or service linked to our
        websites;
        <br /> (4) glitches, bugs, errors, or inaccuracies of any kind in our
        websites, software, systems operated by us or on our behalf (including
        any networks and servers used to provide any of the ZurePay services),
        any of the ZurePay services, or in the information and graphics obtained
        from them; <br />
        (5) the content, actions, or inactions of third parties; <br />
        (6) a suspension or other action taken with respect to your ZurePay
        account; or <br />
        (7) your need to modify your practices, content, or behavior, or your
        loss of or inability to do business, as a result of changes to this user
        agreement or ZurePay's policies.
        <h3>Disclaimer of Warranty and Release</h3>
        <h3>No warranty</h3>
        The ZurePay services are provided "as-is" and without any representation
        or warranty, whether express, implied or statutory. ZurePay specifically
        disclaims any implied warranties of title, merchantability, fitness for
        a particular purpose and non-infringement. <br />
        <br /> ZurePay does not have any control over the products or services
        provided by sellers who accept ZurePay as a payment method, and ZurePay
        cannot ensure that a buyer or a seller you are dealing with will
        actually complete the transaction or is authorized to do so. <br />
        <br /> ZurePay does not guarantee continuous, uninterrupted or secure
        access to any part of the ZurePay services, and operation of our
        websites, software, or systems operated by us or on our behalf
        (including any networks and servers used to provide any of the ZurePay
        services) may be interfered with by numerous factors outside of our
        control. <br />
        <br /> ZurePay will make reasonable efforts to ensure that requests for
        electronic debits and credits involving bank accounts, debit cards,
        credit cards, and cheque issuances are processed in a timely manner but
        ZurePay makes no representations or warranties regarding the amount of
        time needed to complete processing because the ZurePay services are
        dependent upon many factors outside of our control, such as delays in
        the banking system or mail service. <br />
        <br /> This paragraph gives you specific legal rights and you may also
        have other legal rights that vary from country/region to country/region.
        <h3>Release of ZurePay</h3>
        If you have a dispute with any other ZurePay account holder, you release
        ZurePay from any and all claims, demands and damages (actual and
        consequential) of every kind and nature, known and unknown, arising out
        of or in any way connected with such disputes. In entering into this
        release you expressly waive any protections that would otherwise limit
        the coverage of this release to include only those claims which you may
        know or suspect to exist in your favour at the time of agreeing to this
        release.
        <h3>Disputes with ZurePay</h3>
        <h3>Contact ZurePay First</h3>
        If a dispute arises between you and ZurePay, acting as either a buyer or
        a seller, our goal is to learn about and address your concerns. If we
        are unable to do so to your satisfaction, we aim to provide you with a
        neutral and cost effective means of resolving the dispute quickly.
        Disputes between you and ZurePay regarding the services may be reported
        to ZurePay Customer Service.
        <h3>Arbitration</h3>
        For any claim (excluding claims for injunctive or other equitable
        relief) where the total amount of the award sought is less than $10,000
        USD (or other currency equivalents), you may elect to resolve the
        dispute in a cost effective manner through binding non-appearance-based
        arbitration. If you elect arbitration, you will initiate such
        arbitration through an established alternative dispute resolution
        ("ADR") provider mutually agreed upon by you and us. <br />
        <br />
        The ADR provider and the parties must comply with the following rules:{" "}
        <br />
        (a) the arbitration shall be conducted by telephone, online and/or be
        solely based on written submissions, the specific manner shall be chosen
        by you;
        <br /> (b) the arbitration shall not involve any personal appearance by
        the parties or witnesses unless otherwise mutually agreed by the
        parties; and <br />
        (c) any judgment on the award rendered by the arbitrator may be entered
        in any court of competent jurisdiction.
        <h3>Intellectual Property</h3>
        <h4>trademarks ZurePay's</h4>
        "ZurePay.com,", "ZurePay," and all logos related to the ZurePay services
        are either trademarks or registered trademarks of ZurePay or ZurePay's
        licensors. You may not copy, imitate, modify or use them without
        ZurePay's prior written consent. In addition, all page headers, custom
        graphics, button icons, and scripts are service marks, trademarks,
        and/or trade dress of ZurePay. <br />
        <br />
        You may not copy, imitate, modify or use them without our prior written
        consent. You may use HTML logos provided by ZurePay for the purpose of
        directing web traffic to the ZurePay services. You may not alter, modify
        or change these HTML logos in any way, use them in a manner that
        mischaracterizes ZurePay or the ZurePay services or display them in any
        manner that implies ZurePay's sponsorship or endorsement. <br />
        <br />
        All right, title and interest in and to the ZurePay websites, any
        content thereon, the ZurePay services, the technology related to the
        ZurePay services, and any and all technology and any content created or
        derived from any of the foregoing is the exclusive property of ZurePay
        and its licensors.
        <h3>License grants, generally</h3>
        If you are using ZurePay software such as an API, developer's toolkit or
        other software application, which may include software provided by or
        integrated with software, systems or services of our service providers,
        that you have downloaded or otherwise accessed through a web or mobile
        platform, then ZurePay grants you a revocable, non-exclusive,
        non-sublicensable, non-transferable, royalty-free limited license to
        access and/or use ZurePay's software in accordance with the
        documentation accompanying such software. This license grant applies to
        the software and all updates, upgrades, new versions and replacement
        software. You may not rent, lease or otherwise transfer your rights in
        the software to a third party. You must comply with the implementation,
        access and use requirements contained in all documentation accompanying
        the ZurePay services. If you do not comply with implementation, access
        and use requirements you will be liable for all resulting damages
        suffered by you, ZurePay and third parties. ZurePay may update or
        discontinue any software upon notice to you. <br />
        <br /> While ZurePay may have <br />
        (1) integrated certain third party materials and technology into any web
        or other application, including its software, and/or <br />
        (2) accessed and used certain third party materials and technology to
        facilitate providing you with the ZurePay Services, you have not been
        granted and do not otherwise retain any rights in or to any such third
        party materials.
        <br />
        <br /> You agree not to modify, alter, tamper with, repair, copy,
        reproduce, adapt, distribute, display, publish, reverse engineer,
        translate, disassemble, decompile or otherwise attempt to create any
        source code that is derived from the software or any third party
        materials or technology, or otherwise create any derivative works from
        any of the software or third party materials or technology. You
        acknowledge that all rights, title and interest to ZurePay's software
        are owned by ZurePay and any third party materials integrated therein
        are owned by ZurePay's third party service providers.
        <br />
        <br /> Any other third party software application you use on the ZurePay
        websites is subject to the license you agreed to with the third party
        that provides you with this software. You acknowledge that ZurePay does
        not own, control nor have any responsibility or liability for any such
        third party software application you elect to use on any of our
        websites, software and/or in connection with the ZurePay services. If
        you are using the ZurePay services on the ZurePay website, or other
        website or platform hosted by ZurePay, or a third party, and are not
        downloading ZurePay's software or using third party software
        applications on the ZurePay website, then this section does not apply to
        your use of the hosted ZurePay services.
        <h3>
          License grant from you to ZurePay; intellectual property warranties
        </h3>
        ZurePay does not claim ownership of the content that you provide,
        upload, submit or send to ZurePay. Nor does ZurePay claim ownership of
        the content you host on third-party websites or applications that use
        ZurePay services to provide payments services related to your content.
        <br />
        <br /> Subject to the next paragraph, when you provide content to
        ZurePay or post content using ZurePay services, you grant ZurePay (and
        parties that we work with) a non-exclusive, irrevocable, royalty-free,
        transferable, and worldwide license to use your content and associated
        intellectual property and publicity rights in any media known now or in
        the future to help us improve, operate and promote our current services
        and develop new ones. <br />
        <br />
        ZurePay will not compensate you for any of your content. You acknowledge
        that ZurePay's use of your content will not infringe any intellectual
        property or publicity rights. Further, you acknowledge and warrant that
        you own or otherwise control all of the rights of the content you
        provide, and you agree to waive your moral rights and promise not to
        assert such rights against ZurePay.
        <br />
        <br /> You represent and warrant that none of the following infringe any
        intellectual property or publicity right: your provision of content to
        ZurePay, your posting of content using the ZurePay Services, and
        ZurePay's use of such content (including of works derived from it) in
        connection with the ZurePay services.
        <h3>License grant from sellers to ZurePay</h3>
        Notwithstanding the provisions of the prior paragraph, if you are a
        seller using the ZurePay services to accept payments for goods and
        services, you hereby grant ZurePay and its affiliates a worldwide,
        non-exclusive, transferable, sublicensable (through multiple tiers), and
        royalty-free, fully paid-up, right to use and display publicly, during
        the term of this user agreement, your trademark(s) (including but not
        limited to registered and unregistered trademarks, trade names, service
        marks, logos, domain names and other designations owned, licensed to or
        used by you) for the purpose of <br />
        (1) identifying you as a merchant that accepts a ZurePay service as a
        payment form and facilitating consumer transactions with you, and <br />
        (2) any other use to which you specifically consent.
        <h3>Miscellaneous</h3>
        <h4>Assignment</h4>
        You may not transfer or assign any rights or obligations you have under
        this user agreement without ZurePay's prior written consent. ZurePay may
        transfer or assign this user agreement or any right or obligation under
        this user agreement at any time. Business Days "Business Day(s)" means
        Monday through Friday, excluding national holidays recognized in
        Singapore.
        <h3>Dormant accounts</h3>
        If you do not log in to your ZurePay account for two or more years,
        ZurePay may close your ZurePay account and any unused funds in your
        account will be subject to applicable laws regarding unclaimed monies.
        <h3>Complete Agreement and Survival.</h3>
        This user agreement, along with any applicable Policies on the Legal
        Agreements page on the ZurePay website, sets forth the entire
        understanding between you and ZurePay with respect to the ZurePay
        services. <br />
        <br />
        All such terms which by their nature should survive, will survive the
        termination of this user agreement. If any provision of this user
        agreement is held to be invalid or unenforceable, such provision shall
        be struck and the remaining provisions shall be enforced.
        <h3>Governing law and jurisdiction</h3>
        This Agreement will be governed by and interpreted in accordance with
        the laws of Singapore, as such laws are applied to agreements entered
        into and to be performed entirely within Singapore, without regard to
        conflict of law provisions.
        <br />
        <br /> Except as otherwise agreed by the parties, you agree that any
        claim or dispute you may have against ZurePay must be resolved by a
        court located in Singapore or where the defendant is located. You agree
        to irrevocably submit to the non¬-exclusive jurisdiction of the courts
        located within Singapore for the purpose of any suit, action or other
        proceeding arising out of this user agreement or your use of our
        websites or the services.
        <h3>Improperly Filed Litigation</h3>
        All claims you bring against ZurePay must be resolved in accordance with
        this user agreement. All claims filed or brought contrary to this user
        agreement shall be considered improperly filed and a breach of this user
        agreement. Should you file a claim contrary to this user agreement,
        ZurePay may recover attorneys' fees and costs (including in-house
        attorneys and paralegals) up to $1,000.00 USD, provided that ZurePay has
        notified you in writing of the improperly filed claim, and you have
        failed to promptly withdraw the claim.
        <h3>Identity authentication</h3>
        You authorize ZurePay, directly or through third parties, to make any
        inquiries we consider necessary to verify your identity. This may
        include:
        <li>
          asking you for further information, such as your date of birth, your
          taxpayer or national identification number, your physical address and
          other information that will allow us to reasonably identify you;
        </li>
        <li>
          requiring you to take steps to confirm ownership of your email address
          or financial instruments;
        </li>
        <li> ordering a credit report from a credit reporting agency;</li>
        <li>
          verifying your information against third party databases or through
          other sources; or
        </li>
        <li>
          requiring you to provide further documentation, such as your driver's
          license or other identifying documents at any time.
        </li>
        Anti-money laundering and counter-terrorism financing laws may require
        that ZurePay verify certain identifying information if you use certain
        ZurePay services. <br />
        <br />
        ZurePay reserves the right to close, suspend, or limit access to your
        ZurePay account and/or the ZurePay services in the event that, after
        reasonable enquiries, we are unable to obtain information about you
        required to verify your identity.
        <h3>ZurePay is only a payment service provider</h3>
        <b>We act as a payment service provider only. We do not:</b> <br />
        <li>Act as a bank or provide banking services;</li>
        <li>
          Act as an escrow agent with respect to any funds kept in your account;
        </li>
        <li>Act as your agent or trustee;</li>
        <li>
          Enter into a partnership, joint venture, agency or employment
          relationship with you;
        </li>
        <li>
          Have control of, nor liability for, the products or services that are
          paid for with the ZurePay services;
        </li>
        <li>Guarantee the identity of any buyer or seller;</li>
        <li>Ensure that a buyer or a seller will complete a transaction;</li>
        Determine if you are liable for any taxes; or
        <li></li>
        <li>
          Unless otherwise expressly set out in this user agreement, collect or
          pay any taxes that may arise from your use of our services.
        </li>
        <h3>Assessment of your website</h3>
        If you integrate or otherwise reference ZurePay services on your
        website, ZurePay may use automated technologies (e.g., website crawling)
        to assess your website and collect any publicly accessible or available
        data to ensure compliance with this agreement and to combat malicious or
        fraudulent activity. <br />
        <br />
        In connection with such technologies, ZurePay will not collect any of
        your customer personal data. However, there may be times we collect
        personal data about you that you publish on your websites. Such personal
        data shall be processed for the purposes described in this section and
        shall be subject to our Privacy Statement.
        <h3>Privacy</h3>
        Protecting your privacy is very important to us. Please review our
        Privacy Statement in order to better understand our commitment to
        maintaining your privacy, as well as our use and disclosure of your
        information.
        <h3>Terms in English; Translation of agreement</h3>
        It is the express wish of the parties that these terms and any directly
        or indirectly related documents be drawn up in English. Any translation
        of this user agreement is provided solely for your convenience and is
        not intended to modify the terms of this user agreement. In the event of
        a conflict between the English version of this user agreement and a
        version in a language other than English, the English version shall
        control.
        <h3>Your use (as a seller) of personal data; Data protection laws</h3>
        If you (as a seller) receive personal data about another ZurePay
        customer, you must keep such personal data confidential and only use it
        in connection with the ZurePay services.
        <br />
        <br /> You may not disclose or distribute any personal data about
        ZurePay customers to a third party or use such personal data for
        marketing purposes unless you receive that customer's express consent to
        do so. You may not send unsolicited emails to a ZurePay customer or use
        the ZurePay services to collect payments to send, or assist in sending,
        unsolicited emails to third parties. <br />
        <br />
        To the extent that you process any personal data about a ZurePay
        customer pursuant to this user agreement, you and ZurePay will shall
        each be an independent data controller (and not joint controllers),
        meaning we will each separately determine the purposes and means of
        processing such personal data.
        <br />
        <br /> We each agree to comply with the requirements of any applicable
        privacy and data protection laws, including any applicable regulations,
        directives, codes of practice, and regulatory requirements applicable to
        data controllers in connection with this user agreement. We each also
        have and will follow our own, independently-determined privacy
        statements, notices, policies and procedures for any such personal data
        that we process in connection with this user agreement.
        <h3>
          In complying with the applicable data protection laws, we will each:
        </h3>
        <li>
          implement and maintain all appropriate security measures in relation
          to the processing of personal data;
        </li>
        <li>
          maintain a record of all processing activities carried out under this
          user agreement; and
        </li>
        <li>
          not knowingly or intentionally do anything, or knowingly or
          intentionally permit anything to be done, which might lead to a breach
          by the other party of the applicable data protection laws.
        </li>
        Any personal data you collect in connection with the ZurePay services
        (and not otherwise generated, collected, or obtained by you through a
        customer’s separate relationship with you outside the use of the ZurePay
        services) will be used by you only to the limited extent that is
        necessary and relevant to the ZurePay services and for no other purpose
        unless you have obtained the prior express consent of the customer.
        <br />
        <br /> The parties agree that ZurePay may transfer customer personal
        data processed under this user agreement outside the country where it
        was collected as necessary to provide the ZurePay services. If ZurePay
        transfers customer personal data protected under this user agreement to
        a jurisdiction for which the applicable regulatory authority for the
        country in which the data was collected has not issued an adequacy
        decision, ZurePay will ensure that appropriate safeguards have been
        implemented for the transfer of customer personal data in accordance
        with applicable data protection laws. <br />
        <br />
        For example, and for purposes of compliance with the Regulation (EU)
        2016/679, we rely on Binding Corporate Rules approved by competent
        supervisory authorities and other data transfer mechanisms for transfers
        of customer personal data to other members of the ZurePay group.
        <h3>Country/Region-Specific Terms</h3>
        These terms and conditions apply only to account holders for ZurePay
        accounts registered in the following countries. In the event of a
        conflict between the terms of this user agreement and the terms below,
        the country/region-specific terms below shall apply:
        <h3>Croatia and Iceland</h3>
        Data Protection Provisions for sellers. If you, as a seller with a
        Croatian or Icelandic ZurePay account, transfer personal data of
        customers located, respectively, in Croatia or Iceland we each agree to
        enter into the standard contractual clauses found here, which form part
        of this user agreement.
        <h3>Mainland China</h3>
        If you are using a service ("Third Party Service") offered by a ZurePay
        partner bank or licensed foreign-exchange service provider (a "Service
        Provider"), please see the terms and conditions of the Service Provider
        for additional information relating to that service. <br />
        <br />
        In case of any disputes, demands, actions, proceedings ("Disputes")
        between you and a Service Provider on account of any delay or
        non-payment of any funds due to you, you hereby indemnify ZurePay,
        ZurePay's affiliates and its employees from any damages, claims,
        charges, expenses and/or losses arising out of such Disputes. <br />
        <br />
        You further agree that ZurePay shall not be liable to you in relation to
        any funds which have already been transferred from your ZurePay account
        to a Service Provider. You acknowledge and agree that ZurePay may share
        information provided by you in accordance with ZurePay's Privacy
        Statement and the terms of the agreement between you and ZurePay with a
        Service Provider, including for the following reason; to enable a
        Service Provider to conduct the requisite due diligence in accordance
        with Service Provider's Know-your-customer (KYC) obligations. <br />
        <br />
        ZurePay shall not be responsible for any loss or damage incurred or
        suffered by you on account of any <br />
        (i) failure, interruption, defect, or negligent or willful misuse of a
        Third Party Service; or <br />
        (ii) consequences arising out of delayed or non-receipt of funds
        withdrawn through a Third Party Service.
        <br />
        <br /> We may amend the user agreement and any of the policies and other
        agreements on the Legal Agreements page that apply to users in Mainland
        China from time to time.
        <br />
        <br /> The revised version will be effective at the time we post it on
        our website, unless otherwise noted. If our changes reduce your rights
        or increase your responsibilities, we will post a notice on the Policy
        Updates page of our website and provide you with at least 30 days’
        notice. <br />
        <br /> Except to the extent amended by these terms and conditions, all
        other provisions of the user agreement shall remain in full force and
        effect.
        <h3>Colombia</h3>
        As of 1 September 2015, Colombian Resident ZurePay users may only send
        and receive international payments. Domestic payments between two
        Colombian Resident ZurePay users are unavailable. "Colombian Resident"
        is defined to mean:
        <br />
        <br />
        1. an individual, regardless of actual nationality, who has indicated
        Colombia as his/her country or region at the point of signing up for a
        ZurePay account; and <br />
        2. an entity, partnership, organization or association which has
        indicated Colombia as its country or region of registration at the point
        of signing up for a ZurePay account.
        <h3>Malaysia</h3>
        ZurePay has been approved by Bank Negara Malaysia to operate an e-Money
        business. Therefore, if you are a Malaysian customer and ZurePay does
        not provide a prompt and efficient response to you in relation to
        ZurePay's services pursuant to your use of the dispute resolution
        process set out in the Disputes with ZurePay section of this user
        agreement or contacting ZurePay's Customer Support, you may also contact
        Bank Negara through BNMLINK and BNMTELELINK. BNMTELELINK can be
        contacted directly either by telephone, fax, letter or email. <br />{" "}
        <br />
        Members of the public can contact BNMLINK and BNMTELELINK for
        information, inquiries or redress in the areas of conventional and
        Islamic banking, insurance and takaful, advisory services for small and
        medium enterprises, foreign exchange administration and other matters
        under Bank Negara Malaysia's jurisdiction. The contact details for
        BNMTELELINK are:
        <h3>BNMTELELINK</h3>
        <br />
        Jabatan Komunikasi Korporat <br />
        Bank Negara Malaysia <br />
        P.O.Box 10922 <br />
        50929 Kuala Lumpur <br />
        Tel: 1-300-88-5465 (LINK) <br />
        Fax: 03-2174 1515 <br />
        Email: bnmtelelink@bnm.gov.my
        <br />
        <br />
        BNMLINK and BNMTELELINK operating hours are from: Monday - Friday, 9.00
        a.m. - 5.00 p.m. Malaysia Time Privacy Statement. In addition to the
        English language version of the Privacy Statement appended to this
        Agreement, a Bahasa Melayu version of the policy is available by
        clicking the following link. <br />
        <br />
        Financial Ombudsman Scheme. ZurePay is a member of the Financial
        Ombudsman Scheme approved by Bank Negara Malaysia. The operator approved
        to operate the scheme is the Ombudsman for Financial Services (OFS), an
        independent alternative dispute resolution channel covering eligible
        Malaysia customers. OFS services are provided free of charge to eligible
        complainants who may be dissatisfied with ZurePay's resolution. <br />
        <br />
        For more information on the timeframe to file the dispute and the types
        of Award that may be granted by the Ombudsman, please visit
        <b>www.ofs.org.my</b>
        <br />
        <br />
        Withdrawing Funds in Malaysia. If your Account is registered in
        Malaysia, you may only withdraw funds from your Account by transferring
        them to your local Malaysian bank account. Governing Law. <br />
        <br />
        For Malaysian residents who register for the ZurePay services with a
        Malaysian address, this user agreement is governed by and interpreted
        under the laws of Malaysia as such laws are applied to agreements
        entered into and to be performed entirely within Malaysia. <br />
        <br />
        Except as amended by these terms and conditions, all other provisions of
        the user agreement remain in full force and effect. Amendments. <br />
        <br />
        We may amend this user agreement and any of the policies and other
        agreements on the Legal Agreements page that apply to users in Malaysia
        from time to time. The revised version will be effective at the time we
        post it on our website, unless otherwise noted. If our changes reduce
        your rights or increase your responsibilities, we will post a notice on
        the Policy Updates page of our website and provide you with at least 21
        days’ notice. <br />
        <br />
        ZurePay suspension and termination rights. ZurePay, in its sole
        discretion, reserves the right to suspend or terminate this user
        agreement, access to or use of its websites, software, systems
        (including any networks and servers used to provide any of the ZurePay
        services) operated by us or on our behalf or some or all of the ZurePay
        services, at any time upon notice to you: <br />
        <br />
        (i) if you breach this user agreement, the ZurePay Acceptable Use
        Policy, the Commercial Entity Agreements (as they apply to you), or any
        other agreement between you and ZurePay, or <br />
        (ii) if we determine, acting reasonably, that we should do so due to
        other circumstances or reasons. <br />
        <br />
        Our determination shall be final and binding except in the case of
        manifest error or bad faith. Upon termination of this user agreement,
        ZurePay shall arrange the payment to you of any unrestricted funds held
        in the balance of your ZurePay account.
        <h3>Morocco</h3>
        Upon enablement of the withdrawal functionality to a Moroccan bank
        account with the bank partner of ZurePay, you will be required to comply
        with Moroccan laws and regulations. <br />
        <br />
        This means notably that if you receive amounts into the balance of your
        ZurePay account from a ZurePay user, you must withdraw the amounts in
        the balance of your ZurePay account to your Moroccan bank account*
        within 30 Days of receipt of funds. * The bank partner of ZurePay for
        the withdrawal functionality charges a fee for each withdrawal. Upon
        enablement of the receive and withdraw functionalities, ZurePay will
        provide you with additional information.
        <h3>The Philippines</h3>
        If you are using a service ("Third Party Service") offered by a ZurePay
        partner bank, licensed foreign-exchange service provider, remittance
        agent or e-money issuer (a "Service Provider"), please see the terms and
        conditions of the Service Provider for additional information relating
        to that service. In case of any disputes, demands, actions, proceedings
        ("Disputes") between you and a Service Provider on account of any delay
        or non-payment of any funds due to you, you hereby indemnify ZurePay,
        ZurePay's affiliates and its employees from any damages, claims,
        charges, expenses and/or losses arising out of such Disputes.
        <br />
        <br /> You further agree that ZurePay shall not be liable to you in
        relation to any funds which have already been transferred from your
        ZurePay account to a Service Provider. <br />
        <br />
        You acknowledge and agree that ZurePay may share information provided by
        you in accordance with ZurePay's Privacy Statement and the terms of the
        agreement between you and ZurePay with the Service Provider in order to
        enable the Service Provider to conduct the requisite due diligence in
        accordance with Service Provider's Know-your-customer (KYC) obligations.{" "}
        <br />
        <br />
        ZurePay shall not be responsible for any loss or damage incurred or
        suffered by you on account of any <br />
        (i) failure, interruption, defect, or negligent or willful misuse of a
        Third Party Service; or <br />
        (ii) consequences arising out of any delay or non-receipt of funds
        withdrawn through a Third Party Service. <br />
        <br />
        Except to the extent amended by these terms and conditions, all other
        provisions of the user agreement shall remain in full force and effect.
        <h3>South Africa</h3>
        <b>
          You are required to comply with South African exchange control
          regulations. This means that:
        </b>
        <br />
        1. If you receive amounts into the balance of your ZurePay account from
        a ZurePay user:
        <br />
        <li>
          (i) you may not send those amounts to another ZurePay user for a
          purchase or for any other reason.
        </li>
        <li>
          (ii) you must withdraw the amounts in the balance of your ZurePay
          account to your South African bank account* within 30 Days of receipt
          of funds.
        </li>
        <br />
        2. If you would like to send amounts to another ZurePay user:
        <li>
          (i) you must do so either with the credit card associated with your
          account, or by doing a top up to the balance in your ZurePay account
          from your FNB bank account*.
        </li>
        <li>
          (ii) For purchases using top up, you must do a separate top up for
          each purchase.
        </li>
        <br /> * FNB charges a fee for each top up and withdrawal. Please see
        the FNB website for more information.
        <h3>Singapore</h3>
        The ZurePay services are offered by ZurePay Bytes Consults., located at
        5 Temasek Boulevard #09-01, Suntec Tower Five, Singapore 038985. Our
        operating hours are on Business Days from 9am to 5pm. <br />
        <br />
        If you are a Singapore resident, you may have this same information
        emailed to you by sending a letter to the address above with your email
        address and a request for this information.
        <br />
        <br /> Fees for our services are described in our Fees Table. Your
        ZurePay account can only facilitate payments for goods and services, not
        personal fund transfers. For personal accounts, please note that there
        may be a limit on the total balance which you may hold in all personal
        accounts or on the total amount of outgoing transactions which may be
        made from all personal accounts you have with us as prescribed by law or
        as we may determine.
        <br />
        <br /> In relation to the balance of ZurePay accounts of Singapore
        Resident users, ZurePay has arranged for a sum of monies equal to the
        said balances to be held through a trust arrangement.
        <h3>*Singapore Resident means:</h3>
        1. an individual, regardless of nationality, who has indicated
        ‘Singapore’ as his/her ‘Country of Residence’ at the point of signing up
        for a ZurePay account, and the individual agrees in so doing that he/she
        shall be regarded as a Singapore resident; and
        <br />
        2. a non-individual which has indicated ‘Singapore’ as its ‘Country of
        Registration’ at the point of signing up for a ZurePay account, and the
        non-individual agrees in so doing that it shall be regarded as a
        Singapore resident. Additionally, if you are an individual or sole
        proprietor, we are required to inform you of your obligations under the
        E-Payments User Protection Guidelines published by the Monetary
        Authority of Singapore as set out below:
        <li>
          provide us with your contact details as required by us in order for us
          to send you transaction notifications as we are required under the
          guidelines. At a minimum, if you opt to receive transaction
          notification by SMS, you should give us your Singapore mobile phone
          number, and if you have opted to receive notification by email, you
          should give us your mail address;
        </li>
        <li>
          You are responsible for enabling transaction notification alerts, to
          receive all transaction notifications for all outgoing transactions
          made from your account, and to monitor the transaction notifications
          we have sent to your email, without the need for further reminders or
          repeat notifications.
        </li>
        <li>
          As mentioned above, you should not voluntarily disclose any of your
          passwords to any third party, (except as instructed by us). You should
          not disclose your password in a recognisable way on your account,
          authentication device, or any container for your account, or keep a
          record of your password in a way that allows a third party to misuse
          your password.
        </li>
        <li>
          If you do keep a record of your password, you should make reasonable
          efforts to keep the record secure, for example, by keeping it in a
          physical location that only you know or can access, or keeping it in a
          place where a third party is unlikely to find it.
        </li>
        <li>Minimally, you should do the following to protect your account:</li>
        <li>update your device(s)’ browser to the latest version available,</li>
        <li>
          patch your device(s)’ operating systems with regular security updates,
        </li>
        <li>
          install and maintain the latest anti-virus software on your device(s),
          and
        </li>
        <li>
          use strong passwords, such as a mixture of letters, numbers and
          symbols.
        </li>
        <li>
          You should report any Unauthorized Transactions to ZurePay as soon as
          practicable after receipt of any transaction notification alert for
          any Unauthorized Transaction. <br />
          <br />
          If there is a delay in reporting, and we request for such reason, you
          should provide us with the reasons for the delayed report. Reporting
          for Unauthorized Transactions are detailed above. You may also be
          required to make a police report if we request, to facilitate our
          claims investigation process.
        </li>
        <li>
          You should also inform other persons you have authorized (in
          accordance with this Agreement) to use your ZurePay account, if any,
          of our security instructions and advice. Our obligations are:
        </li>
        <li>
          Subject to your preferences, we would provide transaction
          notifications in respect of all of your outgoing transactions, sent to
          your email, or every email selected by you to receive such
          notifications. <br />
          <br /> These notifications are sent on a real time basis for each
          transaction. We will accompany the email transaction notification with
          a notification on our website. <br />
          <br /> The notification would also contain the following information
          (other than information we have left out for confidentiality) for you
          to identify the transaction as being authorized:
        </li>
        <li>
          Information that allows you to identify your account (e.g. by your
          account name),
        </li>
        <li> Information that allows you to identify the recipient,</li>
        <li>
          Information that allows us to later identify you, your account, and
          the recipient’s account,
        </li>
        <li> Transaction amount; Transaction time and date,</li>
        <li> Transaction type,</li>
        <li>
          If the transaction is for goods and services provided by a business,
          the merchant’s trading name and reference number for the transaction
          (where possible).
        </li>
        Please note that your notification preferences may affect your ability
        to detect Unauthorised Transactions in a timely manner, and your
        liability for any Unauthorised Transactions. <br />
        <br />
        In addition to the procedures above, we will complete investigation of
        any Unauthorized Transaction claim within 21 business days, for
        straightforward cases or 45 business days, for complex cases.
        <br />
        <br /> Complex cases may include cases where any party to the
        Unauthorised Transaction stays overseas or where we have not received
        sufficient information from you to complete the investigation.
        <br />
        <br /> We will provide a written or oral report of the investigation
        outcome and its assessment of your liability and seek your
        acknowledgement of the investigation report. <br />
        <br />
        We may also require that you furnish a police report before we begin our
        claims resolution process. Where you do not agree with our assessment,
        we may commence other forms of dispute resolution.
      </p>
      <p className="parag">
        <h3>Taiwan</h3>
        Your ability to withdraw the balance in your ZurePay account to your
        local bank account may require the opening of a bank account with a
        ZurePay partner bank in Taiwan ("Partner Bank"), and using the
        withdrawal service offered by that Partner Bank ("Withdrawal Service").
        Please see the terms and conditions with the Partner Bank for additional
        information relating to the Withdrawal Service. If for any reason the
        balance in your ZurePay account withdrawal cannot be processed, your
        funds will be returned to you in USD.
        <br />
        <br /> In case of any disputes, demands, actions, proceedings
        ("Disputes") between you and the Partner Bank on account of any delayed
        or non-payment of any funds due to you, you hereby indemnify ZurePay,
        ZurePay's affiliates and its employees from any damages, claims,
        charges, expenses and/or losses arising out of such Disputes.
        <br />
        <br /> You further agree that ZurePay shall not be liable to you in
        relation to any funds which have already been transferred from your
        ZurePay account to the Partner Bank.
        <br />
        <br /> ZurePay shall not be responsible for any loss or damage incurred
        or suffered by you on account of any
        <br />
        (i) failure, interruption, defect or negligent or willful misuse of the
        Withdrawal Service; or <br />
        (ii) consequences arising out of any delay or non-receipt of funds
        withdrawn through the Withdrawal Service.
        <br />
        <br /> Except to the extent amended by these country specific terms and
        conditions, all other provisions of the user agreement shall remain in
        full force and effect.
      </p>
    </div>
  );
};

export default UsersAgreement;
