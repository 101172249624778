import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../features/user/userActions";
import { SpinnerCircular } from "spinners-react";
// form styles
import "../styles/forms.css";
import axios from "../api/axios";
const LoginScreen = () => {
  const { loading, isLoading, error } = useSelector((state) => state.user);
  const init = localStorage.getItem("IS_LOGIN_ZPAY_INIT");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getClientIP();
  }, []);

  useEffect(() => {
    // redirect authenticated user to authorized screen
    if (init) {
      const timer = setTimeout(() => {
        getClientIP();
        localStorage.removeItem("IS_LOGIN_ZPAY_INIT");
        navigate("/auth/login");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, isLoading, init]);

  const getClientIP = async () => {
    try {
      await axios.get("https://zurepay.com/api/v1/ip").then((response) => {
        localStorage.setItem("CLIENT_IP", response.data.ip);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = (data) => {
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(userLogin(data));
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZPAY-ICON" />
        </div>
        <p className="divider">
          <span>Login to your Account</span>
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {error && <div className="alert-warning">{error}</div>}
          {init && (
            <h3 className="alert-success">OTP sent! Loading auth page</h3>
          )}

          <input
            type="email"
            placeholder="Enter Email"
            {...register("email")}
          />
          {errors.email && <span>Email is required</span>}

          <input
            type="password"
            placeholder="Enter Password"
            {...register("password")}
          />
          {errors.password && <span>Password is required</span>}
          <button
            type="submit"
            className="r-btn"
            disabled={loading && isLoading}
          >
            {loading && isLoading ? <SpinnerCircular size={20} /> : "Continue"}
          </button>
          <Link to="/signup" className="mt-10">
            Sign Up
          </Link>
          <Link to="/passwordreset/link" className="mt-10">
            Forgot Password?
          </Link>
        </form>
      </div>
    </section>
  );
};

export default LoginScreen;
