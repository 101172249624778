const currencies = {
  "usd United States Dollar": "United States Dollar",
  "eur Euro": "Euro",
  "gbp British Pound": "British Pound",
  "zar South African Rand": "South African Rand",
  "ngn Nigeria Naira": "Nigeria Naira",
  //"inr Indian Ruppee": "Indian Ruppee",
};

export default currencies;
