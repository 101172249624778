import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userEmailVerify } from "../features/user/userActions";
import axios from "../api/axios";

// form styles
import "../styles/forms.css";
const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [email, setEmail] = useState(params.email);
  const [accountID, setAccountID] = useState(params.accountID);
  const [zotaalCODE, setZotaalCODE] = useState(params.zotaalCODE);
  const { loading, error, EMAIL_VERIFIED } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email, accountID, zotaalCODE },
  });

  useEffect(() => {
    axios
      .get(`email/verify/${accountID}/${zotaalCODE}/${email}`)
      .then((response) => {
        setEmail(response.data.email);
        setAccountID(response.data.accountID);
        setZotaalCODE(response.data.zotaalCODE);
        localStorage.setItem("MY_EMAIL", email);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  useEffect(() => {
    if (EMAIL_VERIFIED) {
      const timer = setTimeout(() => {
        localStorage.removeItem("EMAIL_VERIFIED");
        localStorage.removeItem("REG_USER_EMAIL");
        localStorage.removeItem("MY_EMAIL");
        navigate("/login");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [accountID, zotaalCODE, EMAIL_VERIFIED, navigate]);

  const submitForm = (data) => {
    // check if email is present to ensure user is authenticated
    if (email !== "") {
      if (data.email !== email) {
        return new Error("User's Email Error");
      }
    }
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(userEmailVerify(data));
  };

  return (
    <section className="form-container">
      <div className="formly">
        <div className="form-image">
          <img className="form_icon" src="/images/icon.png" alt="ZPAY-ICON" />
        </div>
        <p className="divider">
          <span>EMAIL VERIFICATION</span>
        </p>
        <div className="alert-success">
          Almost done, please click "Verify My Email" button below to finish up
          <hr />
          <br />
        </div>

        <form onSubmit={handleSubmit(submitForm)} className="form-grid">
          {error && <h3 className="alert-warning">{error}</h3>}
          {EMAIL_VERIFIED && (
            <h3 className="alert-success">Your Email Has been verified</h3>
          )}
          <input
            type="email"
            placeholder="Enter Email"
            defaultValue={email}
            disabled
            className="alert-display"
            {...register("email", { required: true })}
          />
          {errors.email && <span>Email is required</span>}
          <input
            type="hidden"
            defaultValue={accountID}
            {...register("accountID", { required: true })}
          />
          {errors.accountID && <span>Account ID is required</span>}
          <input
            type="hidden"
            defaultValue={zotaalCODE}
            {...register("zotaalCODE", { required: true })}
          />
          {errors.zotaalCODE && <span>zotaal Code is required</span>}

          <button type="submit" className="r-btn">
            {loading ? "Verifying..." : "Verify My Email"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default VerifyEmail;
