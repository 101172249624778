import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { sendFund } from "../features/wallet/walletActions";
import { SpinnerCircular } from "spinners-react";

const SendFund = (props) => {
  const [transaction_currency, setCurrency] = useState("");
  const [transaction_amount, setAmount] = useState("");
  const [description, setDescription] = useState("");

  const [email, setEmail] = useState("");
  const [funded_by, setFundedBy] = useState(
    localStorage.getItem("USER_EMAIL") ? localStorage.getItem("USER_EMAIL") : ""
  );

  const funded = localStorage.getItem("FUND_STATUS");
  const notfunded = localStorage.getItem("FUND_ERROR_STATUS");
  const { error, wLoading, USER_INFO } = useSelector((state) => state.wallet);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { funded_by } });
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("IS_LOGIN_ZPAY");
    if (!auth) {
      navigate("/login");
    }
    const uEmail = localStorage.getItem("USER_EMAIL");
    if (uEmail) {
      setEmail(uEmail);
      setFundedBy(uEmail);
    }
  }, [navigate]);

  useEffect(() => {
    if (funded) {
      const timer = setTimeout(() => {
        localStorage.removeItem("FUND_STATUS");
      }, 3000);
      return () => clearTimeout(timer);
    } else if (notfunded) {
      const timer = setTimeout(() => {
        localStorage.removeItem("FUND_ERROR_STATUS");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notfunded, funded]);
  const submitForm = (data) => {
    dispatch(sendFund(data));
    setEmail("");
    setCurrency("");
    setAmount("");
    setDescription("");
  };
  return (
    <div id="CardForm">
      <div className="right">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="full-flex">
            <i>Send fund to anyone with Zurepay Account</i>
            {notfunded && <div className="alert-warning">{error}</div>}
            {funded && <i className="alert-success">Fund sent successfully</i>}
            <input
              type="email"
              placeholder="Enter Recipient's Email"
              {...register("email", { required: true })}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={(e) => setEmail(e.target.value)}
            />
            <div className="half-flex">
              <select {...register("transaction_currency", { required: true })}>
                <option value="">Select currency to send</option>
                <option value="gbp">Pound</option>
                <option value="eur">Euro</option>
                <option value="usd">US dollar</option>
                <option value="ngn">Nigeria Naira</option>
                <option value="zar">South Africa Rand</option>
              </select>
              <input
                type="text"
                placeholder="Amount"
                {...register("transaction_amount", { required: true })}
                onChange={(e) => setAmount(e.target.value)}
                onFocus={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="half-flex">
            <input
              type="hidden"
              placeholder="funder email"
              defaultValue={funded_by}
              {...register("funded_by", { required: true })}
            />
          </div>

          <input
            type="text"
            placeholder="Description"
            {...register("description", { required: false })}
            onChange={(e) => setDescription(e.target.value)}
            onFocus={(e) => setDescription(e.target.value)}
          />

          <div className="btn-container">
            <button></button>
            <button type="submit" className="login">
              {wLoading ? <SpinnerCircular size={20} /> : " Send Fund"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendFund;
