const BuyersProtection = () => {
  return (
    <div className="pages-container">
      <h1> Buyer Protection</h1>
      <h6>Updated on 08 may 2023</h6>
      <p className="parag">
        Sometimes, when you receive items you bought online at home or abroad,
        they aren’t quite as expected when they arrive. If this ever happens,
        don’t worry. What is ZurePay Buyer Protection? If your eligible item
        doesn't arrive, or doesn’t match the seller's description, we can help
        you get your money back.
      </p>
      <p className="parag">
        1. Contact the seller Within 180 days of the purchase, open a dispute in
        our Resolution Center to try to resolve the problem directly with the
        seller.
        <br />
        <br />
        2. Raise a claim If you are unable to resolve the issue with the seller,
        visit the Resolution Center again to escalate the process to a claim.
        <br />
        <br />
        3. Get a refund If your transaction is eligible, you will be reimbursed
        up to the full amount of the purchase.
        <br />
        <br />
        <h3> You can get a refund in the following scenarios.</h3>
        <li>
          You receive something that doesn’t match the seller's description.
        </li>
        <li>Your purchase has been damaged in transit. </li>
        <li>You bought something new but are sent a used item.</li>{" "}
        <li>
          Your item is missing major parts (which the seller didn't disclose).
        </li>
        <li>You've purchased 3 items but only receive 2. </li>
        <li>You paid for an authentic brand but received an imitation.</li>
        <li>See our User Agreement for more information.</li>
        <br />
        <br />
        We can protect you before, during and after the purchase. Fraud
        protection We monitor every transaction 24/7 to prevent fraud, email
        phishing and identity theft.
        <br />
        <br />
        <b>Data encryption: </b> Every transaction is protected using our
        advanced encryption technology.
        <br />
        <br />
        Buyer Protection We can refund your purchase if there is a problem. Shop
        more securely with ZurePay, at
      </p>
    </div>
  );
};

export default BuyersProtection;
