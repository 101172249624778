import React, { useEffect, useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { useParams } from "react-router-dom";
import { approveIdentity } from "../features/user/userActions";
import { RiAlertFill } from "react-icons/ri";
import axios from "../api/axios";

const ApproveIdentity = () => {
  const params = useParams();
  const id = params.id;
  const [data, setData] = useState(false);
  const [approved, setApproved] = useState(false);
  const { error, loading, USER_INFO, APPROVE_KYC_IDENTITY } = useSelector(
    (state) => state.user
  );

  const [bearerToken] = useState(
    USER_INFO.USER_TOKEN ? `Bearer ${USER_INFO?.USER_TOKEN}` : false
  );
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm({
    defaultValues: { id },
  });

  const navigate = useNavigate();
  const submitForm = (data) => {
    dispatch(approveIdentity(data));
    setApproved(true);
  };

  useEffect(() => {
    if (approved) {
      const timer = setTimeout(() => {
        localStorage.removeItem("APPROVE_KYC_IDENTITY");
        setApproved(false);
        navigate("/accounts");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [approved, navigate]);

  useEffect(() => {
    axios
      .get(`account/${id}`, {
        headers: {
          Authorization: `Bearer ${USER_INFO.USER_TOKEN}`,
        },
      })
      .then((response) => {
        setData(response.data);
        localStorage.removeItem("APPROVE_KYC_IDENTITY");
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, USER_INFO?.USER_TOKEN]);
  if (!data) return null;

  return (
    <section className="wallet_div">
      <div className="container">
        <div id="CardForm">
          <div className="right">
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="sixteen alert-warning">
                Approve KYC Identity {data?.user_details?.firstname}
                <br />
                <br />
                <b>{data.user_details.email}</b>
              </div>
              {error && <div className="alert-warning">{error}</div>}

              {APPROVE_KYC_IDENTITY && approved && (
                <i className="sixteen alert-success">
                  {APPROVE_KYC_IDENTITY.message}
                </i>
              )}

              <div className="full-flex">
                <input
                  type="hidden"
                  placeholder="Bearer Token"
                  defaultValue={bearerToken}
                  {...register("bearerToken", { required: true })}
                />
                <input
                  type="hidden"
                  placeholder="ID"
                  defaultValue={id}
                  {...register("id", { required: true })}
                />
              </div>

              <div className="btn-container">
                <Link
                  className="text_link_gray"
                  to={`/residence/approve/${data?.user_details?._id}`}
                >
                  Back to Residence
                </Link>

                <button type="submit" className="login">
                  {loading && APPROVE_KYC_IDENTITY?.status ? (
                    <SpinnerCircular size={20} />
                  ) : (
                    "Approve"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApproveIdentity;
