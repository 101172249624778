import React, { useState } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import "./AddCard.css";

import { useSelector } from "react-redux";
import { FcSimCardChip } from "react-icons/fc";
import Popup from "../dashboard/Popup";

import { HideString } from "../utils/blindit";
import RemoveCardConfirm from "./RemoveCardConfirm";
const RemoveCard = ({ _id, card_number, card_issuer }) => {
  const [removingCard, setRemovingCard] = useState(false);
  const [cardID, setCardID] = useState(_id ? _id : "");
  const [cardNum, setCardNum] = useState(card_number ? card_number : "");
  const { USER_INFO } = useSelector((state) => state.user);
  const [email, setEmail] = useState(USER_INFO?.account?.email);

  const toggleRemovingCard = () => {
    if (!cardID) {
      console.log("Details of card is missing");
    } else {
      localStorage.setItem("CARD_ID", cardID);
      localStorage.setItem("CARD_NUM", cardNum);
      setRemovingCard(!removingCard);
    }
  };
  return (
    <div className="flat-card-grid" key={cardID}>
      <span className="card-type">
        <FcSimCardChip size={50} />
        <h3>{card_issuer}</h3>
      </span>
      <div className="flat-card-details">
        <figure className="card-figure">
          <img src={`/cards/${card_issuer}.svg`} alt={`${card_issuer}-icon`} />
        </figure>{" "}
        <span className="card-number">
          {HideString(card_number.slice(58, 68))}
        </span>
      </div>

      <div className="full-flex">
        <input
          type="hidden"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email to get started"
        />
        <input
          type="hidden"
          name="cardId"
          value={cardID}
          onChange={(e) => setCardID(e.target.value)}
          placeholder="Enter your email to get started"
        />
      </div>
      <button className="signup" onClick={toggleRemovingCard}>
        Remove
      </button>

      {removingCard && (
        <Popup
          content={
            <>
              <RemoveCardConfirm />
            </>
          }
          handleClose={toggleRemovingCard}
        />
      )}
    </div>
  );
};

export default RemoveCard;
